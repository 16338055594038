import React from "react";
import { Box, Typography } from "@mui/material";

function ListComponent({ title, onClick }) {
  return (
    <Box
      onClick={onClick}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        p: 1,
        border: "1.5px solid #D9D9D9",
        borderRadius: "14px",
        cursor: "pointer",
        ":hover": {
          color: "#f9931f",
          transition: " color 0.2s",
        },
      }}
    >
      <Typography fontSize={"16px"} fontWeight={500}>
        {title}
      </Typography>
      <Typography fontSize={"16px"} fontWeight={500}>
        {">"}
      </Typography>
    </Box>
  );
}

export default ListComponent;
