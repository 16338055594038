import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const useOrderData = (bmerchant_id) => {
  const [orderdataResponse, setOrderdataResponse] = useState([]);
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const orderDataString = localStorage.getItem(
      `order_merchant_${bmerchant_id}`
    );
    const orderData = JSON.parse(orderDataString);
    if (orderData) {
      setOrderdataResponse(orderData);
      setOrders(orderData.order_detail);
    } else {
      navigate("/food");
    }
  }, [bmerchant_id, navigate]);

  return { orderdataResponse, orders, setOrders };
};

export default useOrderData;
