import React from "react";
import { Box, Typography } from "@mui/material";

function InstructionItem({ data }) {
  const renderImage = () => {
    if (Array.isArray(data.img) && data.img.length > 0) {
      return data.img.map((item, index) => (
        <img
          key={index}
          src={item}
          alt={`img-${index}`}
          title={`img-${index}`}
          loading="lazy"
          width="100%"
          height="auto"
          style={{ width: "100%", borderRadius: "12px" }}
        />
      ));
    } else if (typeof data.img === "string" && data.img !== "") {
      return (
        <img
          src={data.img}
          alt="1"
          title="1"
          loading="lazy"
          width="100%"
          height="auto"
          style={{ width: "100%", borderRadius: "12px" }}
        />
      );
    } else {
      return null; // Or render a placeholder image here if necessary
    }
  };

  const renderDescription = () => {
    if (Array.isArray(data.description) && data.description.length > 0) {
      return data.description.map((item, index) => (
        <Typography key={index}>{item}</Typography>
      ));
    } else if (
      typeof data.description === "string" &&
      data.description !== ""
    ) {
      return <Typography>{data.description}</Typography>;
    } else {
      return null; // Or render a placeholder description here if necessary
    }
  };
  return (
    <Box
      sx={{
        width: data.img === null ? "100%" : "60%",
        px: ["4px", "8px", "12px"],
        mt: [1, 2, 3],
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "80%",
        }}
      >
        {renderImage()}
      </Box>
      <Box
        sx={{
          py: 2,
          mt: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          width: "100%",
        }}
      >
        {renderDescription()}
      </Box>
    </Box>
  );
}

export default InstructionItem;
