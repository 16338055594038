import React, { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { resetPasswordScheme } from "../../libs/authScheme";
import { yupResolver } from "@hookform/resolvers/yup";
import Password from "../../assets/icon/password.png";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PasswordInput from "../../components/PasswordInput";
import { updatePassword } from "../../libs/authAPI/loginAPI";
import { signInWithCustomToken } from "firebase/auth";
import { auth } from "../../utils/firebaseAuth";
import useModalLoading from "../../stores/useModalLoading";

function ResetPassword() {
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    setError,
  } = useForm({ mode: "all", resolver: yupResolver(resetPasswordScheme) });
  const navigator = useNavigate();
  const [params] = useSearchParams();
  const token = params.get("token");
  const { openModal, closeModal } = useModalLoading();

  const onSubmit = (data) => {
    openModal();
    if (!data || !data.password || !data.confirmPassword) {
      closeModal();
      return;
    }

    const encodedPassword = btoa(data.password);
    signInWithCustomToken(auth, token)
      .then(async (firebaseRes) => {
        const idToken = await firebaseRes.user.getIdToken();
        const body = {
          token: idToken,
          new_password: encodedPassword,
        };
        updatePassword(body)
          .then((res) => {
            closeModal();
            navigator("/login/new?step=resetPasswordSuccess");
          })
          .catch((err) => {
            closeModal();
            console.log(err);
          });
      })
      .catch((err) => {
        closeModal();
        console.log(err);
      });
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "95vh",
        }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            backgroundColor: "white",
            mt: 10,
            py: 6,
            px: [2, 4, 6],
            borderRadius: 3,
            boxShadow: "-1px -1px 10px 1px rgba(0, 0, 0, 0.2)",
            display: "flex",
            width: ["90%", "70%", "400px"],
            flexDirection: "column",
            gap: 1,
          }}
        >
          <img
            src={Password}
            alt="logo"
            title="logo"
            loading="lazy"
            width={"50px"}
            height={"50px"}
            style={{
              width: "50px",
              height: "50px",
            }}
          />
          <Typography variant="h5" className="mt-3 font-bold">
            {t("resetPassword.title")}{" "}
          </Typography>
          <Typography className="text-[#a8a8a8]">
            {t("resetPassword.instruction")}{" "}
          </Typography>
          <PasswordInput
            label={t("resetPassword.newPasswordLabel")}
            placeholder={t("resetPassword.newPasswordPlaceholder")}
            error={errors.password?.message}
            {...register("password", { required: true })}
          />
          <PasswordInput
            label={t("registration.cPasswordLabel")}
            placeholder={t("registration.cPasswordPlaceholder")}
            error={errors.confirmPassword?.message}
            {...register("confirmPassword", { required: true })}
          />
          <Button
            type="submit"
            variant="contained"
            fullWidth
            disabled={!isValid}
            sx={{
              mt: 2,
              borderRadius: 6,
            }}
          >
            {t("btn.continue")}{" "}
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default ResetPassword;
