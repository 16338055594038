import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import { Divider } from "@mui/material";
import { Typography } from "@mui/material";
import { Phone } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import ModalCancelQueue from "./ModalCancelQueue";
import { QRCodeSVG } from "qrcode.react";
import MapButton from "../../components/MapButton";
import DefaultProfile from "../../utils/defaultImage";
import dayjs from "dayjs";
function QueueTicket({
  merchantData,
  queueData,
  onCancel,
  location,
  contractNumber,
}) {
  const { merchant_id, bmerchant_id, merchant_name, phone } = useParams();
  const [isCancelOpen, setIsCancelOpen] = useState(false);
  const currentURL = window.location.href;
  const baseURL = currentURL.split("/").slice(0, 3).join("/");

  const date = dayjs(queueData.time_book);
  const thaiDay = date.format("D MMM YYYY");
  const time = date.format("HH:mm:ss");

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        mx: [4, 0, 0, 0],
      }}
    >
      <Box
        className="drop-shadow-2xl"
        sx={{
          bgcolor: "white",
          borderRadius: "15px",
          width: ["auto", "350px", "400px", "450px"],
        }}
      >
        {/* top box */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            margin: "0 auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              borderTopRightRadius: "15px",
              borderTopLeftRadius: "15px",
              backgroundColor: "#FF961B",
            }}
          >
            <Box
              sx={{
                padding: "1rem 1rem 2rem",
                display: "flex",
                alignItems: "start",
                flexDirection: "row",
                gap: 1,
              }}
            >
              <img
                src={merchantData.logo ?? DefaultProfile}
                alt="logo"
                title="logo"
                loading="lazy"
                width="40px"
                height="40px"
                style={{
                  height: "40px",
                  width: "40px",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
              />
              <Box
                sx={{
                  flexDirection: "column",
                  textAlign: "start",
                  maxWidth: "200px",
                }}
              >
                <Typography color={"white"} fontSize={14} fontWeight={500}>
                  {merchantData.name}
                </Typography>
                {contractNumber && (
                  <Typography color={"white"} fontSize={12}>
                    <Phone
                      sx={{
                        width: "18px",
                        height: "18px",
                        mt: "-3px",
                        color: "white",
                      }}
                    />{" "}
                    {contractNumber}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box sx={{ padding: "0.7rem 0.5rem 2rem" }}>
              <MapButton stringAddress={location} />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mt: "-20px",
              backgroundColor: "white",
              borderTopRightRadius: "15px",
              borderTopLeftRadius: "15px",
            }}
          >
            <Box
              display="grid"
              gridTemplateColumns="repeat(8, 1fr)"
              sx={{
                textAlign: "start",
                padding: "1rem 2rem 0",
                width: "100%",
              }}
            >
              <Box gridColumn="span 3">
                <Typography fontSize={"12px"}>ชื่อผู้จอง</Typography>
                <Typography fontSize={"14px"} fontWeight={500}>
                  {queueData.name}
                </Typography>
              </Box>
              <Box gridColumn="span 2" />
              {contractNumber && (
                <Box gridColumn="span 3">
                  <Typography fontSize={"12px"}>เวลาที่จอง</Typography>
                  <Typography fontSize={"14px"} fontWeight={500}>
                    {thaiDay}
                  </Typography>
                  <Typography fontSize={"14px"} fontWeight={500}>
                    {time}
                  </Typography>
                </Box>
              )}
            </Box>
            <Box
              display="grid"
              gridTemplateColumns="repeat(8, 1fr)"
              sx={{
                textAlign: "start",
                padding: "0.5rem 2rem 1rem",
                width: "100%",
              }}
            >
              <Box gridColumn="span 3">
                <Typography mt={"-10px"} fontSize={"12px"} fontWeight={700}>
                  หมายเลขคิวของคุณ
                </Typography>
                <Typography
                  fontSize={"24px"}
                  fontWeight={700}
                  color={"#00974f"}
                >
                  {queueData.q_number}
                </Typography>
              </Box>
              <Box gridColumn="span 2" />
              <Box gridColumn="span 3">
                <Typography fontSize={"12px"}>จำนวนที่นั่ง</Typography>
                <Typography fontSize={"14px"} fontWeight={500}>
                  {queueData.qty_book}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {/* <Box
            sx={{
              width: "20px",
              height: "20px",
              backgroundColor: "#f8f9fa",
              borderRadius: "50%",
              ml: "-0.75rem",
            }}
          /> */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Divider sx={{ borderColor: "#c1c7c9" }} />
            <Divider
              sx={{
                lineHeight: ".1em",
                borderStyle: "dashed",
              }}
            />
          </Box>
          {/* <Box
            sx={{
              width: "20px",
              height: "20px",
              backgroundColor: "#f8f9fa",
              borderRadius: "50%",
              mr: "-0.75rem",
            }}
          /> */}
        </Box>
        <Box
          sx={{
            textAlign: "start",
            padding: "0.5rem 2rem 1rem",
          }}
        >
          <Box
            display="grid"
            gridTemplateColumns="repeat(8, 1fr)"
            sx={{
              width: "100%",
            }}
          >
            <Box gridColumn="span 3">
              <Typography fontSize={"12px"}>ตอนนี้คิวที่</Typography>
              <Typography fontSize={"24px"} fontWeight={700} color={"#0083ff"}>
                {queueData.CurrentQueue - 1}
              </Typography>
            </Box>
            <Box gridColumn="span 2" />
            <Box gridColumn="span 3">
              <Typography fontSize={"12px"}>รออีก (คิว)</Typography>
              <Typography fontSize={"24px"} fontWeight={700} color={"#0083ff"}>
                {queueData.q_number - queueData.CurrentQueue}
              </Typography>
            </Box>
          </Box>
          <Typography
            fontSize="16px"
            fontWeight={500}
            className="text-red-500 text-center"
          >
            * คำเตือน: กรุณาอย่าปิดเบราว์เซอร์ขณะที่ระบบคิวกำลังทำงาน
            เพื่อป้องกันการพลาดคิวของคุณ โปรดตรวจสอบสถานะคิวของคุณอย่างสม่ำเสมอ
          </Typography>

          <Box
            sx={{
              width: "100%",
              justifyContent: "center",
              display: "flex",
              mt: 1,
            }}
          >
            <QRCodeSVG size={"150px"} value={window.location.href} />
          </Box>
        </Box>
        <Typography
          fontSize="12px"
          fontWeight={500}
          className="text-primary text-center"
        >
          * ขอสงวนสิทธิ์ในการข้ามคิว กรณีลูกค้าไม่แสดงตน
        </Typography>
        <Button
          onClick={() => setIsCancelOpen(true)}
          sx={{
            py: 1,
            mt: 1,
            backgroundColor: "#d32f2f",
            borderRadius: "15px",
            color: "white",
            width: "70%",
            mb: 3,
            ":hover": {
              backgroundColor: "#932020",
            },
          }}
        >
          ยกเลิกคิว
        </Button>
      </Box>

      <ModalCancelQueue
        open={isCancelOpen}
        onCancel={() => onCancel()}
        onClose={() => setIsCancelOpen(false)}
      />
    </Box>
  );
}

export default QueueTicket;
