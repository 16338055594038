import React, { useEffect, useState } from "react"; // Import React
import { Box, Typography } from "@mui/material";
import { useLocationStore } from "../../stores/useLocationStore";
import { useNavigate } from "react-router-dom";

import Options from "../../utils/options";
import { useAccess } from "../../stores/useAccess";
import HeroBanner from "./HeroBanner";
import Partner from "./Partner";
import { useTranslation } from "react-i18next";
import HighlightSlider from "./HighlightSlider";
import MerchantType from "./MerchantType";
import AdsSection from "./AdsSection";
import WhyPompkinsSlider from "./WhyPompkinsSlider";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import ModalMerchantCustomer from "../../components/ModalMerchantCustomer";

function NewWelcomePage() {
  const { t } = useTranslation();
  const { currentAddress } = useLocationStore();
  const { canAccess } = useAccess();
  const { highlightComponent } = Options();
  const [openWhoAmI, setOpenWhoAmI] = useState(false);
  const whoAmI = sessionStorage.getItem("whoAmI");
  const navigator = useNavigate();
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/",
      title: "NewWelcomePage",
    });
  }, []);

  useEffect(() => {
    if (!whoAmI) {
      setOpenWhoAmI(true);
    }
  }, []);

  // useEffect(() => {
  //   if (currentAddress && !canAccess && whoAmI === "customer") {
  //     return navigator("/food");
  //   }
  // }, []);

  return (
    <>
      <Helmet>
        <title>{t("title.homepage")}</title>
        <meta
          name="description"
          content="แอปเดียว ครบ ง่าย คุ้ม เปลี่ยนทุกความไม่ง่ายในชีวิต ให้ง่ายขึ้นด้วยแอปเดียว POS สั่งอาหาร ชำระเงิน จัดการพนักงาน ดูยอดขาย ดาวน์โหลด ฟรี ได้วันนี้"
        />
        <link rel="canonical" href={`${window.location.href}`} />
        <meta property="og:url" content="https://pompkins.com/" />
        <meta
          property="og:title"
          content={`${t("pompkins")} - ${t("title.faqpage")}`}
        />

        <meta name="twitter:creator" content="POMPKINS" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content="https://pompkins.com/pompkins/faq" />
        <meta
          name="twitter:title"
          content={`${t("pompkins")} - ${t("title.faqpage")}`}
        />
      </Helmet>
      <ModalMerchantCustomer open={openWhoAmI} setOpenWhoAmI={setOpenWhoAmI} />
      <Box sx={{ backgroundColor: "#ffffff", height: "100%" }}>
        <HeroBanner />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#ffffff",
          }}
        >
          <Box
            data-aos="fade-up"
            sx={{
              width: ["100%", "100%", "100%"],
              py: [6, 6, 2],
              px: 2,
              textAlign: "center",
            }}
          >
            <Typography
              variant="h1"
              sx={{
                fontSize: ["23px", "40px", "50px"],
                fontWeight: 700,
                background:
                  "-webkit-linear-gradient(-88deg, #F19A3C 0%, #fec88e 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              {" "}
              {t("welcome.managementAdsSection")}
            </Typography>
            <Typography
              data-aos="fade-up"
              sx={{
                fontSize: ["23px", "40px", "50px"],
                fontWeight: 700,
                background:
                  "-webkit-linear-gradient(-88deg, #F19A3C 0%, #fec88e 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              {" "}
              {t("welcome.managementSub")}
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* <PompkinsNewGenSection /> */}
      <WhyPompkinsSlider />
      <HighlightSlider data={highlightComponent} />

      <MerchantType />
      <AdsSection />
      <Box sx={{ py: 4, margin: "0 auto", backgroundColor: "#ffffff" }}>
        <Partner />
      </Box>
    </>
  );
}

export default NewWelcomePage;
