import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuthStore } from "../stores/useAuthStore";
import LoadingScreen from "../components/LoadingScreen";

function ProtectRoute() {
  const { isAuthenticated, isLoading } = useAuthStore();
  const navigate = useNavigate();
  const [checkedAuth, setCheckedAuth] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      if (!isAuthenticated) {
        navigate("/login/new");
      } else {
        setCheckedAuth(true);
      }
    }
  }, [isAuthenticated, isLoading, navigate]);

  if (isLoading || !checkedAuth) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <main>
        <Outlet />
      </main>
    </div>
  );
}

export default ProtectRoute;
