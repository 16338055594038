import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

const MenuSearchBarComponent = ({
  searchValue,
  setSearchValue,
  placeholder,
  sx,
  fullWidth,
}) => {
  return (
    <TextField
      id="search-menu"
      fullWidth={fullWidth}
      variant="standard"
      placeholder={placeholder}
      value={searchValue}
      onChange={(e) => setSearchValue(e.target.value)}
      sx={{
        borderColor: "transparent",
        backgroundColor: "#f7f7f7",
        p: "2px 16px",
        borderRadius: "18px",
        ...sx,
      }}
      InputProps={{
        disableUnderline: true,
        type: "search",
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default MenuSearchBarComponent;
