import {
  Box,
  Button,
  Dialog,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import Correct from "../../assets/images/emoji_pomkins_correct320.png";
import Warnning from "../../assets/images/emoji_pomkins_exclamation320.png";
import { Close, Search } from "@mui/icons-material";
import SearchableFAQ from "./SearchFaq";
import lineQR from "../../assets/images/M_pompkins_GW.png";
import { useClipboard } from "../../utils/useClipboard";
import { useTranslation } from "react-i18next";

function ModalFeedback({ open, onClose, isPositive }) {
  const { handleCopy, copied } = useClipboard();
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: isPositive ? "center" : "flex-start",
          alignItems: "center",

          m: 3,
          px: [5, 10, 15],
          height: isPositive ? "auto" : "auto",
        }}
      >
        <IconButton
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <Close sx={{ color: "#FF961B" }} />
        </IconButton>
        {isPositive ? (
          <>
            <img
              src={Correct}
              alt="correct"
              title="correct"
              loading="lazy"
              width={"100px"}
              height={"100px"}
              style={{ width: "100px", height: "100px" }}
            />
            <Typography sx={{ fontWeight: 500, my: 2 }}>
              ขอบคุณที่ให้เราได้ช่วยเหลือคุณ
            </Typography>
          </>
        ) : (
          <>
            <img
              src={Warnning}
              alt="no-menu"
              title="no-menu"
              loading="lazy"
              width={"100px"}
              height={"100px"}
              style={{ width: "100px", height: "100px" }}
            />
            <Typography sx={{ fontWeight: 500, my: 2, textAlign: "center" }}>
              ค้นหาคำถามของคุณได้ที่นี่
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",

                width: ["250px", "300px", "300px"],
              }}
            >
              <SearchableFAQ />
            </Box>
            <Typography
              sx={{ fontWeight: 500, mt: 2, mb: 1, textAlign: "center" }}
            >
              ติดต่อพนักงาน POMPKINS
            </Typography>
            <Box
              component={"img"}
              src={lineQR}
              alt="LineQR"
              title="LineQR"
              loading="lazy"
              sx={{
                width: "20vh",
                height: "20vh",
              }}
            />
            <Button
              onClick={() => {
                window.open("https://lin.ee/cqbGrx6", "_blank");
              }}
              sx={{
                width: "200px",
                height: "50px",
                position: "relative",
                borderRadius: 0,
                fontSize: "16px",
                zIndex: 1,
                color: "white",
                bgcolor: "#f9931f",
                boxShadow: "8px 8px 0px 0px black",
                ":hover": {
                  bgcolor: "black",
                  color: "white",
                  boxShadow: "8px 8px 0px 0px #f9931f",
                },
                marginTop: "4px",
                py: 4,
              }}
            >
              {/* {copied ? "Copied" : "Copy to Clipboard"} */}
              {t("contact.linePompkins")}
            </Button>
          </>
        )}
      </Box>
    </Dialog>
  );
}

export default ModalFeedback;
