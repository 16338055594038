import { Box, Typography, Button } from "@mui/material";
import React, { useState } from "react";
import TextInput from "../../components/TextInput";
import { useForm } from "react-hook-form";
import { emailScheme, forgotPasswordScheme } from "../../libs/authScheme";
import { yupResolver } from "@hookform/resolvers/yup";
import Password from "../../assets/icon/password.png";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CheckEmail from "./CheckEmail";
import { forgotPassword } from "../../libs/authAPI/loginAPI";
import ErrorForgotModal from "./ErrorForgotModal";

function ForgotPassword() {
  const { t } = useTranslation();
  const location = useLocation();
  const email = location.state;
  const navigator = useNavigate();
  const [isSubmit, setIsSubmit] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    setError,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(forgotPasswordScheme),
  });
  const [openErrorForgotModal, setOpenErrorForgotModal] = useState(false);
  const [loginFrom, setLoginFrom] = useState("");
  const [isError, setIsError] = useState(false);

  const onSubmit = (data) => {
    const { email, phone } = data;
    const body = {
      email,
      phone,
    };
    forgotPassword(body)
      .then((res) => {
        if (res.data === "google.com" || res.data === "line") {
          setLoginFrom(res.data);
          setIsError(false);
          setOpenErrorForgotModal(true);
          return;
        }
        setIsSubmit(true);
      })
      .catch((err) => {
        console.log(err);
        setIsError(true);
        setOpenErrorForgotModal(true);
        setIsSubmit(false);
      });
  };
  if (!location.state) {
    return <Navigate to="/" />;
  }
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "95vh",
        }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            backgroundColor: "white",
            mt: 10,
            py: 6,
            px: [2, 4, 6],
            borderRadius: 3,
            boxShadow: "-1px -1px 10px 1px rgba(0, 0, 0, 0.2)",
            display: "flex",
            width: ["90%", "70%", "400px"],
            flexDirection: "column",
            gap: 1,
          }}
        >
          {isSubmit ? (
            <CheckEmail />
          ) : (
            <>
              <img
                src={Password}
                alt="logo"
                title="logo"
                loading="lazy"
                width="50px"
                height="50px"
                style={{
                  width: "50px",
                  height: "50px",
                }}
              />
              <Typography variant="h5" className="mt-3 font-bold">
                {t("forget.title")}{" "}
              </Typography>
              <Typography className="text-[#a8a8a8] mb-3">
                {t("forget.instruction")}{" "}
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <TextInput
                  label={t("emailStep.label")}
                  placeholder={t("emailStep.placholder")}
                  defaultValue={email}
                  error={errors.email?.message}
                  {...register("email", { required: true })}
                />

                <TextInput
                  label={"เบอร์โทรศัพท์"}
                  placeholder={"กรุณากรอกเบอร์โทรศัพท์ของคุณ"}
                  error={errors.phone?.message}
                  {...register("phone", { required: true })}
                />
              </Box>

              <Button
                type="submit"
                variant="contained"
                fullWidth
                disabled={!isValid}
                sx={{
                  mt: 2,
                  borderRadius: 6,
                }}
              >
                {t("btn.resetPassword")}{" "}
              </Button>
              <Box>
                <Button
                  onClick={() =>
                    navigator("/login/new?step=login", { state: email })
                  }
                  sx={{
                    m: 0,
                    p: 0,
                    minWidth: 0,
                    color: "#FF961B",
                    ":hover": {
                      backgroundColor: "#FFF7EB",
                    },
                  }}
                >
                  {t("btn.backToLogin")}{" "}
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Box>
      <ErrorForgotModal
        open={openErrorForgotModal}
        setOpenError={setOpenErrorForgotModal}
        loginFrom={loginFrom}
        isError={isError}
      />
    </>
  );
}

export default ForgotPassword;
