import { Box, Button, Dialog, Typography } from "@mui/material";
import React from "react";
import Warnning from "../../assets/images/emoji_pomkins_exclamation320.png";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function ErrorForgotModal({ open = false, setOpenError, loginFrom, isError }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Dialog open={open} onClose={() => setOpenError(false)}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          m: 3,
          px: [5, 10, 15],
        }}
      >
        <img
          src={Warnning}
          alt="no-menu"
          title="no-menu"
          loading="lazy"
          width="100px"
          height="100px"
          style={{ width: "100px", height: "100px" }}
        />
        {!isError ? (
          loginFrom === "google.com" ? (
            <Typography sx={{ fontWeight: 500, my: 2, textAlign: "center" }}>
              {t("forgotPassword.youRegisterFromGoogle")}
            </Typography>
          ) : (
            <Typography sx={{ fontWeight: 500, my: 2, textAlign: "center" }}>
              {t("forgotPassword.youRegisterFromLine")}
            </Typography>
          )
        ) : (
          <Typography sx={{ fontWeight: 500, my: 2, textAlign: "center" }}>
            {t("forgotPassword.error")}
          </Typography>
        )}

        {isError ? (
          <Button variant="outlined" onClick={() => setOpenError(false)}>
            {t("merchant.merchantClose")}
          </Button>
        ) : (
          <Button variant="outlined" onClick={() => navigate("/login/new")}>
            {t("btn.login")}
          </Button>
        )}
      </Box>
    </Dialog>
  );
}

export default ErrorForgotModal;
