import React from "react";
import { Box, Typography, Container, Paper } from "@mui/material";
import TextHeader from "../../components/TextHeader";
import SubSectionText from "../../components/SubSectionText";

import ksherTos from "../../assets/images/ksher_tos.png";
import inetTos from "../../assets/images/inet_tos.png";
import kbankTos from "../../assets/images/kbank_tos.png";

import kbankLogo from "../../assets/images/kbankLogoNew.png";
import ksherLogo from "../../assets/images/payment/ksher.png";
import inetLogo from "../../assets/images/payment/inetlogo.png";

const TermPaymentPage = () => {
  const SectionBox = ({ title, titleImage, children, image, footerText }) => (
    <Paper elevation={4} sx={{ p: 4, mb: 4, borderRadius: "15px" }}>
      {title ? (
        <Typography
          variant="h5"
          sx={{ fontWeight: 600, mb: 3, textAlign: "center" }}
        >
          {title}
        </Typography>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
          <img
            src={titleImage}
            alt="Provider Logo"
            style={{ maxHeight: "100px", width: "200px" }}
          />
        </Box>
      )}
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mb: 3 }}>
        {children}
      </Box>
      {image && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
          <img
            src={image}
            alt="Terms of Service"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </Box>
      )}
      {footerText && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="body2" sx={{ textAlign: "center" }}>
            {footerText}
          </Typography>
        </Box>
      )}
    </Paper>
  );

  return (
    <Container maxWidth="md">
      <Box sx={{ my: 5, textAlign: "center" }}>
        <TextHeader text="เงื่อนไขและค่าธรรมเนียมการให้บริการระบบรับชำระเงิน" />
      </Box>

      <SectionBox title="เงินสด / พร้อมเพย์">
        <SubSectionText
          number="-"
          text='สามารถใช้ได้ทันที ยกเว้น "พร้อมเพย์" โดยท่านสามารถตั้งค่าเลข "พร้อมเพย์" ได้ที่หน้า "ข้อมูลร้านค้า"'
        />
      </SectionBox>

      <SectionBox
        titleImage={kbankLogo}
        image={kbankTos}
        // footerText={
        //   <Typography>
        //     ฟรีอัตราค่าธรรมเนียมการใช้บริการ<br></br>*หมายเหตุ
        //     อัตราค่าธรรมเนียมเริ่มต้นธนาคารกสิกรไทย ผู้ให้บริการระบบ Payment
        //     เป็นผู้กำหนด
        //   </Typography>
        // }
      >
        <SubSectionText
          number="-"
          text="ร้านค้าต้องทำการกรอกข้อมูลใน Application POMPKINS เพื่อให้ธนาคารกสิกรไทยติดต่อกลับ"
        />
        <SubSectionText
          number="-"
          text="ธนาคารกสิกรไทยจะติดต่อร้านค้า เพื่อให้นำส่งเอกสาร สำหรับการใช้งานระบบรับชำระเงิน"
        />
        <SubSectionText
          number="-"
          text="ระยะเวลาดำเนินการประมาณ 14-21 วันทำการ"
        />
        <SubSectionText
          number="-"
          text="วงเงินการรับชำระสูงสุดเป็นไปตามเงื่อนไขที่ธนาคารกสิกรไทยกำหนด"
        />
        <SubSectionText
          number="-"
          text="บัญชีรับเงินใช้บัญชีธนาคารกสิกรไทยเท่านั้น"
        />
        <SubSectionText
          number="-"
          text="Thai QR Payment เวลาที่เงินเข้าบัญชี 23.00 น. เป็นต้นไป ของทุกวัน"
        />
        <SubSectionText
          number="-"
          text="Credit Card เวลา Settlement 23.30 น. เวลาที่เงินเข้าบัญชี 04.00 น. เป็นต้นไป ของทุกวัน"
        />
        <SubSectionText
          number="-"
          text="EDC Credit Card กรณียอดขายไม่ถึง 50,000 บาท ค่าเช่า เครื่อง EDC 400 บาท /เครื่อง/เดือน"
        />
        <SubSectionText
          number="-"
          text="EDC Credit Card กรณีที่ใช้ SIM card ค่าบริการ 100 บาท /เดือน"
        />
      </SectionBox>

      <SectionBox
        titleImage={inetLogo}
        image={inetTos}
        // footerText={
        //   <Typography>
        //     อัตราค่าธรรมเนียมเริ่มต้นรายการละ 1 บาท <br></br>*หมายเหตุ
        //     อัตราค่าธรรมเนียมเริ่มต้น INET ผู้ให้บริการระบบ Payment เป็นผู้กำหนด
        //   </Typography>
        // }
      >
        <SubSectionText
          number="-"
          text='กรณีสมัคร "ในนามร้านค้า" ต้องจดทะเบียนพาณิชย์เท่านั้น'
        />
        <SubSectionText
          number="-"
          text="ร้านค้าต้องทำการติดตั้งและลงทะเบียนระบบพ้อมป์กินส์"
        />
        <SubSectionText
          number="-"
          text="กรอกข้อมูลในแบบฟอร์ม พร้อม Upload เอกสาร เพื่อสมัครบริการระบบรับชำระเงิน"
        />
        <SubSectionText
          number="-"
          text="INET พิจารณาเอกสาร ไม่เกิน 3 วันทำการ และอนุมัติให้ร้านค้าใช้ระบบรับชำระเงินได้ทันที"
        />
        <SubSectionText
          number="-"
          text="INET จะติดต่อร้านค้า เพื่อนำส่งเอกสารใบเสนอราคา พร้อมเอกสารสัญญาบริการระบบรับชำระเงิน"
        />
        <SubSectionText
          number="-"
          text="INET จะไม่โอนเงินให้ จนกว่าร้านค้าจะส่งเอกสารที่ลงนามแล้วให้ INET"
        />
        <SubSectionText
          number="-"
          text="บัญชีรับเงินสามารถใช้ได้ทุกบัญชีธนาคาร"
        />
        <SubSectionText
          number="-"
          text="ค่าธรรมเนียมและเงื่อนไขการให้บริการระบบรับชำระเงินเป็นไปตามที่ INET กำหนด"
        />
        <SubSectionText
          number="-"
          text="การรับเงินเข้าบัญชี คิด ค่าธรรมเนียม 10 บาท ต่อรายการ (ไม่รวมภาษีมูลค่าเพิ่ม)"
        />
        <SubSectionText
          number="-"
          text="เงินเข้าบัญชีธนาคาร 22.00 น. เป็นต้นไป ของทุกวัน"
        />
      </SectionBox>

      <SectionBox
        titleImage={ksherLogo}
        image={ksherTos}
        // footerText={
        //   <Typography>
        //     อัตราค่าธรรมเนียมเริ่มต้น 1.6% <br></br>*หมายเหตุ
        //     อัตราค่าธรรมเนียมเริ่มต้น เป็นค่าบริการใช้งานระบบ Payment ของ Ksher
        //     และ POMPKINS เป็นผู้กำหนด
        //   </Typography>
        // }
      >
        <SubSectionText
          number="-"
          text="ร้านค้าต้องทําการติดตั้งและลงทะเบียนระบบพ้อมป์กินส์"
        />
        <SubSectionText
          number="-"
          text="กรอกข้อมูลในแบบฟอร์ม เพื่อสมัครบริการระบบรับชําระเงิน"
        />
        <SubSectionText
          number="-"
          text="Ksher จะทําการติดต่อร้านค้า เพื่อส่งเอกสารประกอบการสมัคร"
        />
        <SubSectionText
          number="-"
          text="ระยะเวลาดําเนินการประมาณ 7 วันทําการ"
        />
        <SubSectionText
          number="-"
          text="บัญชีรับเงินสามารถใช้ได้ทุกบัญชีธนาคาร"
        />
        <SubSectionText
          number="-"
          text="ค่าธรรมเนียมและเงื่อนไขการให้บริการระบบรับชําระเงินเป็นไปตามที่ Ksher กําหนด"
        />
        <SubSectionText
          number="-"
          text="เงินเข้าบัญชีธนาคาร 2 วันทําการ (T+2)"
        />
        <SubSectionText
          number="-"
          text="กรณี นิติบุคคล ใช้บัญชี ธนาคารกสิกรไทยและ ธนาคารกรุงเทพ เงินเข้าบัญชี 1 วันทําการ (T+1)"
        />
      </SectionBox>
    </Container>
  );
};

export default TermPaymentPage;
