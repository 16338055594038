import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  MenuList,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { forwardRef, useCallback, useEffect, useState } from "react";
import { Close } from "@mui/icons-material";
import MenuShopCard from "./MenuShopCard";
import { useTranslation } from "react-i18next";
import notFoundImg from "../assets/images/emo_pomkins_dizzy320.png";
import QrMenuShopCard from "../pages/QrPage/QrMenuShopCard";
import MenuSearchBarComponent from "./MenuSearchBarComponent";
import SearchMenuResults from "./SearchMenuResult";
import { filterMenu } from "../utils/filterMenu";
import FocusTrap from "@mui/material/Unstable_TrapFocus";

const MenuSearch = forwardRef((props, ref) => {
  const {
    isQr = false,
    handleClose,
    displayMenu,
    cartList,
    setCartList,
    handleQuantityChange,
    openNoPayment,
    openNoService,
    openCloseModal,
  } = props;
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const [filteredMenu, setFilteredMenu] = useState(undefined);
  const [resultCount, setResultCount] = useState(0);

  const handleFilterMenu = useCallback(() => {
    const newFilteredMenu = filterMenu(searchValue, displayMenu);
    setFilteredMenu(newFilteredMenu);
    setResultCount(
      newFilteredMenu.reduce((acc, item) => acc + item.menu.length, 0)
    );
  }, [searchValue, displayMenu]);

  useEffect(() => {
    handleFilterMenu();
  }, [searchValue, displayMenu, handleFilterMenu]);

  return (
    // <FocusTrap open>
    <Box
      ref={ref}
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        gap: 2,
        width: "100%",
        height: filteredMenu ? "100%" : "auto",
        position: "absolute",
        top: "0",
        overflowY: "auto",
      }}
      // tabIndex={-1}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "white",
          padding: "8px 16px",
          borderRadius: "4px",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        }}
      >
        <MenuSearchBarComponent
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          placeholder={t("searchInMenu")}
          fullWidth={true}
        />
        <IconButton
          aria-label="close"
          size="medium"
          onClick={() => handleClose()}
          sx={{
            marginLeft: "8px",
            padding: "8px",
            backgroundColor: "background.paper",
            color: "#f9931f",
          }}
        >
          <Close />
        </IconButton>
      </Box>
      <SearchMenuResults
        searchValue={searchValue}
        resultCount={resultCount}
        filteredMenu={filteredMenu}
        isQr={isQr}
        cartList={cartList}
        setCartList={setCartList}
        handleQuantityChange={handleQuantityChange}
        openNoPayment={openNoPayment}
        openNoService={openNoService}
        openCloseModal={openCloseModal}
        notFoundImg={notFoundImg}
        setSearchValue={setSearchValue}
      />
    </Box>
    // </FocusTrap>
  );
});

export default MenuSearch;
