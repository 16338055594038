import React, { useEffect } from "react";
import { Avatar, Box, Button, Typography } from "@mui/material";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { formScheme } from "./formScheme";
import TextInput from "../../components/TextInput";
import locationImg from "../../assets/image/location-img.png";
import phoneImg from "../../assets/image/phone-img.png";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import lineQR from "../../assets/images/M_pompkins_GW.png";

function ContactSalesPage() {
  const { t } = useTranslation();
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/contactus",
      title: "ContactSalesPage",
    });
  }, []);
  const locationNPฺBangkapi = {
    lat: 13.769697657389452,
    lng: 100.63912689685822,
  };
  // const locationNPChaengwatana = { lat: 13.769633, lng: 100.639403 };
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm({
    mode: "all",
    resolver: yupResolver(formScheme),
  });

  const onSubmit = (data) => {};
  return (
    <>
      <Helmet>
        <title>
          {t("pompkins")} - {t("nav.contactUs")}
        </title>
        <meta
          name="description"
          content="แอปเดียว ครบ ง่าย คุ้ม เปลี่ยนทุกความไม่ง่ายในชีวิต ให้ง่ายขึ้นด้วยแอปเดียว POS สั่งอาหาร ชำระเงิน จัดการพนักงาน ดูยอดขาย ดาวน์โหลด ฟรี ได้วันนี้"
        />
        <link rel="canonical" href={`${window.location.href}`} />
        <meta property="og:image" content={`${phoneImg}`} />
        <meta property="og:url" content="https://pompkins.com/contactus" />
        <meta
          property="og:title"
          content={`${t("pompkins")} - ${t("nav.contactUs")}`}
        />

        <meta name="twitter:creator" content="POMPKINS" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content={`${phoneImg}`} />
        <meta name="twitter:url" content="https://pompkins.com/contactus" />
        <meta
          name="twitter:title"
          content={`${t("pompkins")} - ${t("nav.contactUs")}`}
        />
      </Helmet>
      <Box>
        <Box sx={{ width: "100%", height: "600px" }}>
          <GoogleMap
            mapContainerStyle={{ width: "100%", height: "100%" }}
            center={locationNPฺBangkapi}
            zoom={16}
            options={{
              zoomControl: true,
              streetViewControl: false,
              mapTypeControl: true,
              fullscreenControl: true,
              clickableIcons: true,
            }}
          >
            {locationNPฺBangkapi && <Marker position={locationNPฺBangkapi} />}
          </GoogleMap>
        </Box>
        <Box
          sx={{
            display: "flex",
            mx: ["15px", "60px", "80px", "150px"],
            flexDirection: ["column-reverse", "column-reverse", "row"],
            justifyContent: "space-between",
            py: "48px",
          }}
        >
          <Box sx={{ flex: "0 0 50%", px: "15px", mb: [2, 2, 0] }}>
            <Typography variant="h5" fontSize={"20px"} fontWeight={600}>
              สำนักงานใหญ่
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 1,
                mt: 1,
                mb: 2,
              }}
            >
              <img
                src={locationImg}
                alt="location img"
                title="location img"
                loading="lazy"
                width={"30px"}
                height={"30px"}
                style={{ width: "30px", height: "30px" }}
              />
              <Typography
                variant="p"
                fontSize={"16px"}
                fontWeight={600}
                color={"#7d8a8b"}
              >
                2/1-2 ซอยลาดพร้าว 113 ถนนลาดพร้าว แขวงคลองจั่น เขตบางกะปิ
                กรุงเทพมหานคร 10240
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 1,
              }}
            >
              <img
                src={phoneImg}
                alt="location img"
                title="location img"
                loading="lazy"
                width={"30px"}
                height={"30px"}
                style={{ width: "30px", height: "30px" }}
              />
              <Typography
                variant="p"
                fontSize={"16px"}
                fontWeight={600}
                className="text-primary"
              >
                02 136 2930
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              zIndex: 1000,
              // flex: "0 0 50%",
              borderRadius: "15px",
              position: "relative",
              mt: ["-10rem", "-10rem", "-10rem"],
              mb: [2, 2, 0],
              backgroundColor: "#ffffff",
              // px: "15px",
            }}
          >
            <Box sx={{ p: 3, flexDirection: "column" }}>
              <Typography fontSize={"28px"} fontWeight={700}>
                ติดต่อเรา
              </Typography>
              <Box
                component={"img"}
                src={lineQR}
                alt="LineQR"
                title="LineQR"
                loading="lazy"
              />
              {/* <Typography fontSize={"18px"} fontWeight={700} color={"#7d8a8b"}>
                เราอยากได้การติดต่อกลับจากคุณ
              </Typography>
              <Typography sx={{ mt: 2 }}>ชื่อ - นามสกุล *</Typography>
              <TextInput
                placeholder="กรุณากรอกชื่อ - นามสกุล"
                error={errors.full_name?.message}
                {...register("full_name", { required: true })}
              />
              <Typography sx={{ mt: 2 }}>เบอร์ติดต่อ *</Typography>
              <TextInput
                placeholder="กรุณากรอกเบอร์ติดต่อ"
                error={errors.mobileNumber?.message}
                {...register("mobileNumber", { required: true })}
              />
              <Typography sx={{ mt: 2 }}>อีเมล *</Typography>
              <TextInput
                placeholder="กรุณากรอกอีเมล"
                error={errors.email?.message}
                {...register("email", { required: true })}
              />
              <Typography sx={{ mt: 2 }}>ข้อความ *</Typography>
              <TextInput
                placeholder="กรุณากรอกข้อความ"
                error={errors.message?.message}
                multiline
                rows={4}
                {...register("message", { required: true })}
              />
              <Button
                fullWidth
                variant="contained"
                size="large"
                disabled={!isValid}
                sx={{
                  borderRadius: "2rem",
                  fontSize: "17px",
                  fontWeight: 500,
                  my: 3,
                  px: 4,
                  boxShadow: "0 3px 12px 0 rgba(255,161,9,.589)",
                  ":hover": {
                    boxShadow: 0,
                  },
                }}
                onSubmit={handleSubmit(onSubmit)}
              >
                ยืนยัน
              </Button> */}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ContactSalesPage;
