import axios from "axios";
import { getCookie } from "../utils/getToken";

const token = getCookie("token");
export const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
  baseURL: process.env.REACT_APP_BASE_API_URL,
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      document.cookie = "token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:00 GMT";
      window.location.href = "/login/new";
    }
    return Promise.reject(error);
  }
);
