import { useState, useCallback, useEffect } from "react";
import { getReviewByMerchantUUID } from "../libs/merchantAPI/merchantAPI";

const useReview = (uuid, open) => {
  const [displayReviews, setDisplayReviews] = useState([]);
  const [pointPercentages, setPointPercentages] = useState({
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  });

  const fetchReviews = useCallback(() => {
    getReviewByMerchantUUID(uuid)
      .then((res) => {
        const newPointCounts = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
        res.data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );

        res.data.forEach((item) => {
          newPointCounts[item.point.toString()]++;
        });

        const totalCount = res.data.length;
        const newPointPercentages = {};
        for (const [point, count] of Object.entries(newPointCounts)) {
          newPointPercentages[point] = (count / totalCount) * 100;
        }

        setPointPercentages(newPointPercentages);
        setDisplayReviews(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [uuid]);

  useEffect(() => {
    if (open) {
      fetchReviews();
    }
  }, [open, fetchReviews]);

  return { displayReviews, pointPercentages };
};

export default useReview;
