import { AppBar, Box, Toolbar, Typography, TextField } from "@mui/material";
import React, { useState } from "react";
import PrompkinsIcon from "../assets/images/logo_large_size.png";
import PompkinsFoodIcon from "../assets/images/pompkins-food-nav.png";
import { AccountCircleOutlined, Edit } from "@mui/icons-material";
import { Outlet } from "react-router-dom";
import LoadingScreen from "../components/LoadingScreen";
import BottomNavQR from "./BottomNavQR";

function LayoutTableQr() {
  const [name, setName] = useState(localStorage.getItem("name")); // Initialize name from localStorage
  const [isEditingName, setIsEditingName] = useState(false); // State to control name editing
  const [newName, setNewName] = useState(name); // State to store the new name
  const handleSaveName = () => {
    if (newName.trim() === "") {
      return;
    }

    localStorage.setItem("name", newName);
    setIsEditingName(false);
    setName(newName);
  };

  const handleNameInputChange = (e) => {
    setNewName(e.target.value);
  };

  const handleNameInputKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSaveName();
    }
  };

  if (!name) {
    return <LoadingScreen />;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="sticky" className="bg-white" sx={{ width: "100%" }}>
        <Toolbar sx={{ display: "flex", flexDirection: "row" }}>
          <Box sx={{ display: "flex", flex: 1, alignItems: "center" }}>
            <AccountCircleOutlined
              className="text-primary"
              sx={{ mt: isEditingName ? "5px" : 0 }}
            />

            {isEditingName ? ( // Show input field if editing name
              <TextField
                variant="standard"
                value={newName}
                onChange={handleNameInputChange}
                onBlur={handleSaveName}
                onKeyDown={handleNameInputKeyPress}
                autoFocus
                InputProps={{
                  disableunderline: true,
                  sx: {
                    ml: "7px",
                    fontSize: "18px",
                    border: 1,
                    width: "40%",
                    py: "0px !important",
                  },
                }}
              />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography
                  ml={1}
                  fontSize={"18px"}
                  color={"black"}
                  onClick={() => setIsEditingName(true)}
                  style={{ cursor: "pointer" }}
                >
                  {name}
                  <Edit
                    sx={{
                      width: "14px",
                      height: "14px",
                      ml: "4px",
                      color: "rgba(0, 0, 0, 0.6)",
                    }}
                    className="edit-icon"
                  />
                </Typography>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={PompkinsFoodIcon}
              alt="logo"
              title="logo"
              loading="lazy"
              width="250px"
              height="60px"
              style={{
                width: "300px",
                height: "auto",
                objectFit: "contain",
              }}
            />
          </Box>
          {/* <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "end",
            }}
          /> */}
        </Toolbar>
      </AppBar>
      <main className="flex-grow min-h-screen">
        <Outlet />
      </main>
      <BottomNavQR />
    </Box>
  );
}

export default LayoutTableQr;
