import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Typography,
  Box,
  Card,
  CardContent,
  CardActionArea,
  Divider,
} from "@mui/material";
import {
  DeliveryDining,
  Favorite,
  FavoriteBorder,
  LocationOnOutlined,
  Star,
} from "@mui/icons-material";
import useSnackBar from "../stores/useSnackBar";
import { useTranslation } from "react-i18next";
import { useAuthStore } from "../stores/useAuthStore";
import { useLocation, useNavigate } from "react-router-dom";
import { putFavorite } from "../libs/customerAPI/favoriteAPI";

import DefaultProfile from "../assets/images/pompkins_food_logo.png";

const FoodCard = React.memo(({ items, sx }) => {
  const location = useLocation();
  const { isAuthenticated, updateUser, user, setLocationFrom } = useAuthStore();
  const [isFavorite, setIsFavorite] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (isAuthenticated && user.favorite) {
      if (user.favorite.includes(items.bmerchantId)) {
        setIsFavorite(true);
      } else {
        setIsFavorite(false);
      }
    }
  }, [isAuthenticated, items.bmerchantId, user]);
  const { openSnack } = useSnackBar();
  const navigator = useNavigate();

  const handleFavClick = useCallback(() => {
    if (isAuthenticated) {
      let updateFavorite = [];

      if (isFavorite) {
        updateFavorite = user.favorite.filter(
          (favId) => favId !== items.bmerchantId
        );

        openSnack(t("snack.unFav"));
      } else {
        updateFavorite = [...user.favorite, items.bmerchantId];
        openSnack(t("snack.fav"));
      }
      updateUser({ ...user, favorite: updateFavorite });
      putFavorite(updateFavorite, user.uuid);
      setIsFavorite(!isFavorite);
    } else {
      setLocationFrom(location.pathname);
      navigator("/login/new");
    }
  }, [
    isAuthenticated,
    isFavorite,
    updateUser,
    user,
    openSnack,
    t,
    items.bmerchantId,
    setLocationFrom,
    location.pathname,
    navigator,
  ]);

  const handleCardClick = useCallback(() => {
    localStorage.setItem("scrollPosition", window.scrollY);
    navigator(`/merchant/${items.id}/${items.bmerchantId}/${items.title}`);
  }, [items.bmerchantId, items.id, items.title, navigator]);

  const favoriteIcon = useMemo(() => {
    return isFavorite ? (
      <Favorite sx={{ color: "#FF961B", width: "15px", height: "15px" }} />
    ) : (
      <FavoriteBorder
        sx={{ color: "#FF961B", width: "15px", height: "15px" }}
      />
    );
  }, [isFavorite]);

  return (
    <Card
      sx={{
        position: "relative",
        mb: 2,
        borderRadius: "15px",
        width: "233px",
        boxShadow: "5px 4px 16.1px rgba(0, 0, 0, 0.1)",
        ...sx,
      }}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
          handleFavClick();
        }}
        style={{ zIndex: 1001 }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 7,
            right: 7,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            opacity: "90%",
            borderRadius: "50%",
            p: "6px",
            cursor: "pointer",
            zIndex: 1000,
            height: "23px",
            width: "23px",
          }}
        >
          {favoriteIcon}
        </Box>
      </div>
      <CardActionArea onClick={handleCardClick}>
        <Box
          sx={{
            position: "absolute",
            top: "20px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {Array.isArray(items.features) && items.features.length > 0 ? (
            items.features.map((feature, index) => (
              <Box
                key={index}
                sx={{
                  backgroundColor: "orange",
                  padding: "5px 10px",
                  borderBottomRightRadius: 6,
                  borderTopRightRadius: 6,
                  color: "white",
                  opacity: "90%",
                  mb: 1,
                }}
              >
                <Typography sx={{ fontSize: ["9px", "13px", "13px"] }}>
                  {feature}
                </Typography>
              </Box>
            ))
          ) : (
            <Box
              sx={{
                backgroundColor: "orange",
                padding: "5px 10px",
                borderBottomRightRadius: 6,
                borderTopRightRadius: 6,
                color: "white",
                opacity: "90%",
                mb: 1,
                display: "none",
              }}
            >
              <Typography sx={{ fontSize: ["9px", "13px", "13px"] }}>
                {t("freeDelivery2")}
              </Typography>
            </Box>
          )}
        </Box>

        <div>
          <Box
            sx={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              minWidth: "240px",
            }}
          >
            {!items.isOpen && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "#333333",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  backdropFilter: "blur(2px)",
                  zIndex: 1,
                  opacity: items.isOpen ? 0 : 0.9,
                }}
              >
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  {" "}
                  {t("merchant.closeTime")}
                </Typography>
              </Box>
            )}

            <Box
              alt="food"
              component="img"
              loading="lazy"
              title={items.title}
              src={items.img ?? DefaultProfile}
              width="100%"
              height="144px"
              sx={{
                width: "100%",
                height: "144px",
                borderTopLeftRadius: "15px",
                borderTopRightRadius: "15px",
                objectFit: items.img ? "cover" : "contain",
                borderBottom: "1px #f3f3f3 solid",
              }}
            />
          </Box>
        </div>
        <Box
          sx={{
            background:
              "linear-gradient(145.57deg, #FFDEBA 39.48%, #FFC07A 96.14%)",
            color: "black",
            position: "absolute",
            top: 0,
            left: 0,
            padding: "4px 15px",
            borderRadius: "0px 0px 38px",
            opacity: "90%",
            zIndex: 1001,
          }}
        >
          <Typography fontSize="12px"> แนะนำ </Typography>
        </Box>
        <CardContent sx={{ pt: 1 }}>
          <Typography noWrap variant="h6" sx={{ textOverflow: "ellipsis" }}>
            {items.title}
          </Typography>
          <Typography
            className="line-clamp-1"
            variant="body2"
            color="textSecondary"
          >
            <LocationOnOutlined
              sx={{
                color: "#FF961B",
                ml: "-3px",
                fontSize: "20px",
                mt: "-3px",
              }}
            />
            {items.location?.address?.address_formatted
              ? items.location.address.address_formatted
              : items.location}
          </Typography>
          <Typography mt={"4px"} variant="body2" sx={{ color: "#FF961B" }}>
            {items.shopType}
          </Typography>
          <Divider sx={{ my: 1, borderStyle: "dashed" }} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Star sx={{ color: "rgb(250, 175, 0)", mt: "-5px" }} />
              <Typography variant="body2">({items.rating})</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <DeliveryDining sx={{ marginLeft: 2, color: "#FF961B" }} />
              <Typography
                variant="body2"
                sx={{ marginLeft: "4px", color: "#FF961B" }}
              >
                {items.distance}
              </Typography>
            </Box>
          </Box>
          {/* <Typography
            variant="body2"
            sx={{ color: "#FF961B", fontSize: "12px", paddingTop: "6px" }}
          >
            <span className="text-red-500">*</span> {t("actualDriving")}
          </Typography> */}
        </CardContent>
      </CardActionArea>
    </Card>
  );
});

export default FoodCard;
