import { useState, useEffect } from "react";
import {
  getBMerchantById,
  getMerchantById,
  getServiceBMerchant,
} from "../libs/merchantAPI/merchantAPI";

export const useMerchantData = (merchantId, bMerchantUUID, bMerchantID) => {
  const [merchantData, setMerchantData] = useState(null);
  const [bMerchantData, setBMerchantData] = useState(null);
  const [service, setService] = useState([]);
  const [isFetch, setIsFetch] = useState(false);

  // useEffect(() => {
  //   console.log("merchantId", merchantId);
  //   console.log("bMerchantUUID", bMerchantUUID);
  // }, [bMerchantUUID, merchantId]);

  // useEffect(() => {
  //   console.log("merchantData", merchantData);
  //   console.log("service", service);
  // }, [merchantData, service]);

  useEffect(() => {
    const fetchMerchantData = async () => {
      try {
        if (
          merchantId === undefined ||
          bMerchantUUID === undefined ||
          merchantId === null
        ) {
          setIsFetch(true);
          return;
        }
        const res = await getMerchantById(merchantId);
        setMerchantData(res.data);
        const bMerchantRes = await getBMerchantById(bMerchantID);
        setBMerchantData(bMerchantRes.data);
        const serviceRes = await getServiceBMerchant(bMerchantUUID);
        setService(serviceRes.data);
        setIsFetch(true);
      } catch (error) {
        console.error("Error fetching merchant data:", error);
      }
    };

    fetchMerchantData();
  }, [merchantId, bMerchantUUID, bMerchantID]);

  return { merchantData, bMerchantData, service, isFetch };
};
