import React from "react";
import { Box, Breadcrumbs } from "@mui/material";
import { NavigateNext } from "@mui/icons-material";

const MerchantBreadcrumbs = ({ breadcrumbs }) => (
  <Box sx={{ py: 2, pl: 2 }}>
    <Breadcrumbs
      separator={<NavigateNext fontSize="small" />}
      aria-label="breadcrumb"
    >
      {breadcrumbs}
    </Breadcrumbs>
  </Box>
);

export default MerchantBreadcrumbs;
