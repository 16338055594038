import { useEffect, useState } from "react";
import {
  getBMerchantById,
  getMerchantById,
  getServiceBMerchant,
} from "../libs/merchantAPI/merchantAPI";

const useFetchMerchantData = (bmerchant_id, merchant_id) => {
  const [bmerchantData, setBMerchantData] = useState({});
  const [merchantData, setMerchantData] = useState({});
  const [bmerchantService, setBMerchantService] = useState({});
  const [isFetch, setIsFetch] = useState(false);

  useEffect(() => {
    getBMerchantById(bmerchant_id).then((res) => {
      const data = res.data;
      setBMerchantData(data);
      getServiceBMerchant(res.data.uuid).then(({ data }) => {
        setBMerchantService(data);
        setIsFetch(true);
      });
    });
    getMerchantById(merchant_id).then((res) => {
      setMerchantData(res.data);
    });
  }, [bmerchant_id, merchant_id]);

  return { bmerchantData, merchantData, bmerchantService, isFetch };
};

export default useFetchMerchantData;
