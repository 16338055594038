import React, { useState } from "react";
import {
  Autocomplete,
  TextField,
  InputAdornment,
  Box,
  Typography,
  IconButton,
  createFilterOptions,
} from "@mui/material";
import Search from "@mui/icons-material/Search";
import FaqData from "./FaqData";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const filter = createFilterOptions();

function SearchableFAQ() {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const { faqData, applicationFaqData } = FaqData();
  const navigator = useNavigate();
  const combineData = (data, sourceType) => {
    return data.flatMap((section) =>
      section.data.flatMap((item) => [
        {
          type: "question",
          text: item.question,
          sourceType,
          relatedQuestion: item.question,
          category: section.type,
        },
        {
          type: "answer",
          text: item.answer,
          sourceType,
          relatedQuestion: item.question,
          category: section.type,
        },
      ])
    );
  };

  const combinedOptions = [
    ...combineData(faqData, "General"),
    ...combineData(applicationFaqData, "Application"),
  ];
  const handleNoResultsClick = () => {
    window.open("https://lin.ee/cqbGrx6", "_blank");
  };

  const handleSearch = () => {
    if (selectedOption) {
      navigator(
        `/pompkins/FAQ/categories/${selectedOption.sourceType}/sections/${selectedOption.category}/${selectedOption.relatedQuestion}`
      );
      window.location.reload();
      // Display the answer or handle the search action as needed
    }
  };

  const filteredOptions = inputValue
    ? combinedOptions.filter((option) =>
        option.text.toLowerCase().includes(inputValue.toLowerCase())
      )
    : [];

  const noOptions = filteredOptions.length === 0 && inputValue;

  if (noOptions) {
    filteredOptions.push({ text: "ไม่พบคำถามนี้", type: "noResults" });
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Autocomplete
        freeSolo
        options={filteredOptions}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== "" && filtered.length === 0) {
            filtered.push({ text: `${params.inputValue}`, type: "noResults" });
          }

          return filtered;
        }}
        getOptionLabel={(option) => option.text || inputValue}
        disablePortal
        fullWidth
        disableClearable
        sx={{
          width: "100%",
        }}
        renderOption={(props, option) => {
          const isExisting = filteredOptions.some((existingOption) => {
            if (
              option.type === "answer" &&
              existingOption.relatedQuestion === option.relatedQuestion
            ) {
              return true;
            } else {
              return false;
            }
          });

          if (option.type === "answer" && isExisting) {
            return null;
          }

          return (
            <li {...props} key={option.text}>
              <div>
                <div>
                  {noOptions && (
                    <Box
                      onClick={handleNoResultsClick}
                      sx={{ cursor: "pointer" }}
                    >
                      <Typography variant="body2" color="black">
                        {t("NotFoundQuestionContactStaff")}
                      </Typography>
                    </Box>
                  )}
                  {option.type === "answer" && !isExisting && (
                    <Typography
                      variant="body2"
                      onClick={() => setSelectedOption(option)}
                    >
                      <strong>{t("relatedQuestion")}</strong>{" "}
                      {option.relatedQuestion}
                    </Typography>
                  )}

                  {option.type === "question" && (
                    <Typography
                      variant="body2"
                      onClick={() => setSelectedOption(option)}
                    >
                      <strong>{t("relatedQuestion")}</strong>{" "}
                      {option.relatedQuestion}
                    </Typography>
                  )}
                </div>
              </div>
            </li>
          );
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              backgroundColor: "white",
              boxShadow: "0px 2.29907px 11.4953px rgb(0 0 0 / 15%)",
            }}
            fullWidth
            InputProps={{
              ...params.InputProps,
              sx: {
                pr: "9px !important",
              },
              type: "search",
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleSearch} aria-label="search">
                    <Search className="text-primary" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            placeholder={t("typeYourQuestionHere")}
          />
        )}
      />
    </Box>
  );
}

export default SearchableFAQ;
