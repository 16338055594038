import React, { useEffect, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import foodFaviconIco from "../assets/images/favicon_package_pompkins_food/favicon-32x32.png";
import largeFoodPngIcon from "../assets/images/favicon_package_pompkins_food/PKFCircle-01.png";
import appleTouchFoodIcon from "../assets/images/favicon_package_pompkins_food/apple-touch-icon.png";
import imagePompkinOG from "../assets/images/coverFoodWeb-07.png";
import { useTranslation } from "react-i18next";
import ScreenRotationIcon from "@mui/icons-material/ScreenRotation";

function MobileContainer() {
  const version = new Date().toISOString().split("T")[0];
  const { t } = useTranslation();
  const [orientation, setOrientation] = useState(
    window.screen.orientation.type
  );

  const isMobile = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  useEffect(() => {
    if (!isMobile()) {
      setOrientation("");
      return;
    }
    // Check if the window.screen Orientation API is supported
    if (window.screen.orientation) {
      // Function to lock the window.screen orientation
      const lockOrientation = async (orientation) => {
        try {
          if (window.screen.orientation.type !== orientation) {
            await window.screen.orientation.lock(orientation);
          }
        } catch (error) {
          console.error("Failed to lock orientation:", error);
        }
      };

      // Lock the orientation to portrait-primary on component mount
      lockOrientation("portrait-primary");

      // Event listener for orientation changes
      const handleOrientationChange = () => {
        const currentOrientation = window.screen.orientation.type;
        setOrientation(currentOrientation);
        // Re-lock if the orientation is not portrait-primary
        if (currentOrientation !== "portrait-primary") {
          lockOrientation("portrait-primary");
        }
      };

      window.screen.orientation.addEventListener(
        "change",
        handleOrientationChange
      );

      // Cleanup function to remove the event listener
      return () => {
        window.screen.orientation.removeEventListener(
          "change",
          handleOrientationChange
        );
      };
    } else {
      console.warn(
        "window.screen Orientation API is not supported in this browser."
      );
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta
          property="og:image"
          content={`{${imagePompkinOG}?v=${version}}`}
        />
        <meta
          name="twitter:image"
          content={`{${imagePompkinOG}?v=${version}}`}
        />
        <link rel="image/png" href={`{${foodFaviconIco}`} sizes="any" />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href={`{${largeFoodPngIcon}`}
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`{${appleTouchFoodIcon}`}
        />
      </Helmet>
      <Box sx={{ width: "100%", bgcolor: "#f9f9f9" }}>
        {orientation.includes("landscape") && (
          <Box
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              bgcolor: "rgba(0, 0, 0, 0.8)",
              color: "white",
              zIndex: 1000,
              animation: "fadeIn 0.3s ease-in-out",
            }}
          >
            <IconButton
              sx={{
                bgcolor: "white",
                color: "black",
                mb: 2,
                "&:hover": { bgcolor: "rgba(255, 255, 255, 0.8)" },
              }}
            >
              <ScreenRotationIcon fontSize="large" />
            </IconButton>
            <Typography variant="h6" align="center" gutterBottom>
              {t("mobile.pleaseRotateYourDevice")}
            </Typography>
            <Typography variant="body2" align="center">
              {t("mobile.portrait")}
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            maxWidth: "440px",
            height: "100%",
            m: "0 auto",
            bgcolor: "white",
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </>
  );
}

export default MobileContainer;
