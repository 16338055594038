import React from "react";
import { Skeleton, Box, Card, CardContent, Divider } from "@mui/material";

function FoodCardSkeleton({ sx }) {
  return (
    <Card
      sx={{
        position: "relative",
        mb: 2,
        borderRadius: "15px",
        width: "233px",
        boxShadow: "5px 4px 16.1px rgba(0, 0, 0, 0.1)",
        ...sx,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 7,
          right: 7,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "white",
          opacity: "90%",
          borderRadius: "50%",
          p: "6px",
          zIndex: 1000,
          height: "23px",
          width: "23px",
        }}
      >
        <Skeleton variant="circular" width={15} height={15} />
      </Box>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          minWidth: "240px",
        }}
      >
        <Skeleton
          variant="rectangular"
          width="100%"
          height="144px"
          sx={{
            borderTopLeftRadius: "15px",
            borderTopRightRadius: "15px",
          }}
        />
      </Box>
      <CardContent sx={{ pt: 1 }}>
        <Skeleton variant="text" width="80%" height={24} />
        <Skeleton variant="text" width="60%" height={20} />
        <Skeleton variant="text" width="40%" height={20} />
        <Divider sx={{ my: 1, borderStyle: "dashed" }} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Skeleton variant="circular" width={20} height={20} />
            <Skeleton variant="text" width={30} height={20} />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Skeleton variant="circular" width={20} height={20} />
            <Skeleton variant="text" width={30} height={20} />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default FoodCardSkeleton;
