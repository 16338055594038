import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { keyframes } from "@emotion/react";
import { useTranslation } from "react-i18next";

// Define keyframes for the up-and-down animation
const upDownAnimation = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
`;

function SafariPrompt() {
  const { t } = useTranslation();

  function isLikelySafariViewController() {
    const windowHeight = window.innerHeight;
    const screenHeight = window.screen.height;

    // Calculate the ratio of window height to screen height
    const heightRatio = windowHeight / screenHeight;

    // Define known ratios for different iPhone models based on logical screen heights
    const knownRatios = {
      896: { svc: 721 / 896, safari: 714 / 896 }, // iPhone XS Max, XR, 11, 11 Pro Max
      812: { svc: 724 / 812, safari: 736 / 812 }, // iPhone X, XS, 11 Pro, 13 mini, 12 mini
      736: { svc: 652 / 736, safari: 667 / 736 }, // iPhone 6s Plus, 7 Plus, 8 Plus
      667: { svc: 559 / 667, safari: 548 / 667 }, // iPhone 6, 6s, 7, 8, SE 2nd, SE 3rd (100% correct)
      568: { svc: 460 / 568, safari: 548 / 568 }, // iPhone 5, 5C, 5S, SE 1st
      480: { svc: 372 / 480, safari: 460 / 480 }, // iPhone 4, 4S
      844: { svc: 724 / 844, safari: 654 / 844 }, // iPhone 12, 12 Pro, 13, 13 Pro, 14, 14 Pro
      926: { svc: 812 / 926, safari: 896 / 926 }, // iPhone 12 Pro Max, 13 Pro Max, 14 Pro Max
      852: { svc: 666 / 852, safari: 659 / 852 }, // iPhone 15, 15 Pro (100% correct)
      932: { svc: 812 / 932, safari: 896 / 932 }, // iPhone 15 Pro Max, 15 Plus, 14 Plus
      874: { svc: 812 / 874, safari: 844 / 874 }, // iPhone 16 Pro
      956: { svc: 844 / 956, safari: 932 / 956 }, // iPhone 16 Pro Max
    };

    const ratios = knownRatios[screenHeight];

    if (!ratios) {
      return "Unknown";
    }

    const { svc: svcRatio, safari: safariRatio } = ratios;

    if (Math.abs(heightRatio - svcRatio) === 0) {
      return true;
    } else if (Math.abs(heightRatio - safariRatio) === 0) {
      return false;
    } else {
      return true;
    }
  }

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isIOS = /iPhone|iPad|iPod/.test(userAgent);
    const isSVC = isLikelySafariViewController();

    if (isIOS && isSVC) {
      // Logic to show the prompt
      const promptElement = document.getElementById("safari-prompt");
      if (promptElement) {
        promptElement.style.display = "block";
        setTimeout(() => {
          promptElement.style.opacity = 1;
          promptElement.style.transform = "translateY(0)";
        }, 10);

        setTimeout(() => {
          promptElement.style.opacity = 0;
          promptElement.style.transform = "translateY(20px)";
          setTimeout(() => {
            promptElement.style.display = "none";
          }, 500);
        }, 10000);
      }
    }
  }, []);

  return (
    <Box
      id="safari-prompt"
      sx={{
        display: "none",
        opacity: 0,
        transform: "translateY(20px)",
        transition: "opacity 0.5s ease, transform 0.5s ease",
        position: "fixed",
        bottom: "10px",
        right: "10px",
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        color: "white",
        padding: "10px",
        borderRadius: "5px",
        zIndex: 1000,
        animation: `${upDownAnimation} 2s infinite`,
      }}
    >
      <Typography variant="body2">{t("qrtable.tabSafari")}</Typography>
      <Box
        sx={{
          position: "absolute",
          bottom: "-10px",
          right: "10px",
          width: "0",
          height: "0",
          borderLeft: "10px solid transparent",
          borderRight: "10px solid transparent",
          borderTop: "10px solid rgba(0, 0, 0, 0.7)",
        }}
      />
    </Box>
  );
}

export default SafariPrompt;
