import { AssignmentTurnedIn, Home } from "@mui/icons-material";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getBMerchantById } from "../libs/merchantAPI/merchantAPI";
import { useTranslation } from "react-i18next";

function BottomNavQR() {
  const location = useLocation();
  const path = location.pathname;
  const navigator = useNavigate();
  const { merchant_id, bmerchant_id, token, tableNumber, transaction_uuid } =
    useParams();
  const [value, setValue] = useState(0);
  const [bMerchant_uuid, setBMerchant_uuid] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    if (path.includes("menu")) {
      setValue(0);
    } else if (path.includes("checkbill")) {
      setValue(1);
    }
  }, [path]);
  useEffect(() => {
    getBMerchantById(bmerchant_id).then((res) => {
      setBMerchant_uuid(res.data.uuid);
    });
  }, [bmerchant_id]);
  if (path.includes("payment")) {
    return null;
  }

  return (
    <Paper
      sx={{ boxShadow: 0, position: "sticky", bottom: 0, left: 0, right: 0 }}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          if (newValue === 1) {
            navigator(
              `/table-merchant/${token}/${merchant_id}/${bmerchant_id}/${tableNumber}/${transaction_uuid}/checkbill/${bMerchant_uuid}`
            );
          } else {
            navigator(
              `/table-merchant/${token}/${merchant_id}/${bmerchant_id}/${tableNumber}/${transaction_uuid}/menu`
            );
          }
        }}
      >
        <BottomNavigationAction
          sx={{
            "& .Mui-selected": {
              color: "#f9931f !important",
            },
          }}
          label={t("qrtable.menu")}
          icon={<Home className="text-primary" />}
        />
        <BottomNavigationAction
          sx={{
            "& .Mui-selected": {
              color: "#f9931f !important",
            },
          }}
          label={t("qrtable.ordered")}
          icon={<AssignmentTurnedIn className="text-primary" />}
        />
      </BottomNavigation>
    </Paper>
  );
}

export default BottomNavQR;
