import React, { useState, useEffect } from "react";
import { Box, Typography, Divider } from "@mui/material";
import ListComponent from "./ListComponent";
import SubListComponent from "./SubListComponent";
import { useSearchParams } from "react-router-dom";
import Instruction from "./Instruction";
import ReactGA from "react-ga4";

function HowToUsePage() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/pompkins/instruction",
      title: "HowToUsePage",
    });
  }, []);
  const [state, setState] = useState("");
  const [searchParams] = useSearchParams();

  const page = searchParams.get("page");

  const renderPage = () => {
    return <Instruction state={page} />;
  };

  const howToUseData = [
    {
      title: "การแก้ไขรายละเอียดร้านค้า",
      state: "Edit Details",
      setState: () => setState("Edit Details"),
      list: [
        {
          title: "เปลี่ยนโปรไฟล์และรูปพื้นหลังร้านค้า",
          navigate: "?page=merchantProfile",
        },
        {
          title: "เปลี่ยนรายละเอียดข้อมูลร้านค้า",
          navigate: "?page=merchantDetail",
        },
      ],
    },
    {
      title: "การจัดการร้านค้า",
      state: "Manage Merchant",
      setState: () => setState("Manage Merchant"),
      list: [
        { title: "ตั้งค่าเวลา เปิด-ปิด ร้าน", navigate: "?page=openCloseTime" },
        {
          title: "ตั้งค่าการให้บริการร้านค้า",
          navigate: "?page=serviceMerchant",
        },
        {
          title: "ตั้งค่าการให้บริการออนไลน์",
          navigate: "?page=onlineService",
        },
        { title: "การออกแบบใบเสร็จรับเงิน", navigate: "?page=billDesign" },
        { title: "ตั้งค่าการจัดส่ง", navigate: "?page=deliverFee" },
      ],
    },
    {
      title: "เอกสารสมัครระบบรับชำระเงิน",
      state: "Document and Payment",
      setState: () => setState("Document and Payment"),
      list: [
        {
          title: "วิธีการสมัครระบบรับชำระเงิน",
          navigate: "?page=registerPayment",
        },
      ],
    },
    {
      title: "การจัดการพนักงาน",
      state: "Manage Staff",
      setState: () => setState("Manage Staff"),
      list: [
        { title: "เพิ่มพนักงาน", navigate: "?page=addStaff" },
        {
          title: "ลบพนักงาน",
          navigate: "?page=deleteStaff",
        },
        { title: "แก้ไขข้อมูลพนักงาน", navigate: "?page=editStaff" },
        { title: "เข้าสู่ระบบด้วยพนักงาน", navigate: "?page=loginWithStaff" },
      ],
    },
    {
      title: "ระบบจัดการเมนู",
      state: "Manage Menu",
      setState: () => setState("Manage Menu"),
      list: [
        { title: "สร้างเมนู", navigate: "?page=addMenu" },
        {
          title: "เพิ่มประเภท",
          navigate: "?page=addCategory",
        },
        { title: "เพิ่มตัวเลือก", navigate: "?page=addOption" },
      ],
    },
    {
      title: "ระบบจัดการโต๊ะ",
      state: "Manage Table",
      setState: () => setState("Manage Table"),
      list: [
        { title: "วิธีการเพิ่มและลบโต๊ะ", navigate: "?page=addTable" },
        {
          title: "วิธีย้ายโต๊ะ",
          navigate: "?page=moveTable",
        },
        { title: "วิธีเช็คบิล", navigate: "?page=checkBill" },
      ],
    },
    {
      title: "ระบบจัดการคิว",
      state: "Manage Queue",
      setState: () => setState("Manage Queue"),
      list: [
        { title: "วิธีการเพิ่มคิว", navigate: "?page=addQueue" },
        {
          title: "วิธีการลบคิว",
          navigate: "?page=deleteQueue",
        },
        { title: "วิธีรับคิว", navigate: "?page=receiveQueue" },
        { title: "ดูสถานะคิว", navigate: "?page=statusQueue" },
        { title: "วิธีการ Reset คิว", navigate: "?page=resetQueue" },
      ],
    },
    {
      title: "ระบบจัดการครัว",
      state: "Manage Kitchen",
      setState: () => setState("Manage Kitchen"),
      list: [
        {
          title: "การยืนยันออเดอร์เมื่อทำเสร็จ",
          navigate: "?page=confirmOrderKitchen",
        },
      ],
    },
    {
      title: "ระบบจัดการออเดอร์",
      state: "Manage Order",
      setState: () => setState("Manage Order"),
      list: [
        { title: "ออเดอร์จากออนไลน์", navigate: "?page=orderOnline" },
        {
          title: "ออเดอร์ทานที่ร้าน",
          navigate: "?page=orderSit-in",
        },
        {
          title: "ออเดอร์สั่งกลับบ้าน",
          navigate: "?page=orderTakehome",
        },
      ],
    },
    {
      title: "ระบบการเงิน",
      state: "Payment System",
      setState: () => setState("Payment System"),
      list: [
        { title: "การตั้งค่าระบบชำระเงิน", navigate: "?page=settingPayment" },
        {
          title: "ระบบชำระด้วยเงินสด",
          navigate: "?page=paymentByCash",
        },
        {
          title: "ระบบชำระด้วย PromptPay",
          navigate: "?page=paymentByPromptPay",
        },
        {
          title: "ระบบชำระด้วย Thai QR",
          navigate: "?page=paymentByThaiQR",
        },
        {
          title: "ระบบชำระด้วย WeChat Pay",
          navigate: "?page=paymentByWeChatPay",
        },
        {
          title: "ระบบชำระด้วย Alipay",
          navigate: "?page=paymentByAlipay",
        },
        {
          title: "ระบบชำระด้วย ShopeePay",
          navigate: "?page=paymentByShopeePay",
        },
      ],
    },
    {
      title: "ระบบเครื่องพิมพ์",
      state: "Print System",
      setState: () => setState("Print System"),
      list: [
        { title: "การตั้งค่าเครื่องพิมพ์", navigate: "?page=settingPrint" },
      ],
    },
    {
      title: "ระบบจัดการแพ็กเกจ",
      state: "Package System",
      setState: () => setState("Package System"),
      list: [{ title: "วิธีซื้อและเปลี่ยนแพ็กเกจ", navigate: "?page=package" }],
    },
    {
      title: "ระบบเปลี่ยนภาษา",
      state: "Language System",
      setState: () => setState("Language System"),
      list: [{ title: "วิธีเปลี่ยนภาษา", navigate: "?page=language" }],
    },
    {
      title: "ระบบตั้งค่าบัญชี",
      state: "Account System",
      setState: () => setState("Account System"),
      list: [
        { title: "วิธีเปลี่ยนรหัส PIN", navigate: "?page=changePIN" },
        {
          title: "วิธีลบบัญชี",
          navigate: "?page=deleteAccount",
        },
        {
          title: "วิธีออกจากระบบ",
          navigate: "?page=logout",
        },
      ],
    },
    // {
    //   title: "ระบบรีวิวออเดอร์",
    //   state: "Review System",
    //   setState: () => setState("Review System"),
    //   list: [{ title: "การดูและตอบกลับรีวิว", navigate: "?page=review" }],
    // },
  ];

  const renderSubList = (header, list, setState) => {
    return (
      <>
        <SubListComponent header={header} list={list} setState={setState} />
      </>
    );
  };

  const renderMainList = () => {
    return (
      <>
        {howToUseData.map((item, index) => (
          <Box key={index} sx={{ mb: 2 }}>
            <ListComponent title={item.title} onClick={item.setState} />
          </Box>
        ))}
      </>
    );
  };

  const renderList = () => {
    if (state !== "") {
      const data = howToUseData.find((item) => item.state === state);
      return renderSubList(data.title, data.list, setState);
    } else {
      return renderMainList();
    }
  };

  if (page) {
    return renderPage();
  }
  return (
    <Box sx={{ bgcolor: "white", p: 4 }}>
      <Box sx={{ mt: 2, width: "100%", height: "100vh" }}>{renderList()}</Box>
    </Box>
  );
}

export default HowToUsePage;
