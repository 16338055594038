import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
  Chip,
  Tooltip,
  CircularProgress,
  Rating,
} from "@mui/material";
import {
  Close,
  Favorite,
  FavoriteBorder,
  LocationOn,
  PedalBike,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthStore } from "../stores/useAuthStore";
import useSnackBar from "../stores/useSnackBar";
import { useTranslation } from "react-i18next";
import { putFavorite } from "../libs/customerAPI/favoriteAPI";
import DefaultProfile from "../utils/defaultImage";
import StarIcon from "@mui/icons-material/Star";

function FoodTiles({ item, sx, onClose }) {
  const location = useLocation();
  const { isAuthenticated, updateUser, user, setLocationFrom } = useAuthStore();
  const [isFavorite, setIsFavorite] = useState(false);
  const [loading, setLoading] = useState(false);
  const { openSnack } = useSnackBar();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated && user.favorite) {
      setIsFavorite(user.favorite.includes(item.bmerchantId));
    }
  }, [isAuthenticated, item.bmerchantId, user]);

  const handleFavClick = async (e) => {
    e.stopPropagation();
    if (isAuthenticated) {
      setLoading(true);
      const updateFavorite = isFavorite
        ? user.favorite.filter((favId) => favId !== item.bmerchantId)
        : [...user.favorite, item.bmerchantId];

      await updateUser({ ...user, favorite: updateFavorite });
      await putFavorite(updateFavorite, user.uuid);
      setIsFavorite(!isFavorite);
      openSnack(t(isFavorite ? "snack.unFav" : "snack.fav"));
      setLoading(false);
    } else {
      setLocationFrom(location.pathname);
      navigate("/login/new");
    }
  };

  function extractLocation(location) {
    // Regular expressions to match the sub-district (แขวง, ตำบล) and district (เขต, อำเภอ)
    const regex1 = /แขวง\s*([\u0E00-\u0E7F]+)\s*เขต\s*([\u0E00-\u0E7F]+)/;
    const regex2 = /ตำบล\s*([\u0E00-\u0E7F]+)\s*อำเภอ\s*([\u0E00-\u0E7F]+)/;

    let match = location.match(regex1);
    if (match) {
      // Return the sub-district and district for แขวง, เขต
      return `${match[1]}, ${match[2]}`;
    }

    match = location.match(regex2);
    if (match) {
      // Return the sub-district and district for ตำบล, อำเภอ
      return `${match[1]}, ${match[2]}`;
    }

    // Return the original location if no match is found
    return location;
  }

  return (
    <Card
      elevation={3}
      onClick={() =>
        navigate(`/merchant/${item.id}/${item.bmerchantId}/${item.title}`)
      }
      sx={{
        minWidth: "150px",
        minHeight: "250px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "16px",
        overflow: "hidden",
        transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
        "&:hover": {
          transform: "translateY(-5px)",
          boxShadow: 6,
        },
        cursor: "pointer",
        ...sx,
      }}
    >
      <Box sx={{ position: "relative", width: "100%", height: "100px" }}>
        <CardMedia
          component="img"
          src={item.img ?? DefaultProfile}
          alt={item.title}
          sx={{
            width: "100%",
            height: "100%",
            objectFit: item.img ? "cover" : "contain",
            zIndex: 1000,
          }}
        />
        {/* Favorite Icon */}
        <Tooltip
          title={isFavorite ? "Remove from favorites" : "Add to favorites"}
        >
          <IconButton
            onClick={handleFavClick}
            size="small"
            sx={{
              position: "absolute",
              top: 8,
              right: 48,
              zIndex: 1,
              backgroundColor: "#FFFFFF",
              opacity: 0.8,
              transition:
                "transform 0.3s ease-in-out, background-color 0.3s ease-in-out",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 1)",
                transform: "scale(1.1)",
              },
            }}
            aria-label={
              isFavorite ? "Remove from favorites" : "Add to favorites"
            }
          >
            {loading ? (
              <CircularProgress size={20} />
            ) : isFavorite ? (
              // sx={{ color: "#FF4081" }}
              <Favorite fontSize="small" sx={{ color: "#4A4A4A" }} />
            ) : (
              <FavoriteBorder fontSize="small" sx={{ color: "#4A4A4A" }} />
            )}
          </IconButton>
        </Tooltip>
        <Tooltip title="Close">
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
            size="small"
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              zIndex: 1,
              backgroundColor: "#FFFFFF",
              opacity: 0.9,
              transition:
                "transform 0.3s ease-in-out, background-color 0.3s ease-in-out",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 1)",
                transform: "scale(1.1)",
              },
            }}
            aria-label="Close"
          >
            <Close fontSize="small" sx={{ color: "#4A4A4A" }} />
          </IconButton>
        </Tooltip>
      </Box>

      <CardContent
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          p: 2,
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 2,
              mb: 1,
            }}
          >
            <Typography variant="h6" fontWeight="bold" minWidth={"150px"}>
              {item.title}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              <StarIcon
                sx={{
                  fontSize: "12px",
                  mb: 0.5,
                }}
              />
              <Typography variant="body2" color="text.secondary">
                {item.rating}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ mb: 1 }}>
          <Typography
            variant="body2"
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 0.5,
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: 1,
              WebkitBoxOrient: "vertical",
            }}
          >
            <LocationOn
              sx={{ mr: 0.5, color: "text.secondary", fontSize: "1rem" }}
            />
            {extractLocation(item.location)}
          </Typography>
          <Typography
            variant="body2"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <PedalBike
              sx={{ mr: 0.5, color: "text.secondary", fontSize: "1rem" }}
            />
            {item.distance}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
          <Chip
            label={item.shopType}
            size="small"
            sx={{ height: 24, fontSize: "0.75rem" }}
          />
          {item.cuisineType && (
            <Chip
              label={item.cuisineType}
              size="small"
              sx={{ height: 24, fontSize: "0.75rem" }}
            />
          )}
        </Box>
      </CardContent>
    </Card>
  );
}

export default FoodTiles;
