import React from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ListComponent = ({ title, onClick }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        p: 1,
        backgroundColor: "#EFEFEF",
        border: "1.5px solid #D9D9D9",
        borderRadius: "14px",
        cursor: "pointer",
        ":hover": {
          color: "#f9931f",
          transition: " color 0.2s",
        },
      }}
    >
      <Typography fontSize={"16px"}>{title}</Typography>
      <Typography fontSize={"16px"}></Typography>
      {">"}
    </Box>
  );
};
function SubListComponent({ list, setState, header }) {
  const navigator = useNavigate();
  return (
    <>
      <Box
        onClick={() => setState("")}
        sx={{
          display: "flex",
          p: 2,
          gap: 2,
          cursor: "pointer",
          ":hover": {
            color: "#f9931f",
            transition: " color 0.2s",
          },
        }}
      >
        <Typography fontSize={"18px"} fontWeight={600}>
          {"<"}
        </Typography>
        <Typography fontSize={"18px"} fontWeight={600}>
          กลับ
        </Typography>
      </Box>

      <Typography
        sx={{
          textAlign: "center",
          fontSize: "22px",
          fontWeight: 600,
          mb: 2,
          color: "#f9931f",
        }}
      >
        {header}
      </Typography>
      {list.map((item, index) => (
        <Box key={index} sx={{ mb: 2 }}>
          <ListComponent
            title={item.title}
            onClick={() => navigator(item.navigate)}
          />
        </Box>
      ))}
    </>
  );
}

export default SubListComponent;
