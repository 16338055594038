import { create } from "zustand";

export const useAuthStore = create((set) => ({
  user: {
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    uuid: "",
    favorite: [],
  },
  isAuthenticated: false,
  isLoading: true,
  login: (user) => {
    set({ isAuthenticated: true, user: user, isLoading: false });
  },
  logout: () => {
    const deleteCookie = (name) => {
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    };
    deleteCookie("token");
    set({
      isAuthenticated: false,
      user: {
        email: "",
        first_name: "",
        last_name: "",
        phone: "",
        uuid: "",
        favorite: [],
      },
      isLoading: false,
    });
  },
  updateUser: (newData) => {
    set((state) => ({
      user: {
        ...state.user,
        ...newData,
      },
    }));
  },
  locationFrom: null,
  setLocationFrom: (from) => {
    set({ locationFrom: from });
  },
}));
