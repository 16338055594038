import { Avatar, Box, Button, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import facebookLogo from "../../assets/icon/facebook.png";
import googleLogo from "../../assets/image/google_logo.png";
import lineLogo from "../../assets/image/line_logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import { signInWithCredential, GoogleAuthProvider } from "firebase/auth";
import { ssoThirdParty } from "../../libs/authAPI/loginAPI";
import useModalLoading from "../../stores/useModalLoading";
import { useAuthStore } from "../../stores/useAuthStore";
import { getCustomerByUUID } from "../../libs/customerAPI/getCustomer";
import { auth } from "../../utils/firebaseAuth";
import liff from "@line/liff";
import axios from "axios";
import { callBackLine } from "../../libs/lineAPI";
import { generateRandomString } from "../../utils/randomString";
import { checkDeviceOs } from "../../utils/checkOS";

function LoginPage() {
  const { isIOS, isAndroid, isWindow, isMac } = checkDeviceOs();
  const { t } = useTranslation();
  const navigator = useNavigate();
  const { login: loginStore, locationFrom } = useAuthStore();
  const { openModal, closeModal } = useModalLoading();
  const location = useLocation();

  useEffect(() => {
    closeModal();
  }, []);

  useEffect(() => {
    const fetchAccessToken = async () => {
      const params = new URLSearchParams(location.search);
      const code = params.get("code");
      if (code) {
        openModal();
        try {
          const response = await callBackLine({ code });
          const accessToken = response.data.access_token;
          const profileResponse = await axios.get(
            "https://api.line.me/v2/profile",
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          const body = {
            email: profileResponse.data.email,
            firstName: profileResponse.data.displayName,
            lastName: "",
            uuid: profileResponse.data.userId,
            idToken: accessToken,
            loginType: "line",
          };
          ssoThirdParty(body).then((res) => {
            const token = res.data.token;
            document.cookie = `token=${token}; path=/`;
            localStorage.setItem("accessToken", accessToken);
            getCustomerByUUID(res.data.customersUUID)
              .then((customerRes) => {
                const user = {
                  email: customerRes.data.email,
                  first_name: customerRes.data.first_name
                    ? customerRes.data.first_name
                    : res.data.firstName,
                  last_name: customerRes.data.last_name,
                  phone: customerRes.data.phone ?? "",
                  uuid: customerRes.data.uuid
                    ? customerRes.data.uuid
                    : res.data.customersUUID,
                  favorite: customerRes.data.favorite_bmerchants ?? [],
                };
                loginStore(user);
                closeModal();
                // navigator(locationFrom);
                navigator(locationFrom ? locationFrom : "/");
              })
              .catch((error) => {
                console.error("Error fetching customer:", error);
                const data = res.data;
                const user = {
                  email: data.email,
                  first_name: data.firstName,
                  last_name: data.lastName,
                  phone: data.phone ?? "",
                  uuid: data.customersUUID,
                  favorite: [],
                };
                loginStore(user);
                closeModal();
                navigator(locationFrom ? locationFrom : "/");
              });
          });
        } catch (error) {
          console.error("Error fetching access token:", error);
          closeModal();
        } finally {
          closeModal();
          navigator(locationFrom ? locationFrom : "/");
        }
      } else {
        closeModal();
      }
    };
    fetchAccessToken();
  }, [
    closeModal,
    location.search,
    locationFrom,
    loginStore,
    navigator,
    openModal,
  ]);

  const handleLineLogin = () => {
    const clientId = process.env.REACT_APP_CHANNEL_ID;
    const redirectUri = encodeURIComponent(
      process.env.REACT_APP_CALLBACK_URL_LOGIN_LINE
    );
    const state = generateRandomString(16);
    const scope = "profile openid email";
    const botPrompt = "normal";
    const prompt = "consent";
    const lineAuthUrl = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}&scope=${scope}&bot_prompt=${botPrompt}&prompt=${prompt}`;
    window.open(lineAuthUrl, "_self");
  };

  const googleLogin = useGoogleLogin({
    ux_mode: "popup",
    onSuccess: async (codeResponse) => {
      console.log("Code Response:", codeResponse);
      const credential = GoogleAuthProvider.credential(
        null,
        codeResponse.access_token
      );
      signInWithCredential(auth, credential).then(async (response) => {
        const tokenResponse = response._tokenResponse;
        const body = {
          email: tokenResponse.email,
          firstName: tokenResponse.firstName,
          lastName: tokenResponse.lastName,
          uuid: tokenResponse.localId,
          idToken: tokenResponse.idToken,
          loginType: tokenResponse.providerId,
        };
        ssoThirdParty(body)
          .then((res) => {
            const token = res.data.token;
            document.cookie = `token=${token}; path=/`;
            getCustomerByUUID(res.data.customersUUID)
              .then((customerRes) => {
                const user = {
                  email: customerRes.data.email,
                  first_name: customerRes.data.first_name,
                  last_name: customerRes.data.last_name,
                  phone: customerRes.data.phone ?? "",
                  uuid: customerRes.data.uuid,
                  favorite: customerRes.data.favorite_bmerchants ?? [],
                };
                loginStore(user);
                closeModal();
                navigator(locationFrom ? `${locationFrom}` : "/");
                // const custom_token = res.data.token;
                // document.cookie = `token=${custom_token}; path=/`;
              })
              .catch(() => {
                const data = res.data;
                const user = {
                  email: data.email,
                  first_name: data.firstName,
                  last_name: data.lastName,
                  phone: data.phone ?? "",
                  uuid: data.customersUUID,
                  favorite: [],
                };
                loginStore(user);
                closeModal();
                navigator(locationFrom ? `${locationFrom}` : "/");
              });
          })
          .catch((err) => {
            closeModal();
          });
      });
    },
    onError: (errorResponse) => {
      closeModal();
    },
    error_callback: (error) => {
      if (error.message === "Popup window closed") {
        closeModal();
      }
      closeModal();
    },
  });

  const logOut = () => {
    googleLogout();
    // setProfile(null);
  };

  return (
    <Box
      component="form"
      sx={{
        backgroundColor: "white",
        mt: 10,
        py: 6,
        px: [2, 4, 6],
        borderRadius: 3,
        boxShadow: "-1px -1px 10px 1px rgba(0, 0, 0, 0.2)",
        display: "flex",
        width: ["90%", "70%", "400px"],
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Typography variant="h5" className="mt-3 font-bold">
        {t("login.welcome")}
      </Typography>
      <Typography className="text-[#a8a8a8] mb-3">
        {t("login.instruction")}
      </Typography>
      {/* <Button
        startIcon={
          <Avatar
            src={facebookLogo}
            sx={{ width: "20px", height: "20px" }}
            className="bg-white"
          />
        }
        sx={{
          color: "white",
          backgroundColor: "#1877F2",
          "& .MuiButton-startIcon": {
            position: "absolute",
            left: 20,
          },
          ":hover": {
            backgroundColor: "#1877f2b3",
            color: "white",
          },
        }}
      >
        {t("btn.facebook")}{" "}
      </Button> */}

      <Button
        variant="outlined"
        onClick={() => {
          handleLineLogin();
          openModal();
        }}
        startIcon={
          <Avatar
            src={lineLogo}
            sx={{ width: "20px", height: "20px", backgroundColor: "white" }}
            className="bg-white"
          />
        }
        sx={{
          borderColor: "#DADCE0",
          color: "black",
          "& .MuiButton-startIcon": {
            position: "absolute",
            left: 20,
          },
          ":hover": {
            borderColor: "#DCE7F7",
            backgroundColor: "#F9FCFF",
            color: "black",
          },
        }}
      >
        {t("btn.line")}{" "}
      </Button>
      <Button
        variant="outlined"
        onClick={() => {
          googleLogin();
          openModal();
        }}
        startIcon={
          <Avatar
            src={googleLogo}
            sx={{ width: "20px", height: "20px", backgroundColor: "white" }}
            className="bg-white"
          />
        }
        sx={{
          borderColor: "#DADCE0",
          color: "black",
          "& .MuiButton-startIcon": {
            position: "absolute",
            left: 20,
          },
          ":hover": {
            borderColor: "#DCE7F7",
            backgroundColor: "#F9FCFF",
            color: "black",
          },
        }}
      >
        {t("btn.google")}{" "}
      </Button>
      <Typography className="text-[#a8a8a8] my-1 flex justify-center">
        or
      </Typography>

      <Link to="/login/new?step=email">
        <Button
          variant="outlined"
          className=" text-primary border-primary"
          sx={{
            width: "100%",
          }}
        >
          {t("btn.login")}
        </Button>
      </Link>
      <Link to="/login/new?step=email">
        <Button
          variant="contained"
          className=" text-white bg-primary"
          sx={{ width: "100%" }}
        >
          {t("btn.register")}
        </Button>
      </Link>
    </Box>
  );
}

export default LoginPage;
