import React from "react";
import { Helmet } from "react-helmet-async";
import DefaultProfile from "../../assets/images/pompkins_food_logo.png";

const MerchantMetaTags = ({ merchant_name, merchantData, version }) => (
  <Helmet onChangeClientState={(newState) => {}}>
    <title>{merchant_name} | POMPKINS Food</title>
    <meta
      name="description"
      content="สั่งเดลิเวอรีผ่าน POMPKINS Food  x Order with Google ได้แล้ววันนี้"
    />
    <meta
      name="keywords"
      content={`POMPKINS Food, Order with Google, ${merchant_name}, online food delivery, ${merchant_name} menu, ${merchant_name} reviews, best food in ${
        merchantData?.company_location ?? merchant_name
      }, ${merchant_name} delivery, พ้อมป์กินส์ ฟู้ด, สั่งอาหารออนไลน์, เมนู ${merchant_name}, รีวิว ${merchant_name}, อาหารที่ดีที่สุดใน ${
        merchantData?.company_location ?? merchant_name
      }, จัดส่ง ${merchant_name}`}
    />
    <link rel="canonical" href={`${window.location.href}`} />
    <meta property="og:title" content={`${merchant_name} | POMPKINS Food`} />
    <meta
      property="og:description"
      content="สั่งเดลิเวอรีผ่าน POMPKINS Food  x Order with Google ได้แล้ววันนี้"
    />
    <meta
      property="og:image"
      content={`${merchantData?.logo}?v=${version}` ?? DefaultProfile}
    />
    <meta property="og:url" content={`${window.location.href}`} />
    <meta property="og:type" content="website" />
    <meta name="twitter:title" content={`${merchant_name} | POMPKINS Food`} />
    <meta
      name="twitter:description"
      content="สั่งเดลิเวอรีผ่าน POMPKINS Food  x Order with Google ได้แล้ววันนี้"
    />
    <meta
      name="twitter:image"
      content={`${merchantData?.logo}?v=${version}` ?? DefaultProfile}
    />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:url" content={`${window.location.href}`} />
  </Helmet>
);

export default MerchantMetaTags;
