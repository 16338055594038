import { Button } from "@mui/material";
import { LocationOn } from "@mui/icons-material";

const LocationNav = ({ currentAddress, openModal, t }) => {
  const isValidLocation = (currentAddress) => {
    if (
      currentAddress === null ||
      currentAddress === "" ||
      currentAddress === undefined ||
      currentAddress === "null"
    ) {
      return false;
    }
    return true;
  };

  return isValidLocation(currentAddress) ? (
    <Button
      sx={{
        color: "black",
        ":hover": {
          backgroundColor: "#fff8f0",
        },
        width: "100%",
      }}
      onClick={() => openModal()}
      startIcon={<LocationOn className="text-primary" />}
    >
      {/* {t("btn.location")} */}
      <span variant="" className="text-primary line-clamp-1 ">
        {isValidLocation(currentAddress)
          ? currentAddress
          : t("NoLocationFound")}
      </span>
    </Button>
  ) : (
    <Button
      sx={{
        color: "black",
        ":hover": {
          backgroundColor: "#fff8f0",
        },
        width: "70%",
      }}
      onClick={() => openModal()}
      startIcon={<LocationOn className="text-primary" />}
    >
      <span variant="" className="text-primary line-clamp-1 ">
        {t("NoLocationFound")}
      </span>
    </Button>
  );
};

export default LocationNav;
