import React, { useCallback, useEffect } from "react";
import ReactGA from "react-ga4";
import { checkDeviceOs } from "../../utils/checkOS";
import { Box, Button, Typography } from "@mui/material";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import QRCodeGenerator from "../../components/QrGen";
import AdsSection from "../Welcome/AdsSection";
import { Helmet } from "react-helmet-async";
import downloadImg from "../../assets/image/download-app.png";
function DonwloadRedirectPage() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/download",
      title: "DonwloadRedirectPage",
    });
  }, []);
  const currentURL = window.location.href;
  const baseURL = currentURL.split("/").slice(0, 3).join("/");
  const [url, setUrl] = React.useState(baseURL + "/download");
  const redirectDownload = useCallback(() => {
    const { isIOS, isAndroid, isWindow, isMac } = checkDeviceOs();
    if (isIOS || isMac) {
      window.open(
        "https://apps.apple.com/th/app/pompkins-%E0%B8%9E-%E0%B8%AD%E0%B8%A1%E0%B8%9B-%E0%B8%81-%E0%B8%99%E0%B8%AA/id6479531244?l=th",
        "_blank"
      );
    } else if (isAndroid || isWindow) {
      window.open(
        "https://play.google.com/store/apps/details?id=com.prompkinsapp&hl=th&pli=1",
        "_blank"
      );
    }
  });
  useEffect(() => {
    redirectDownload();
  }, []);
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="แอปเดียว ครบ ง่าย คุ้ม เปลี่ยนทุกความไม่ง่ายในชีวิต ให้ง่ายขึ้นด้วยแอปเดียว POS สั่งอาหาร ชำระเงิน จัดการพนักงาน ดูยอดขาย ดาวน์โหลด ฟรี ได้วันนี้"
        />
        <link rel="canonical" href={`${window.location.href}`} />
        <meta property="og:image" content={`${downloadImg}`} />
        <meta property="og:url" content="https://pompkins.com/download" />
        <meta name="twitter:creator" content="POMPKINS" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content={`${downloadImg}`} />
        <meta name="twitter:url" content="https://pompkins.com/download" />
      </Helmet>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
          backgroundColor: "#ffffff",
        }}
      >
        <AdsSection />
        {/* <Box sx={{ my: 2, textAlign: "center" }}>
        <Typography sx={{ fontSize: "30px" }}>
          กดที่ปุ่มด้านล่างเพื่อดาวน์โหลด
        </Typography>
      </Box>
      <Button
        variant="contained"
        onClick={redirectDownload}
        sx={{ fontSize: "36px", borderRadius: "36px" }}
      >
        <DownloadForOfflineOutlinedIcon
          sx={{ mr: 1, color: "#ffffff", fontSize: "50px" }}
        />
        ดาวน์โหลดเลย
      </Button>
      <Box sx={{ my: 2, textAlign: "center" }}>
        <Typography sx={{ fontSize: "30px" }}>
          หรือสแกน QR Code ด้านล่างเพื่อดาวน์โหลด
        </Typography>
      </Box>
      <QRCodeGenerator qrString={url} /> */}
      </Box>
    </>
  );
}

export default DonwloadRedirectPage;
