// CartPage.js
import React, { useState, useEffect } from "react";
import CartItem from "../../components/cart/CartItem"; // Make sure this path is correct
import { Card, CardContent, Typography, Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  getBMerchantById,
  getMerchantById,
  getServiceBMerchant,
} from "../../libs/merchantAPI/merchantAPI";
import { createOrder } from "../../libs/orderAPI/orderAPI";
import { updateOrdersStatus } from "../../libs/customerAPI/getOrders";
import DefaultProfile from "../../assets/images/pompkins_food_logo.png";
import { checkToken } from "../../libs/tableAPI/checkExpire";
import useModalLoading from "../../stores/useModalLoading";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";

const AddMenuPage = () => {
  const version = new Date().toISOString().split("T")[0];
  const { t } = useTranslation();
  const [orders, setOrders] = useState([]);
  const [orderdataResponse, setOrderdataResponse] = useState([]);
  const navigator = useNavigate();
  const { merchant_id, bmerchant_id, tableNumber, token, transaction_uuid } =
    useParams();
  const [logo, setLogo] = useState("");
  const [bmerchantService, setBMerchantService] = useState({});
  const { openModal, closeModal } = useModalLoading();
  const [isSubmit, setIsSubmit] = useState(false);
  getMerchantById(merchant_id).then((res) => {
    setLogo(res.data.logo);
  });

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/table-merchant/${token}/${merchant_id}/${bmerchant_id}/${tableNumber}/${transaction_uuid}/cartorder`,
      title: "AddMenuPage",
    });
  }, []);
  useEffect(() => {
    getBMerchantById(bmerchant_id).then((res) => {
      getServiceBMerchant(res.data.uuid).then(({ data }) => {
        setBMerchantService(data);
      });
    });
  }, []);

  useEffect(() => {
    const orderDataString = localStorage.getItem(`qr_merchant_${bmerchant_id}`);
    const orderData = JSON.parse(orderDataString);
    if (!orderData) {
      return navigator(
        `/table-merchant/${token}/${merchant_id}/${bmerchant_id}/${tableNumber}/${transaction_uuid}/menu`
      );
    }
    setOrderdataResponse(orderData);
    setOrders(orderData.cartList);
  }, [
    bmerchant_id,
    merchant_id,
    navigator,
    tableNumber,
    transaction_uuid,
    token,
  ]);

  const handleOrder = async () => {
    // Format orders for the POST request
    openModal();
    setIsSubmit(true);
    const customer_name = localStorage.getItem("name");
    const formattedOrders = orders.map((order) => ({
      menu_id: parseInt(order.menu.id, 10),
      menu_name: order.menu.name,
      quantity: parseInt(order.quantity, 10),
      note: order.note || "-",
      price: order.price,
      option: order.option,
      image: order.menu.image,
    }));
    const total_order = formattedOrders.reduce(
      (total, order) => total + parseInt(order.quantity, 10),
      0
    );

    // Construct the order data
    const orderData = {
      customer_uuid: "", // Replace with actual customer UUID
      total_order: total_order,
      bmerchant_uuid: orderdataResponse.merchant_uuid, // Replace with actual merchant UUID
      table_number: `${tableNumber}`, // Use the selected tableNumber, // This can be dynamic based on your application logic
      transaction_uuid: transaction_uuid,
      order_type: "SIT-IN", // Order type in uppercase
      order_detail: formattedOrders, // The formatted order details
      total_amount: total,
      order_distance: 0,
      customer_name: customer_name, // Total amount calculated from orders
      order_from: "POMPKINS",
    };
    checkToken(token)
      .then(() => {
        createOrder(orderData)
          .then((res) => {
            const merchant_uuid = res.data.bmerchant_uuid;
            const order_uuid = res.data.order_uuid;
            const status = {
              status: "PURCHASED",
              transaction_uuid: transaction_uuid,
            };
            updateOrdersStatus(order_uuid, status);
            navigator(
              `/table-merchant/${token}/${merchant_id}/${bmerchant_id}/${tableNumber}/${transaction_uuid}/checkbill/${merchant_uuid}`
            );

            localStorage.removeItem(`qr_merchant_${bmerchant_id}`);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            closeModal();
            setIsSubmit(false);
          });
      })
      .catch(() => {
        closeModal();
        setIsSubmit(false);
        navigator(`/table-merchant/expired`);
      });
  };

  const total = orders.reduce(
    (acc, item) => acc + item.quantity * item.price,
    0
  );

  const subtotal = bmerchantService.vat ? total / 1.07 : total;
  const vatValues = bmerchantService.vat ? total - subtotal : 0;

  const handleQuantityChange = (index, delta) => {
    setOrders((orders) => {
      const newOrders = [...orders];
      const currentQuantity = parseInt(newOrders[index].quantity, 10);
      const newItemQuantity = currentQuantity + delta;

      if (newItemQuantity > 0) {
        newOrders[index].quantity = newItemQuantity;
      } else {
        // Remove the item if the new quantity is 0 or less
        newOrders.splice(index, 1);
      }
      return newOrders;
    });
  };

  useEffect(() => {
    if (orders.length === 0) {
      localStorage.removeItem(`qr_merchant_${bmerchant_id}`);
    }
    localStorage.setItem(
      `qr_merchant_${bmerchant_id}`,
      JSON.stringify({
        ...orderdataResponse,
        order_detail: orders,
        cartList: orders,
      })
    );
  }, [orders]); // Only re-run the effect if orders changes

  if (orders.length === 0) {
    navigator(
      `/table-merchant/${token}/${merchant_id}/${bmerchant_id}/${tableNumber}/${transaction_uuid}/menu`
    );
  }

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="สั่งเดลิเวอรีผ่าน POMPKINS Food  x Order with Google ได้แล้ววันนี้"
        />
        <link rel="canonical" href={`${window.location.href}`} />
        <meta property="og:url" content={`${window.location.href}`} />
        <meta
          property="og:title"
          content={`${orderdataResponse.merchant_name} | POMPKINS Food`}
        />
        <meta
          property="og:image"
          content={`${logo}?v=${version}` ?? DefaultProfile}
        />
        <meta
          name="twitter:image"
          content={`${logo}?v=${version}` ?? DefaultProfile}
        />
      </Helmet>
      <Box
        sx={{
          margin: "auto",
          padding: "2% 5%",
          height: orders.length > 2 ? "100%" : "100vh",
        }}
      >
        <Card sx={{ borderRadius: "15px" }}>
          <CardContent>
            <Box sx={{ textAlign: "center" }}>
              <Typography fontSize={"20px"} fontWeight={700}>
                {orderdataResponse.merchant_name}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
              <img
                src={logo ?? DefaultProfile}
                alt="logo merchant"
                loading="lazy"
                title="logo merchant"
                style={{
                  minWidth: "150px",
                  minHeight: "150px",
                  width: "150px",
                  height: "150px",
                  objectFit: logo ? "cover" : "contain",
                  borderRadius: "20px",
                }}
                width="150px"
                height="150px"
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography fontWeight={500}>
                {t("cartOrder.tableNumber")}
                <span> {tableNumber}</span>
              </Typography>
              <Button
                onClick={() =>
                  navigator(
                    `/table-merchant/${token}/${merchant_id}/${bmerchant_id}/${tableNumber}/${transaction_uuid}/menu`
                  )
                }
                sx={{
                  m: 0,
                  p: 0,
                  minWidth: 0,
                  color: "#FF961B",
                  ":hover": {
                    backgroundColor: "#FFF7EB",
                  },
                }}
              >
                {t("cartOrder.addMenu")}
              </Button>
            </Box>
            {/* การวนซ้ำผ่าน orders และการแสดงรายการในตะกร้า */}
            {orders.map((item, index) => (
              <CartItem
                key={index}
                item={item}
                index={index}
                handleQuantityChange={handleQuantityChange}
                cartList={orders}
                setCartList={setOrders}
              />
            ))}

            {/* ส่วนสรุป */}
            <Typography variant="h6" sx={{ mt: 2 }}>
              {t("qrtable.summary")}
            </Typography>
            {bmerchantService.vat && (
              <Box
                sx={{ display: "flex", justifyContent: "space-between", my: 1 }}
              >
                <Typography> {}ราคารวมก่อนภาษีมูลค่าเพิ่ม</Typography>
                <Typography>{`฿ ${subtotal.toFixed(2)}`}</Typography>
              </Box>
            )}

            {bmerchantService.vat && (
              <Box
                sx={{ display: "flex", justifyContent: "space-between", my: 1 }}
              >
                <Typography>VAT7%</Typography>
                <Typography>{`฿ ${vatValues.toFixed(2)}`}</Typography>
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                my: 1,
                fontWeight: "bold",
              }}
            >
              <Typography>{t("qrtable.total")}</Typography>
              <Typography>{`฿ ${total.toFixed(2)}`}</Typography>
            </Box>
            <Button
              variant="contained"
              color="primary"
              disabled={orders.length === 0 || isSubmit}
              fullWidth
              sx={{ mt: 2, borderRadius: "2rem" }}
              onClick={handleOrder}
            >
              {t("qrtable.orderNow")}
            </Button>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default AddMenuPage;
