import { Box, Skeleton } from "@mui/material";

function PastOrderTilesSkeleton() {
  return (
    <Box sx={{ width: "100%", height: "130px" }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          backgroundColor: "white",
          gap: "18px",
          mt: 1,
          p: 1,
          borderRadius: "15px",
          position: "relative",
          boxShadow: 1,
        }}
      >
        <Skeleton variant="rounded" width={100} height={80} />
        <Box width={"100%"}>
          <Skeleton variant="text" width="60%" />
          <Skeleton variant="text" width="40%" />
          <Skeleton variant="text" width="80%" />
          <Skeleton variant="text" width="50%" />
        </Box>
      </Box>
    </Box>
  );
}

export default PastOrderTilesSkeleton;
