import { Search } from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FaqData from "./FaqData";
import ListFaq from "./ListFaq";
import LoadingScreen from "../../components/LoadingScreen";
import SearchableFAQ from "./SearchFaq";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

function FaqSectionPage() {
  const { t } = useTranslation();
  const { sections, categories } = useParams();
  const navigator = useNavigate();
  const [data, setData] = useState([]);
  const [isFetch, setIsFetch] = useState(false);
  const [displayData, setDisplayData] = useState([]);
  const { faqData, applicationFaqData } = FaqData();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/pompkins/FAQ/categories/${categories}/sections/${sections}`,
      title: "FaqSectionPage",
    });
  }, []);

  useEffect(() => {
    if (categories === "Application") {
      setData(applicationFaqData);
    } else if (categories === "General") {
      setData(faqData);
    }
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      const filteredData = data.find((item) => item.type === sections);
      setDisplayData(filteredData);
      setIsFetch(true);
    }
  }, [data, sections]);

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      sx={{
        color: "#333333",
        cursor: "pointer",
      }}
      onClick={() => navigator(`/pompkins/FAQ`)}
    >
      Pompkins FAQ
    </Link>,
    <Link
      underline="hover"
      key="2"
      sx={{
        color: "#333333",
        cursor: "pointer",
      }}
      onClick={() => navigator(`/pompkins/FAQ/categories/${categories}`)}
    >
      {categories}
    </Link>,
    <Link
      underline="hover"
      key="3"
      sx={{
        color: "#666666",
        cursor: "pointer",
      }}
      onClick={() =>
        navigator(`/pompkins/FAQ/categories/${categories}/sections/${sections}`)
      }
    >
      {sections}
    </Link>,
  ];

  if (!isFetch) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Helmet>
        <title>{t("title.faqpage")}</title>
        <meta
          name="description"
          content="แอปเดียว ครบ ง่าย คุ้ม เปลี่ยนทุกความไม่ง่ายในชีวิต ให้ง่ายขึ้นด้วยแอปเดียว POS สั่งอาหาร ชำระเงิน จัดการพนักงาน ดูยอดขาย ดาวน์โหลด ฟรี ได้วันนี้"
        />
        <link rel="canonical" href={`${window.location.href}`} />
        <meta property="og:url" content="https://pompkins.com/faq" />
      </Helmet>
      <Box sx={{ backgroundColor: "white", minHeight: "100vh" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "10vh",
            backgroundColor: "#FAFAFA",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              maxWidth: "1160px",
              width: "100%",
              gap: 3,
              p: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                width: ["100%", "100%", "50%"],
              }}
            >
              <SearchableFAQ />
            </Box>
          </Box>
        </Box>
        <Box sx={{ p: 4, maxWidth: "1160px", mx: "auto" }}>
          <Breadcrumbs separator="›">{breadcrumbs}</Breadcrumbs>
          <Typography
            sx={{ fontWeight: 700, fontSize: ["32px", "36px", "40px"], mt: 1 }}
          >
            {displayData.type}
          </Typography>
          <Box sx={{ my: 4 }}>
            {displayData.data.map((list, index) => (
              <Box key={index} sx={{ mt: 1 }}>
                <ListFaq
                  title={list.question}
                  onClick={() =>
                    navigator(
                      `/pompkins/FAQ/categories/${categories}/sections/${sections}/${list.question}`
                    )
                  }
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default FaqSectionPage;
