import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  IconButton,
  Rating,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import OrderItem from "./OrderItem";
import { useAuthStore } from "../../stores/useAuthStore";
import {
  createReview,
  createReviewOrder,
} from "../../libs/merchantAPI/merchantAPI";
import DefaultProfile from "../../assets/images/pompkins_food_logo.png";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

function ReivewOrderModal({ open = false, data, onClose }) {
  const order = data.order;
  const review = order.review;
  const merchant = order.merchant;
  const bmerchant = merchant?.bmerchant;
  const [rating, setRating] = useState(review?.point || null);
  const [isRatingValid, setIsRatingValid] = useState(!!review?.point);
  const [comment, setComment] = useState(review?.customer_comment || "");
  const { user } = useAuthStore();
  const date = dayjs(data.order.date);
  const thaiDay = date.format("D MMM YYYY");
  const time = date.format("HH:mm:ss");

  const handleRatingChange = (event, newRating) => {
    setRating(newRating);
    setIsRatingValid(!!newRating);
  };
  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleConfirm = () => {
    if (!isRatingValid) {
      return;
    }
    const body = {
      transaction_uuid: order.transaction_uuid,
      customer_uuid: user?.uuid,
      bmerchant_uuid: bmerchant.uuid,
      point: rating,
      customer_comment: comment,
    };
    createReviewOrder(body)
      .then(() => {
        onClose();
      })
      .finally(() => {
        window.location.reload();
      });
  };

  const isReviewDisabled =
    review?.customer_comment !== null || review?.point !== null;

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "90%",
          minHeight: "70vh",
          backgroundColor: "white",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        },
      }}
    >
      <IconButton
        color="inherit"
        onClick={onClose}
        aria-label="close"
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <Close sx={{ color: "#FF961B" }} />
      </IconButton>
      <DialogTitle className="font-bold" sx={{ backgroundColor: "white" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Box sx={{ position: "relative", width: "40px" }}>
            <Box
              alt="food"
              component="img"
              src={merchant?.logo ? merchant.logo : DefaultProfile}
              sx={{
                width: "40px",
                height: "40px",
                borderRadius: "15px",
                cursor: "pointer",
                objectFit: merchant?.logo ? "contain" : "cover",
              }}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography>{merchant?.merchant_name}</Typography>
            <Typography fontSize={"12px"} mt={"-4px"} color={"GrayText"}>
              {thaiDay} {time}
            </Typography>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          width={"90%"}
          sx={{
            m: "0 auto",
            mt: 2,
          }}
        >
          {order.order_detail &&
            order.order_detail.map((menuItem, menuIndex) => (
              <OrderItem key={menuIndex} item={menuItem} />
            ))}
        </Box>
        <Box
          width={"90%"}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            p: 2,
            borderRadius: "15px",
            backgroundColor: "#f1f1f1",
            m: "0 auto",
          }}
        >
          <Typography variant="h5">ออเดอร์ของคุณเป็นอย่างไร</Typography>
          <Typography variant="subtitle1">ให้คะแนนออเดอร์ของคุณ</Typography>
          <Rating
            name="rating"
            value={rating}
            readOnly={isReviewDisabled}
            onChange={handleRatingChange}
            size="large"
            sx={{ color: "#FF961B" }}
          />
          <Typography variant="subtitle1">เพิ่มความคิดเห็นของคุณ</Typography>
          <TextField
            fullWidth
            id="outlined-multiline-static"
            placeholder="เขียนความคิดเห็นของคุณที่นี่..."
            multiline
            rows={2}
            sx={{ backgroundColor: "white" }}
            value={comment}
            disabled={isReviewDisabled}
            onChange={handleCommentChange}
            margin="normal"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isReviewDisabled || !isRatingValid}
          onClick={handleConfirm}
          variant="contained"
          sx={{ m: 1 }}
        >
          ยอมรับ
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ReivewOrderModal;
