import dayjs from "dayjs";
import React from "react";
import { useParams } from "react-router-dom";
import * as htmlToImage from "html-to-image";
import { Box, Button, Typography } from "@mui/material";
import DefaultProfile from "../../utils/defaultImage";
import FileSaver from "file-saver";
import useModalLoading from "../../stores/useModalLoading";

function BillEdcItem({ billData, serviceData, bmerchantData }) {
  const billRef = React.useRef(false);
  const { transaction_uuid } = useParams();
  //Header 24px base 16px order_detail 14px Total
  const date = dayjs(billData.date);
  const thaiDay = date.format("DD/MM/YY");
  const time = date.format("HH:mm");
  const orders = billData.orders;
  const note = orders.map((item) => item.order_detail[0].note.split(","));
  const { openModal, closeModal } = useModalLoading();
  const handleDownload = async () => {
    openModal();
    await new Promise((resolve) => {
      setTimeout(resolve, 500); // Wait for any existing animations/events to resolve
    });

    await htmlToImage.toBlob(billRef.current, { cacheBust: true });
    await htmlToImage.toBlob(billRef.current, { cacheBust: true });
    await new Promise((resolve) => {
      setTimeout(resolve, 500); // Wait for any new animations/events to resolve
    });
    const blob = await htmlToImage.toBlob(billRef.current, { cacheBust: true });
    FileSaver.saveAs(blob, `bill_${transaction_uuid}.png`);
    closeModal();
  };
  const subtotal = billData.total_price - billData.promotion;
  const vatValues = serviceData.vat ? subtotal - subtotal / 1.07 : 0;
  const total_amount = billData.total_price - billData.promotion;
  return (
    <>
      <Box
        ref={billRef}
        sx={{
          p: 2,
          backgroundColor: "white",
          boxShadow: "0 10px 8px rgb(0 0 0 / 0.1)",
          width: "366px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          className="text-textGray"
          sx={{
            textAlign: "center",
            fontSize: "24px",
            fontWeight: 700,
            mb: 2,
            overflowWrap: "break-word",
            wordWrap: "break-word",
            width: "90%",
          }}
        >
          {serviceData.billing_header}
        </Typography>
        <img
          src={serviceData.billing_logo ?? DefaultProfile}
          alt="img"
          title="img"
          loading="lazy"
          width="100px"
          height="100px"
          style={{
            width: "100px",
            height: "100px",
            objectFit: "contain",
            borderRadius: "20px",
          }}
        />
        <Typography
          variant="body2"
          className="text-textGray"
          fontSize={"16px"}
          sx={{
            mt: 1,
            fontWeight: 700,
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {billData.merchant_name}
        </Typography>
        {serviceData.business_type === "Company" && billData.tax_id && (
          <Typography
            variant="body2"
            className="text-textGray"
            fontSize={"14px"}
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            เลขประจำตัวผู้เสียภาษี:{billData.tax_id ?? "-"}
          </Typography>
        )}

        <Typography
          variant="body2"
          className="text-textGray"
          sx={{
            width: "100%",
            overflowWrap: "break-word",
            wordWrap: "break-word",
            textAlign: "center",
          }}
          fontSize={"16px"}
        >
          {serviceData.billing_detail}
        </Typography>
        {serviceData.business_type === "Company" && (
          <Typography
            variant="body2"
            className="text-textGray"
            sx={{
              width: "100%",
              overflowWrap: "break-word",
              wordWrap: "break-word",
              textAlign: "center",
            }}
            fontSize={"16px"}
          >
            {bmerchantData && bmerchantData.is_main_branch
              ? "สำนักงานใหญ่"
              : "สาขาย่อย"}
          </Typography>
        )}
        {serviceData.business_type === "Company" && serviceData.vat && (
          <Typography
            variant="body2"
            className="text-textGray"
            sx={{
              width: "100%",
              overflowWrap: "break-word",
              wordWrap: "break-word",
              textAlign: "center",
            }}
            fontSize={"16px"}
          >
            (VAT INCLUDED)
          </Typography>
        )}
        <Typography
          variant="body2"
          className="text-textGray"
          sx={{
            mt: 2,
            fontWeight: 700,
            width: "100%",
            overflowWrap: "break-word",
            wordWrap: "break-word",
            textAlign: "center",
          }}
          fontSize={"16px"}
        >
          {serviceData.business_type === "Company" && serviceData.vat
            ? "ใบเสร็จรับเงิน/ใบกํากับภาษีอย่างย่อ"
            : "ใบเสร็จรับเงิน"}
        </Typography>

        <Box sx={{ mt: 2, width: "100%" }}>
          {billData.staff_name && billData.staff_name !== "" && (
            <Typography
              variant="body2"
              className="text-textGray"
              sx={{ textAlign: "left" }}
            >
              ชื่อพนักงาน: {billData.staff_name}
            </Typography>
          )}
          <Typography
            variant="body2"
            className="text-textGray"
            sx={{ textAlign: "left" }}
          >
            เลขที่ใบเสร็จ: {transaction_uuid.slice(0, 15).toLocaleUpperCase()}
          </Typography>

          <Typography
            variant="body2"
            className="text-textGray"
            sx={{ textAlign: "left", mb: 2 }}
          >
            วันที่: {thaiDay} {time}
          </Typography>
          <div className="border-dashed  border-textGray border w-full" />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              py: "4px",
            }}
          >
            <Typography
              variant="body2"
              className="text-textGray"
              sx={{ textAlign: "center" }}
            >
              ประวัติการคำนวณ
            </Typography>
          </Box>
          <div className="border-dashed  border-textGray border w-full" />
          <Box sx={{ my: "4px", width: "100%", py: 2 }}>
            {note &&
              note[0].map((item, index) => (
                <Typography
                  variant="body2"
                  className="text-textGray"
                  sx={{ textAlign: "end" }}
                  key={index}
                >
                  {" "}
                  {item}
                </Typography>
              ))}
          </Box>
          <div className="border-dashed  border-textGray border w-full" />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="body2"
              fontSize={"14px"}
              sx={{ width: "70%" }}
              className="text-textGray"
            >
              ราคารวม
            </Typography>
            <Typography
              variant="body2"
              fontSize={"14px"}
              className="text-textGray"
            >
              {subtotal
                .toFixed(2)
                .toString()
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="body2"
              fontSize={"14px"}
              className="text-textGray"
            >
              ส่วนลด
            </Typography>
            <Typography
              variant="body2"
              fontSize={"14px"}
              className="text-textGray"
            >
              {billData.promotion
                ? billData.promotion
                    .toFixed(2)
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                : "0.00"}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              fontWeight={700}
              variant="body2"
              sx={{ width: "70%" }}
              fontSize={"14px"}
              className="text-textGray"
            >
              ราคารวมทั้งสิ้น
            </Typography>
            <Typography
              fontWeight={700}
              variant="body2"
              fontSize={"14px"}
              className="text-textGray"
            >
              {total_amount
                .toFixed(2)
                .toString()

                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
            </Typography>
          </Box>
        </Box>
        <div className="border-dashed  border-textGray border w-full" />
        {serviceData.vat && (
          <Box sx={{ width: "100%" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                sx={{ width: "70%" }}
                variant="body2"
                className="text-textGray"
              >
                รวมมูลค่าสินค้า
              </Typography>
              <Typography variant="body2" className="text-textGray">
                {(subtotal - vatValues)
                  .toFixed(2)
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                sx={{ width: "70%" }}
                variant="body2"
                className="text-textGray"
              >
                ภาษีมูลค่าเพิ่ม
              </Typography>
              <Typography variant="body2" className="text-textGray">
                {vatValues.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
              </Typography>
            </Box>
            <div className="border-dashed  border-textGray border w-full" />
          </Box>
        )}

        {billData.payment_method === "CASH" && billData.amount && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="body2" className="text-textGray">
              เงินสด{" "}
              {parseFloat(billData.amount)
                .toFixed(2)
                .toString()
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
            </Typography>
            <Typography variant="body2" className="text-textGray">
              เงินทอน{" "}
              {parseFloat(billData.amount - billData.total_price)
                .toFixed(2)
                .toString()
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
            </Typography>
          </Box>
        )}

        <Typography
          textAlign={"center"}
          mt={2}
          sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          variant="body2"
          className="text-textGray"
        >
          {billData.billing_footer}
        </Typography>
      </Box>

      <Button
        size="large"
        sx={{
          mt: 2,
          backgroundColor: "#2e7d32",
          color: "white",
          ":hover": {
            backgroundColor: "#2e5320",
            color: "white",
          },
        }}
        onClick={handleDownload}
      >
        {" "}
        ดาวน์โหลด
      </Button>
    </>
  );
}

export default BillEdcItem;
