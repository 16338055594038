import thaiQr from "../../assets/images/payment/thaiqrIcon.png";
import cash from "../../assets/images/payment/cashIcon.png";
// import creditCard from "../../assets/images/credit-card.png";
import weChatPay from "../../assets/images/payment/wechatIcon.png";
import aliPay from "../../assets/images/payment/alipayIcon.png";
import linePay from "../../assets/images/payment/linepay.png";
import shopeePay from "../../assets/images/payment/shopeepayIcon.png";
import visa from "../../assets/images/payment/visa.png";
import masterCard from "../../assets/images/payment/mastercard.png";

// NO prompt_pay and edc in paymentData Online

export const paymentData = [
  {
    paymentType: "THAI_QR",
    title: "ThaiQR",
    images: [thaiQr],
    channel: "KBNKQRPP",
  },
  {
    paymentType: "THAI_QR_INET",
    title: "ThaiQR",
    images: [thaiQr],
    channel: "INETQRPP",
  },
  {
    paymentType: "WECHAT_PAY",
    title: "WeChat Pay",
    images: [weChatPay],
    channel: "KSHRONID",
    channelName: "wechat",
  },
  {
    paymentType: "ALIPAY",
    title: "Alipay",
    images: [aliPay],
    channel: "KSHRONID",
    channelName: "alipay",
  },
  {
    paymentType: "CREDIT_QR",
    title: "QR Credit Card",
    images: [visa, masterCard],
    channel: "KBNKQRCC",
  },
  {
    paymentType: "RABBIT_LINE_PAY",
    title: "Line Pay",
    images: [linePay],
    channel: "KSHRQRLP",
  },
  {
    paymentType: "SHOPEE_PAY",
    title: "Shopee Pay",
    images: [shopeePay],
    channel: "KSHRONID",
    channelName: "shopeepay",
  },
  {
    paymentType: "CASH",
    title: "Cash",
    images: [cash],
    channel: "CASH",
  },
];
