const isVibrationSupported = () => {
  return "vibrate" in navigator;
};

export const triggerVibration = (duration = 300) => {
  if (isVibrationSupported()) {
    console.log("Vibration API is supported on this device.");
    navigator.vibrate(duration);
  } else {
    console.warn("Vibration API is not supported on this device.");
  }
};
