import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import {
  Collapse,
  DialogContent,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";
import Options from "../../utils/options";
import { useTranslation } from "react-i18next";
import {
  CheckCircleOutline,
  ExpandLess,
  ExpandMore,
  Language,
} from "@mui/icons-material";
import { useLanguage } from "../../stores/useLanguage";
import { useNavigate } from "react-router-dom";
import { useGuestLogin } from "../../stores/useGuestLogin";

GusetModalMenuMobile.defaultProps = {
  open: false,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

export default function GusetModalMenuMobile({ open, setOpen }) {
  const { t } = useTranslation();
  const { guestOptions, languageOptions } = Options();
  const [isLanguageOpen, setIsLanguageOpen] = useState(false);
  const { selectedLanguage, setSelectedLanguage } = useLanguage();
  const navigator = useNavigate();
  const { guestLogout } = useGuestLogin();

  const handleMenuClick = (path) => {
    handleClose();
    if (path === "logout") {
      guestLogout();
      setOpen(false);
      localStorage.removeItem("guest");
      localStorage.removeItem("guest_order");
    } else {
      navigator(path);
    }
  };

  const handleClick = () => {
    setIsLanguageOpen(!isLanguageOpen);
  };

  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language);
    setIsLanguageOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    setIsLanguageOpen(false);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <IconButton
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon sx={{ color: "#FF961B" }} />
        </IconButton>
        <DialogContent sx={{ mt: 3 }}>
          <List>
            {guestOptions.map((option) => (
              <div key={option.label}>
                {option.label === t("options.helpCenter") && (
                  <>
                    <ListItemButton onClick={handleClick}>
                      <ListItemIcon>
                        <Language />
                      </ListItemIcon>
                      <ListItemText primary={selectedLanguage} />
                      {isLanguageOpen ? (
                        <ExpandLess sx={{ color: "#FF961B" }} />
                      ) : (
                        <ExpandMore sx={{ color: "#FF961B" }} />
                      )}
                    </ListItemButton>
                    <Collapse in={isLanguageOpen} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {languageOptions.map((languageOption) => (
                          <ListItemButton
                            key={languageOption.label}
                            sx={{
                              pl: 4,
                              borderRadius: 3,
                              backgroundColor:
                                selectedLanguage === languageOption.code
                                  ? "#F8F7F7"
                                  : "transparent",
                              ":hover": {
                                backgroundColor: "#fff8f0",
                              },
                            }}
                            onClick={() =>
                              handleLanguageSelect(languageOption.code)
                            }
                          >
                            <ListItemText primary={languageOption.label} />
                            <ListItemIcon>
                              {selectedLanguage === languageOption.code && (
                                <CheckCircleOutline
                                  sx={{
                                    fontSize: 18,
                                    color: "#FF961B",
                                    ml: 2,
                                  }}
                                />
                              )}
                            </ListItemIcon>
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                  </>
                )}
                <ListItemButton onClick={() => handleMenuClick(option.path)}>
                  <ListItemIcon>{option.icon}</ListItemIcon>
                  <ListItemText primary={option.label} />
                </ListItemButton>
              </div>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </div>
  );
}
