import { Box, Button, Typography } from "@mui/material";
import React from "react";
import TextInput from "../../components/TextInput";
import PasswordInput from "../../components/PasswordInput";
import mobileIcon from "../../assets/icon/smartphone.png";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { registrationScheme } from "../../libs/authScheme";
import { useTranslation } from "react-i18next";

function Registration() {
  const location = useLocation();
  const email = location.state;
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm({
    mode: "all",
    resolver: yupResolver(registrationScheme),
  });
  const { t } = useTranslation();
  const navigator = useNavigate();
  const onSubmit = (data) => {
    const encodedPassword = btoa(data.password);
    const state = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: email,
      password: encodedPassword,
    };
    navigator("/login/new?step=phone", { state: state, replace: true });
  };
  if (!location.state) {
    return <Navigate to="/" />;
  }
  return (
    <Box
      component="form"
      sx={{
        backgroundColor: "white",
        mt: 10,
        py: 6,
        px: [2, 4, 6],
        borderRadius: 3,
        boxShadow: "-1px -1px 10px 1px rgba(0, 0, 0, 0.2)",
        display: "flex",
        width: ["90%", "70%", "400px"],
        flexDirection: "column",
        gap: 1,
      }}
    >
      <img
        src={mobileIcon}
        alt="logo"
        title="logo"
        loading="lazy"
        width="50px"
        height="50px"
        style={{
          width: "50px",
          height: "50px",
        }}
      />
      <Typography variant="h5" className="mt-3 font-bold">
        {t("registration.title")}
      </Typography>
      <Typography className="text-[#a8a8a8] mb-3">
        {t("registration.instruction")}
        {email}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: 1,
          mb: 1,
        }}
      >
        <TextInput
          label={t("registration.fnameLabel")}
          placeholder={t("registration.fnamePlaceholder")}
          error={errors.first_name?.message}
          {...register("first_name", { required: true })}
        />
        <TextInput
          label={t("registration.lnameLabel")}
          placeholder={t("registration.lnamePlaceholder")}
          error={errors.last_name?.message}
          {...register("last_name", { required: true })}
        />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <PasswordInput
          label={t("registration.passwordLabel")}
          placeholder={t("registration.passwordLabelPlaceholder")}
          error={errors.password?.message}
          {...register("password", { required: true })}
        />
        <PasswordInput
          label={t("registration.cPasswordLabel")}
          placeholder={t("registration.cPasswordPlaceholder")}
          error={errors.confirmPassword?.message}
          {...register("confirmPassword", { required: true })}
        />
      </Box>
      <Button
        type="submit"
        variant="contained"
        fullWidth
        disabled={!isValid}
        onClick={handleSubmit(onSubmit)}
        sx={{
          mt: 2,
          borderRadius: 6,
        }}
      >
        {t("btn.createAccount")}
      </Button>
    </Box>
  );
}

export default Registration;
