import React, { useEffect, useState } from "react";
import LoadingScreen from "../components/LoadingScreen";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { checkToken } from "../libs/tableAPI/checkExpire";
import { Helmet } from "react-helmet-async";
import { getOrderByTableNumber } from "../libs/customerAPI/getOrders";

function CheckTableToken() {
  const { merchant_id, token, tableNumber, bmerchant_id, transaction_uuid } =
    useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const navigator = useNavigate();

  useEffect(() => {
    const validateToken = async () => {
      try {
        const result = await checkToken(token);
        setIsValid(true);
      } catch (error) {
        setIsValid(false);
      } finally {
        setIsLoading(false);
      }
    };

    validateToken();
  }, [token]);

  useEffect(() => {
    const fetchInterval = setInterval(async () => {
      try {
        const result = await checkToken(token);
      } catch (error) {
        navigator(
          `/order/bill/${merchant_id}/${bmerchant_id}/${transaction_uuid}`
        );
      }
    }, 5000);

    return () => clearInterval(fetchInterval);
  }, [token, merchant_id, bmerchant_id, transaction_uuid, navigator]);

  useEffect(() => {
    if (!isLoading && !isValid) {
      navigator(`/table-merchant/expired`);
    }
  }, [isLoading, isValid, navigator]);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="สั่งเดลิเวอรีผ่าน POMPKINS Food  x Order with Google ได้แล้ววันนี้"
        />
        <link rel="canonical" href={`${window.location.href}`} />
        <meta property="og:url" content={`${window.location.href}`} />
      </Helmet>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <main>
          <Outlet />
        </main>
      )}
    </>
  );
}

export default CheckTableToken;
