import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useOrderFromGoogle = (merchant_id, bmerchant_id, orderType) => {
  const navigate = useNavigate();

  useEffect(() => {
    const orderFromGoogleStr = localStorage.getItem("orderFromGoogle");
    if (orderFromGoogleStr) {
      const orderFromGoogle = JSON.parse(orderFromGoogleStr);
      const now = new Date().getTime();
      if (now > orderFromGoogle.expiry) {
        localStorage.removeItem("orderFromGoogle");
      } else {
        if (
          orderFromGoogle.value &&
          orderFromGoogle.value.includes(
            `/merchant/${merchant_id}/${bmerchant_id}`
          )
        ) {
          const currentPathname = window.location.pathname;
          const url = new URL(orderFromGoogle.value);
          const utmSource = url.searchParams.get("utm_source");
          const utmMedium = url.searchParams.get("utm_medium");
          const utmCampaign = url.searchParams.get("utm_campaign");
          navigate(
            `${currentPathname}?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}&order_type=${orderType}`,
            { replace: true }
          );
        }
      }
    }
  }, [bmerchant_id, merchant_id, navigate, orderType]);
};

export default useOrderFromGoogle;
