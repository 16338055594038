import React, { useEffect } from "react";
import SuccessImg from "../../assets/images/emoji_pomkins_correct320.png";
import { Box } from "@mui/system";
import { Card, CardContent, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  updateOrderPaymentStatus,
  updateOrderStatus,
} from "../../libs/orderAPI/orderAPI";

function PaymentSuccess({
  transaction_uuid,
  order_uuid,
  merchant_id,
  bmerchant_id,
  paymentType,
}) {
  const navigator = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // updateOrderPaymentStatus(transaction_uuid, "PAID", paymentType)
  //   .then((res) => {
  //     // console.log("update payment", res.data);
  //   })
  //   .catch((err) => {
  //     // console.error("update payment error", err);
  //   });
  // updateOrderStatus(order_uuid, "PURCHASED")
  //   .then((res) => {
  //     // console.log("update status", res.data);
  //   })
  //   .catch((err) => {
  //     // console.error("update status error", err);
  //   });

  useEffect(() => {
    if (order_uuid) {
      const delay = 3000;

      const timeoutId = setTimeout(() => {
        navigator(
          `/order-status/${merchant_id}/${bmerchant_id}/${order_uuid}`,
          { replace: true }
        );
      }, delay);

      return () => clearTimeout(timeoutId);
    }
  }, [navigator, order_uuid]);

  return (
    <Box
      sx={{
        maxWidth: 1440,
        margin: "auto",
        mb: 6,
        padding: { xs: "2% 5%", sm: "2% 20%", md: "2% 24%" },
      }}
    >
      <Card sx={{ borderRadius: "15px" }}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 2,
              p: 2,
              textAlign: "center",
            }}
          >
            <img
              src={SuccessImg}
              alt="Success Img"
              title="Success Img"
              loading="lazy"
              width="100px"
              height="100px"
            />

            <Typography variant="h5">ชำระเงินสำเร็จ</Typography>
            <Typography variant="body1">
              ขอบคุณที่มาใช้บริการร้านของเรา
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

export default PaymentSuccess;
