import { useEffect, useState } from "react";
import { getPaymentStatusList } from "../libs/merchantAPI/merchantAPI";

const useAvailablePaymentList = (bmerchantService, orderType) => {
  const [availablePaymentList, setAvailablePaymentList] = useState([]);
  const [paymentListUpdated, setPaymentListUpdated] = useState(false);

  useEffect(() => {
    if (!bmerchantService || !bmerchantService.bmerchant_uuid) return;
    getPaymentStatusList(bmerchantService.bmerchant_uuid).then(
      ({ data: paymentData }) => {
        const payments = Object.keys(paymentData).filter(
          (key) =>
            paymentData[key] !== null &&
            key !== "bmerchant_uuid" &&
            key !== "thai_qr" &&
            key !== "prompt_pay"
        );
        setAvailablePaymentList(payments);
        setPaymentListUpdated(true);
      }
    );
  }, [orderType, bmerchantService, bmerchantService.bmerchant_uuid]);

  useEffect(() => {
    if (paymentListUpdated) {
      if (
        bmerchantService.delivery_cash &&
        orderType.toUpperCase() === "PICKUP"
      ) {
        setAvailablePaymentList((prev) => [...prev, "cash"]);
      } else {
        setAvailablePaymentList((prev) =>
          prev.filter((item) => item !== "cash")
        );
      }
      setPaymentListUpdated(false);
    }
  }, [
    availablePaymentList,
    paymentListUpdated,
    bmerchantService.delivery_cash,
    orderType,
  ]);

  return availablePaymentList;
};

export default useAvailablePaymentList;
