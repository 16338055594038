import { axiosInstance } from "../axios";

export const login = (body) => {
  return axiosInstance.post("/web/auth/login", body);
};

export const loginV2 = (body) => {
  return axiosInstance.post("/web/v2/auth/login", body);
};

export const checkEmail = (data) => {
  return axiosInstance.post("/web/v2/auth/check_email", data);
};

export const authentication = (token) => {
  return axiosInstance.get("/web/v2/auth/authentication", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const refreshVerify = (token) => {
  return axiosInstance.post("/web/customer/verify_token", {
    token: token,
  });
};

export const ssoThirdParty = (body) => {
  return axiosInstance.post("/web/v2/auth/sso/createUser", body);
};

export const forgotPassword = (body) => {
  return axiosInstance.post("/web/v2/auth/forgot_password", body);
};

export const updatePassword = (body) => {
  return axiosInstance.post("/web/v2/auth/update_password", body);
};
