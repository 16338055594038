import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function TawkTo() {
  const location = useLocation();
  const isHelpCenterPage = location.pathname === "/helpcenter"; // Adjust the pathname as per your actual Help Center page URL
  const [loadScript, setLoadScript] = useState(false);

  useEffect(() => {
    var Tawk_API = window.Tawk_API || {};
    var Tawk_LoadStart = new Date();
    if (!Tawk_API.loaded) {
      const script = document.createElement("script");
      script.async = true;
      script.src = "https://embed.tawk.to/65b630988d261e1b5f58c95b/1hl8g1oir";
      script.charset = "UTF-8";
      script.setAttribute("crossorigin", "*");
      script.onload = () => {
        Tawk_API = window.Tawk_API;
        setLoadScript(true);
      };
      document.head.appendChild(script);
      Tawk_API.loaded = true;
    } else {
      setLoadScript(true);
    }
  }, []);

  useEffect(() => {
    if (loadScript && window.Tawk_API) {
      if (isHelpCenterPage) {
        window.Tawk_API.showWidget();
      } else {
        window.Tawk_API.hideWidget();
      }
    }
  }, [isHelpCenterPage, loadScript]);

  return null; // Since this component doesn't render anything, return null
}

export default TawkTo;
