import React from "react";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { DialogContent, ListItemButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useLocationStore } from "../../stores/useLocationStore";
import { useAuthStore } from "../../stores/useAuthStore";

ModalHomeDialogMenu.defaultProps = {
  open: false,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

export default function ModalHomeDialogMenu({ open, setOpen }) {
  const { t } = useTranslation();
  const { openModal, currentAddress } = useLocationStore();
  const navigator = useNavigate();
  const { isAuthenticated, logout } = useAuthStore();
  const homeDialogMenu = [
    {
      label: t("nav.priceAndPackage"),
      event: () => navigator("/pompkins/package"),
    },
    {
      label: t("nav.findRestaurants"),
      event: () => (!currentAddress ? openModal() : navigator("/food")),
    },
    {
      label: t("nav.contactUs"),
      event: () => navigator("/contactus"),
    },
    { label: t("nav.FAQ"), event: () => navigator("/pompkins/FAQ") },
    // {
    //   label: isAuthenticated ? t("options.logout") : t("btn.login"),
    //   event: isAuthenticated ? () => logout() : () => navigator("/login/new"),
    // },
  ];
  const handleMenuClick = (event) => {
    handleClose();
    event();
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <IconButton
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon sx={{ color: "#FF961B" }} />
        </IconButton>
        <DialogContent sx={{ mt: 3 }}>
          <List>
            {homeDialogMenu.map((option) => (
              <div key={option.label}>
                <ListItemButton onClick={() => handleMenuClick(option.event)}>
                  <ListItemText primary={option.label} />
                </ListItemButton>
              </div>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </div>
  );
}
