import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getMerchantById } from "../../libs/merchantAPI/merchantAPI";
import LoadingScreen from "../../components/LoadingScreen";
import { ArrowBack, Shuffle } from "@mui/icons-material";
import { getRandomColor } from "../../utils/randomColor";
import bgDefault from "../../assets/image/bg-graphic.png";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import DefaultProfile from "../../assets/images/pompkins_food_logo.png";
import { getCookie } from "../../utils/getToken";
import { useTranslation } from "react-i18next";

function QrCreateUser() {
  const version = new Date().toISOString().split("T")[0];
  const { merchant_id, bmerchant_id, token, tableNumber, transaction_uuid } =
    useParams();
  const [merchantData, setMerchantData] = useState();
  const [isFetch, setIsFetch] = useState(false);
  const navigator = useNavigate();
  const [inputText, setInputText] = useState("");
  // getCookie ("selectedLanguage")
  const selectedLanguage = getCookie("selectedLanguage") || "ไทย";
  const { t } = useTranslation();

  const getRandomName = () => {
    let names;
    if (selectedLanguage === "ไทย") {
      names = [
        "ตู่",
        "หมูเด้ง",
        "ลีน่าจัง",
        "หอยหลอด",
        "อูเด็น",
        "แดง",
        "เขียว",
        "หัวจุก",
        "ลูกหมู",
        "เจ้าเอก",
        "ตัวกลม",
        "ปลาน้อย",
        "หมูหวงยาง",
        "เปี๊ยก",
        "ลุยสวน",
        "เป้า",
      ];
    } else {
      names = [
        "Tu",
        "Mu Deng",
        "Lina-chan",
        "Hoy Lod",
        "Udon", //
        "Daeng", //
        "Khiao", //
        "Hua Chuk",
        "Luk Mu",
        "Chao Ek",
        "Tua Klom",
        "Pla Noi",
        "Mu Huang Yang",
        "Piak",
        "Lui Suan",
        "Isabella",
      ];
    }

    const randomIndex = Math.floor(Math.random() * names.length);
    setInputText(names[randomIndex]);
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setInputText(inputValue);
  };

  const fetchMerchant = useCallback(() => {
    getMerchantById(merchant_id)
      .then((res1) => {
        setMerchantData(res1.data);
        setIsFetch(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [merchant_id]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/table-merchant/${token}/${merchant_id}/${bmerchant_id}/${tableNumber}/${transaction_uuid}/create-user`,
      title: "QrCreateUser",
    });
  }, []);

  const onSubmit = () => {
    const nameColor = getRandomColor();
    localStorage.setItem("nameColor", nameColor);
    localStorage.setItem("name", inputText);
    navigator(
      `/table-merchant/${token}/${merchant_id}/${bmerchant_id}/${tableNumber}/${transaction_uuid}/menu`
    );
  };

  useEffect(() => {
    if (!isFetch) {
      fetchMerchant();
    }
  }, [fetchMerchant, isFetch]);

  if (!isFetch) {
    return <LoadingScreen />;
  }
  return (
    <>
      <Helmet>
        <title>{merchantData.name} | POMPKINS Food</title>
        <meta
          name="description"
          content="สั่งเดลิเวอรีผ่าน POMPKINS Food  x Order with Google ได้แล้ววันนี้"
        />
        <link rel="canonical" href={`${window.location.href}`} />
        <meta property="og:url" content={`${window.location.href}`} />
        <meta
          property="og:title"
          content={`${merchantData.name} | POMPKINS Food`}
        />
        <meta
          property="og:image"
          content={`${merchantData?.logo}?v=${version}` ?? DefaultProfile}
        />
        <meta
          name="twitter:image"
          content={`${merchantData?.logo}?v=${version}` ?? DefaultProfile}
        />
      </Helmet>
      <Box
        sx={{
          backgroundImage: `url(${merchantData.background ?? bgDefault})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
          width: "100%",
        }}
      >
        <Box height={"45%"} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
            px: 3,
            height: "45%",
            position: "relative",
          }}
        >
          <Typography
            sx={{ textShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)" }}
            variant="h6"
            color="white"
            fontWeight={500}
            mb={2}
          >
            {t("qrtable.pleaseInformName")}
          </Typography>
          {/* <Typography
          sx={{ textShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)" }}
          variant="h6"
          color="white"
          fontWeight={300}
        >
          หรือให้เราระบุ
        </Typography> */}
          <TextField
            type="text"
            value={inputText}
            placeholder={t("qrtable.informName")}
            onChange={handleInputChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => getRandomName()}
                    aria-label="random"
                  >
                    <Shuffle className="text-primary" />
                  </IconButton>
                </InputAdornment>
              ),
              disableunderline: true,
              sx: { backgroundColor: "white" },
            }}
          />

          <Box sx={{ display: "flex", flexDirection: "row", mt: 2, gap: 2 }}>
            <Button
              variant="contained"
              onClick={() =>
                navigator(
                  `/table-merchant/${token}/${merchant_id}/${bmerchant_id}/${tableNumber}/${transaction_uuid}`
                )
              }
              style={{
                fontSize: "18px",
                height: ["60px", "40px", "40px"],
                width: "30%",
                backgroundColor: "white",
                color: "black",
              }}
            >
              <ArrowBack />
            </Button>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={!inputText}
              onClick={onSubmit}
              sx={{
                fontSize: "18px",
                height: ["60px", "40px", "40px"],
                ":disabled": {
                  backgroundColor: "#a8a8a8",
                },
              }}
            >
              {t("qrtable.next")}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default QrCreateUser;
