import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { preferencesData } from "./preferencesData";
import { formScheme } from "./formScheme";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// Custom styled Checkbox
const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  color: "#f9931f",
  "&.Mui-checked": {
    color: "#f9931f",
  },
  "&:hover": {
    backgroundColor: "rgba(249, 147, 31, 0.04)",
  },
}));

function Optout() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [preferences, setPreferences] = useState(
    preferencesData.reduce((acc, pref) => {
      acc[pref.name] = { checked: false, comment: "" };
      return acc;
    }, {})
  );

  const handlePreferenceChange = (event) => {
    const { name, checked } = event.target;
    setPreferences((prev) => ({
      ...prev,
      [name]: { ...prev[name], checked },
    }));
  };

  const handleCommentChange = (name, value) => {
    setPreferences((prev) => ({
      ...prev,
      [name]: { ...prev[name], comment: value },
    }));
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(formScheme),
  });

  const onSubmit = (data) => {
    const checkedPreferences = Object.entries(preferences)
      .filter(([_, pref]) => pref.checked)
      .map(([name, pref]) => ({
        name,
        comment: pref.comment,
      }));

    const submissionData = {
      email: data.email,
      preferences: checkedPreferences,
    };

    navigate("/opt-out/thankyou");
  };

  return (
    <Box sx={{ bgcolor: "#f9931f", minHeight: "100vh", p: 4 }}>
      <Box
        sx={{
          bgcolor: "white",
          borderRadius: 2,
          p: 4,
          maxWidth: 600,
          mx: "auto",
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Let's communicate better!
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Tell us what YOU want to keep hearing about.
        </Typography>
        <Box sx={{ mt: 4, display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography variant="h6" gutterBottom>
            Continue hearing about:
          </Typography>
          {preferencesData.map((pref) => (
            <Box key={pref.name}>
              <FormControlLabel
                control={
                  <CustomCheckbox
                    checked={preferences[pref.name].checked}
                    onChange={handlePreferenceChange}
                    name={pref.name}
                  />
                }
                label={
                  <Box>
                    <Typography variant="body1">{pref.label}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      {pref.description}
                    </Typography>
                  </Box>
                }
              />
              {preferences[pref.name].checked && (
                <Box width={"80%"}>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Add a comment (optional)"
                    value={preferences[pref.name].comment}
                    onChange={(e) =>
                      handleCommentChange(pref.name, e.target.value)
                    }
                    variant="outlined"
                    sx={{ mt: 1, ml: 4 }}
                  />
                </Box>
              )}
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            mt: 6,
            display: "flex",
            flexDirection: "row",
            gap: 2,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ flex: 1 }}>
            <TextField
              fullWidth
              size="small"
              placeholder={t("emailStep.placholder")}
              error={errors.email?.message}
              {...register("email", { required: true })}
              variant="outlined"
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
            disableRipple
            sx={{
              bgcolor: "#f9931f",
              "&:hover": { bgcolor: "#e88c1c" },
              my: errors.email?.message ? 1 : 0,
              height: "40px",
            }}
          >
            Save changes
          </Button>
        </Box>
        {errors.email?.message && (
          <FormHelperText error className="min-h-[1em]">
            {errors.email.message}
          </FormHelperText>
        )}
      </Box>
    </Box>
  );
}

export default Optout;
