import { Box } from "@mui/material";
import React, { useEffect } from "react";
import MyProfileBox from "./MyProfileBox";
import EmailBox from "./EmailBox";
import PasswordBox from "./PasswordBox";
// import MyAccountBox from "./MyAccountBox";
import DeleteAccountBox from "./DeleteAccountBox";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

function ProfilePage() {
  const { t } = useTranslation();
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/profile",
      title: "ProfilePage",
    });
  }, []);
  return (
    <div>
      <Helmet>
        <title>
          {t("pompkins")} - {t("your.profile")}
        </title>
        <meta
          name="description"
          content="แอปเดียว ครบ ง่าย คุ้ม เปลี่ยนทุกความไม่ง่ายในชีวิต ให้ง่ายขึ้นด้วยแอปเดียว POS สั่งอาหาร ชำระเงิน จัดการพนักงาน ดูยอดขาย ดาวน์โหลด ฟรี ได้วันนี้"
        />
        <link rel="canonical" href={`${window.location.href}`} />
        <meta property="og:url" content="https://pompkins.com/profile" />
      </Helmet>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          my: [10, 10, 15],
        }}
      >
        <MyProfileBox />

        <EmailBox />
        <PasswordBox />
        {/* <MyAccountBox /> */}
        <DeleteAccountBox />
      </Box>
    </div>
  );
}

export default ProfilePage;
