import { initializeApp } from "firebase/app";
import { getAuth, signInWithCustomToken } from "firebase/auth";

export const app = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
});

export const auth = getAuth(app);

// Test Workspace
// const signInWithCustomTokenResponse = () =>
//   signInWithCustomToken(
//     auth,
//     "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJmaXJlYmFzZS1hZG1pbnNkay1sNXNsdEBmb3JtYWwtcGxhdGZvcm0tNDAxOTA5LmlhbS5nc2VydmljZWFjY291bnQuY29tIiwiYXVkIjoiaHR0cHM6Ly9pZGVudGl0eXRvb2xraXQuZ29vZ2xlYXBpcy5jb20vZ29vZ2xlLmlkZW50aXR5LmlkZW50aXR5dG9vbGtpdC52MS5JZGVudGl0eVRvb2xraXQiLCJleHAiOjE3MjkxNTY5MjQsImlhdCI6MTcyOTE1MzMyNCwic3ViIjoiZmlyZWJhc2UtYWRtaW5zZGstbDVzbHRAZm9ybWFsLXBsYXRmb3JtLTQwMTkwOS5pYW0uZ3NlcnZpY2VhY2NvdW50LmNvbSIsInVpZCI6IjM0MmJlZDc0LWUwM2ItNDI3Ny1iN2IxLTc4NjA5MWE2NzY5ZCJ9.o6hHeiRswMKbSfVKE6RVtVqmJ_A6c0dqra1yHJ352qRxrc8lrV5mxsyAjpxxmXFoo_HAvRT3HXG71_3Nb0hYGGiOWevolPAK_dW7Xihdb2fY0gtoByFyJpmRJmI0lTV0wCvxLn_UNoH-sx_IB3aibZbRWW9jq14I_bA3VWlD6RHHMWNdN742IRV31Xzvj86_w8nzG3TOS3IgUnmSqq_Y-mSxhfvkbmqJVY-g5TfixnhsmYx-IbPVgJXrGtkaLi1xkCT8dEgDaiK6fOqit3gmDdxN47nQ6XsuKu7JFJFBF2-UhvdVuqvqtbbyMNbZsgSMJizQ8UXywHKGfxoAHcJeNw"
//   ).then(async (firebaseRes) => {
//     console.log("firebaseRes", firebaseRes);
//   });

// export default signInWithCustomTokenResponse;
