import { Box, Typography, Button } from "@mui/material";
import React from "react";
import TextInput from "../../components/TextInput";
import { useForm } from "react-hook-form";
import { emailScheme } from "../../libs/authScheme";
import { yupResolver } from "@hookform/resolvers/yup";
import EmailIcon from "../../assets/icon/email.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { checkEmail } from "../../libs/authAPI/loginAPI";

function EmailStep() {
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm({ mode: "onSubmit", resolver: yupResolver(emailScheme) });

  const navigator = useNavigate();
  const onSubmit = (data) => {
    checkEmail(data)
      .then((_) => {
        navigator("/login/new?step=login", { state: data.email });
      })
      .catch((_) => {
        navigator("/login/new?step=registration", { state: data.email });
      });
  };
  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        backgroundColor: "white",
        mt: 10,
        py: 6,
        px: [2, 4, 6],
        borderRadius: 3,
        boxShadow: "-1px -1px 10px 1px rgba(0, 0, 0, 0.2)",
        display: "flex",
        width: ["90%", "70%", "400px"],
        flexDirection: "column",
        gap: 1,
      }}
    >
      <img
        src={EmailIcon}
        alt="logo"
        title="logo"
        loading="lazy"
        width="50px"
        height="50px"
        style={{
          width: "50px",
          height: "50px",
        }}
      />
      <Typography variant="h5" className="mt-3 font-bold">
        {t("emailStep.title")}
      </Typography>
      <Typography className="text-[#a8a8a8] mb-3">
        {t("emailStep.instruction")}
      </Typography>

      <TextInput
        label={t("emailStep.label")}
        placeholder={t("emailStep.placholder")}
        error={errors.email?.message}
        {...register("email", { required: true })}
      />

      <Button
        type="submit"
        variant="contained"
        fullWidth
        disabled={!isValid}
        sx={{
          mt: 2,
          borderRadius: 6,
        }}
      >
        {t("btn.next")}
      </Button>
    </Box>
  );
}
export default EmailStep;
