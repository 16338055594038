export const filterMenu = (searchValue, displayMenu) => {
  if (!searchValue) return displayMenu;

  const normalizedSearchValue = searchValue.toLowerCase().trim();

  return displayMenu
    .map((item) => {
      // Filter the menu items that match the search criteria
      const filteredMenu = item.menu.filter((menuItem) => {
        const menuMatch = menuItem.name
          .toLowerCase()
          .includes(normalizedSearchValue);

        const optionsMatch = menuItem.options?.some((option) =>
          option.sub_option?.some((subOption) =>
            subOption.name.toLowerCase().includes(normalizedSearchValue)
          )
        );

        const descriptionMatch = menuItem.description
          ?.toLowerCase()
          .includes(normalizedSearchValue);

        return menuMatch || descriptionMatch;
      });

      // If the category name matches, include all filtered menu items
      const categoryMatch = item.category.name
        .toLowerCase()
        .includes(normalizedSearchValue);

      // Return the category with only the filtered menu items
      if (categoryMatch || filteredMenu.length > 0) {
        return {
          ...item,
          menu: filteredMenu,
        };
      }

      // If no matches, return null (or you can filter these out later)
      return null;
    })
    .filter((item) => item !== null); // Remove null entries
};
