import React from "react";
import { Box, Typography, Radio } from "@mui/material";
import { useTranslation } from "react-i18next";

const PaymentOptions = ({
  availablePaymentList,
  paymentData,
  bmerchantService,
  paymentType,
  setPaymentType,
}) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ my: 2 }}>
      <Typography variant="body1" color={"#343434"}>
        ชำระเงินโดย
      </Typography>

      {availablePaymentList.length === 0 ? (
        <Typography variant="body1" color={"red"} sx={{ mt: 2 }}>
          **ร้านค้ายังไม่ได้ตั้งค่าระบบชำระเงิน**
        </Typography>
      ) : (
        <>
          {paymentData
            .filter((item) =>
              availablePaymentList.includes(item.paymentType.toLowerCase())
            )
            .map((item, index) => (
              <Box
                onClick={() => {
                  if (
                    !(
                      item.paymentType === "CASH" &&
                      !bmerchantService.delivery_cash
                    )
                  ) {
                    setPaymentType(item.paymentType);
                  }
                }}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  mt: 1,
                  px: 1,
                  cursor:
                    item.paymentType === "CASH" &&
                    !bmerchantService.delivery_cash
                      ? "not-allowed"
                      : "pointer",
                  ":hover": {
                    bgcolor:
                      item.paymentType === "CASH" &&
                      !bmerchantService.delivery_cash
                        ? "inherit"
                        : "#ffebcf",
                    borderRadius: 3,
                  },
                  opacity:
                    item.paymentType === "CASH" &&
                    !bmerchantService.delivery_cash
                      ? 0.5
                      : 1,
                }}
                key={index}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  {item.images?.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt="icon"
                      loading="lazy"
                      title="icon"
                      width="36px"
                      height="36px"
                      style={{ height: "36px", objectFit: "cover" }}
                    />
                  ))}
                  <Typography variant="body2">
                    {item.title}
                    {item.paymentType === "CASH" &&
                    !bmerchantService.delivery_cash
                      ? ` (${t("PaymentNotAvailable")})`
                      : ""}
                  </Typography>
                </Box>
                <Radio
                  checked={item.paymentType === paymentType}
                  disableRipple
                  onChange={() => {
                    if (
                      !(
                        item.paymentType === "CASH" &&
                        !bmerchantService.delivery_cash
                      )
                    ) {
                      setPaymentType(item.paymentType);
                    }
                  }}
                  value={paymentType}
                  sx={{
                    color: "#FF961B",
                    "&.Mui-checked": {
                      color: "#FF961B",
                    },
                  }}
                  disabled={
                    item.paymentType === "CASH" &&
                    !bmerchantService.delivery_cash
                  }
                />
              </Box>
            ))}
        </>
      )}
    </Box>
  );
};

export default PaymentOptions;
