import React, { useState, useRef } from "react";
import TextField from "@mui/material/TextField";

const OTPInput = ({ onOTPChange }) => {
  const [otp, setOTP] = useState(["", "", "", "", "", ""]);
  const inputRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];
  const handleKeyDown = (e, index) => {
    // Handle backspace to move focus to the previous input
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      const newOTP = [...otp];
      newOTP[index - 1] = ""; // Clear the previous input
      setOTP(newOTP);
      inputRefs[index - 1].current.focus();
      e.preventDefault();
    }
  };

  const handleBeforeInput = (e) => {
    // Allow only numeric input (0-9)
    if (!/^[0-9]$/.test(e.data)) {
      e.preventDefault();
    }
  };

  const handleInputChange = (e, index) => {
    const newOTP = [...otp];
    newOTP[index] = e.target.value;
    setOTP(newOTP);

    if (e.target.value && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    } else if (!e.target.value && index > 0) {
      inputRefs[index - 1].current.focus();
    }
    const otpValue = newOTP.join("");
    onOTPChange(otpValue);
  };

  return (
    <div>
      {otp.map((value, index) => (
        <TextField
          key={index}
          type="text"
          variant="standard"
          margin="dense"
          value={value}
          color="warning"
          onChange={(e) => handleInputChange(e, index)}
          onBeforeInput={handleBeforeInput}
          onKeyDown={(e) => handleKeyDown(e, index)}
          sx={{ p: 1, width: "16%" }}
          inputProps={{
            maxLength: 1,
            style: { textAlign: "center", fontSize: "1.5rem" },
          }}
          inputRef={inputRefs[index]}
        />
      ))}
    </div>
  );
};

export default OTPInput;
