import React, { useState, useMemo, useCallback } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Button,
  IconButton,
  TextField,
  useMediaQuery,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import StarIcon from "@mui/icons-material/Star";
import { debounce } from "lodash";

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  color: "#E4e4e4",
  "&.Mui-checked": {
    color: "#343434",
  },
  "&:hover": {
    backgroundColor: "rgba(249, 147, 31, 0.04)",
  },
}));

const CustomRadio = styled(Radio)(({ theme }) => ({
  color: "#E4e4e4",
  "&.Mui-checked": {
    color: "#343434",
  },
  "&:hover": {
    backgroundColor: "rgba(249, 147, 31, 0.04)",
  },
}));

function FilterPanel({
  onClose,
  sortBy,
  setSortBy,
  filters,
  setFilters,
  handleRatingChange,
  handleShopTypeChange,
  handleClearAll,
  filteredData,
  fetchMerchant,
}) {
  const { t } = useTranslation();

  const allCuisines = [
    {
      label: t("merchantInfoScreen.thaiFood"),
      value: "thai",
    },
    {
      label: t("merchantInfoScreen.internationalFood"),
      value: "international",
    },
    {
      label: t("merchantInfoScreen.healthFood"),
      value: "health",
    },
    {
      label: t("merchantInfoScreen.fastFood"),
      value: "fastfood",
    },
    {
      label: t("merchantInfoScreen.madeToOrderFood"),
      value: "made_to_order",
    },
    {
      label: t("merchantInfoScreen.breakfastFood"),
      value: "breakfast",
    },
    {
      label: t("merchantInfoScreen.desserts"),
      value: "desserts",
    },
    {
      label: t("merchantInfoScreen.beverages"),
      value: "beverages",
    },
    {
      label: t("merchantInfoScreen.italianFood"),
      value: "italian",
    },
    {
      label: t("merchantInfoScreen.japaneseFood"),
      value: "japanese",
    },
    {
      label: t("merchantInfoScreen.vietnameseFood"),
      value: "vietnamese",
    },
    {
      label: t("merchantInfoScreen.indianFood"),
      value: "indian",
    },
    {
      label: t("merchantInfoScreen.chineseFood"),
      value: "chinese",
    },
    {
      label: t("merchantInfoScreen.vegetarianFood"),
      value: "vegetarian",
    },
    {
      label: t("merchantInfoScreen.koreanFood"),
      value: "korean",
    },
    {
      label: t("merchantInfoScreen.frenchFood"),
      value: "french",
    },
    {
      label: t("merchantInfoScreen.americanFood"),
      value: "american",
    },
    {
      label: t("merchantInfoScreen.noodleFood"),
      value: "raw_noodle",
    },
  ];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const debouncedSetFilters = useCallback(
    debounce((newFilters) => {
      setFilters(newFilters);
      fetchMerchant();
    }, 10),
    [fetchMerchant]
  );

  const handleFilterChange = useCallback(
    (newFilters) => {
      debouncedSetFilters(newFilters);
    },
    [debouncedSetFilters]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        minWidth: 300,
        maxWidth: !isMobile ? 300 : "100%",
      }}
    >
      <Box
        sx={{
          p: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" fontSize={18} fontWeight={600}>
          {t("FilterPanel.title")}
        </Typography>
        {isMobile && (
          <IconButton
            onClick={onClose}
            sx={{
              borderRadius: "50%",
              border: "1px solid",
              padding: "3px",
              transition: "background-color 0.3s",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          px: 2,
        }}
      >
        {/* Sort By */}

        <Typography variant="subtitle1" gutterBottom fontSize={16}>
          {t("FilterPanel.sortBy")}
        </Typography>
        <RadioGroup
          value={sortBy}
          onChange={(e) => {
            setSortBy(e.target.value);
            fetchMerchant();
          }}
        >
          <FormControlLabel
            value="relevance"
            control={<CustomRadio />}
            label={
              <Typography sx={{ fontSize: 14 }}>
                {t("FilterPanel.relevance")}
              </Typography>
            }
          />
          {/* <FormControlLabel
            value="time"
            control={<CustomRadio />}
            label={
              <Typography sx={{ fontSize: 14 }}>
                {t("FilterPanel.fastestDelivery")}
              </Typography>
            }
          /> */}
          <FormControlLabel
            value="distance"
            control={<CustomRadio />}
            label={
              <Typography sx={{ fontSize: 14 }}>
                {t("FilterPanel.distance")}
              </Typography>
            }
          />
        </RadioGroup>

        {/* Filters */}

        <Box mt={2}>
          <Button
            variant={filters.rating === 4 ? "contained" : "outlined"}
            startIcon={
              <StarIcon
                sx={{ color: filters.rating === 4 ? "white" : "#f9931f" }}
              />
            }
            sx={{
              textTransform: "none",
              mr: 1,
              color: filters.rating === 4 ? "white" : "#000000",
              fontWeight: 400,
              borderRadius: "20px",
            }}
            onClick={() =>
              handleFilterChange({
                ...filters,
                rating: filters.rating === 0 ? 4 : 0,
              })
            }
          >
            {t("FilterPanel.rating")} 4+
          </Button>
          <Button
            variant={filters.isOpen ? "contained" : "outlined"}
            sx={{
              textTransform: "none",
              color: filters.isOpen ? "white" : "#000000",
              fontWeight: 400,
              borderRadius: "20px",
            }}
            onClick={() =>
              handleFilterChange({
                ...filters,
                isOpen: !filters.isOpen,
              })
            }
          >
            {t("FilterPanel.openNow")}
          </Button>
        </Box>

        {/* <Typography variant="subtitle1" gutterBottom fontSize={14}>
            Rating
          </Typography>
          {[4, 5].map((rating) => (
            <FormControlLabel
              key={rating}
              control={
                <CustomCheckbox
                  checked={filters.rating}
                  onChange={() => handleRatingChange(rating)}
                />
              }
              label={
                <Typography sx={{ fontSize: 14 }}>
                  {rating}
                  {rating === 4 ? "+" : ""}
                </Typography>
              }
            />
          ))} */}
        <Typography variant="subtitle1" gutterBottom fontSize={14} mt={2}>
          {t("FilterPanel.cuisines")}
        </Typography>
        <Grid container spacing={1} sx={{ padding: 0 }}>
          {allCuisines.map((type) => (
            <Grid item xs={12} sm={6} key={type.value} sx={{ padding: 0 }}>
              <FormControlLabel
                control={
                  <CustomCheckbox
                    checked={filters.shopType.includes(type.value)}
                    onChange={() =>
                      handleFilterChange({
                        ...filters,
                        shopType: filters.shopType.includes(type.value)
                          ? filters.shopType.filter((t) => t !== type.value)
                          : [...filters.shopType, type.value],
                      })
                    }
                  />
                }
                label={
                  <Typography sx={{ fontSize: 14 }}>{type.label}</Typography>
                }
              />
            </Grid>
          ))}
        </Grid>

        {/* Render Filtered and Sorted Data */}
        {/* <Box>
          {filteredData.map((merchant) => (
            <Box key={merchant.id} mb={2}>
              <Typography variant="body1">{merchant.title}</Typography>
              <Typography variant="body2">{merchant.location}</Typography>
              <Typography variant="body2">Rating: {merchant.rating}</Typography>
              <Typography variant="body2">
                Distance: {merchant.distance}
              </Typography>
              <Typography variant="body2">
                Time: {merchant.time} mins
              </Typography>
            </Box>
          ))}
        </Box> */}
      </Box>
    </Box>
  );
}

export default React.memo(FilterPanel);
