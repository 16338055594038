import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/system";

const CouponContainer = styled(Box)({
  display: "flex",
  width: "100%",
  height: "10dvh",
  border: "1px solid #F19A3C",
  borderRadius: "11px",
  overflow: "hidden",
  padding: "10px",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
});

const CouponTextContainer = styled(Box)({
  flexBasis: "70%",
  display: "flex",
  flexDirection: "column",
  paddingLeft: "16px",
});

const CouponText = styled(Typography)({
  fontSize: "14px",
  fontWeight: "bold",
  color: "#F19A3C",
});

const CouponDescription = styled(Typography)({
  fontSize: "12px",
  fontWeight: "medium",
  color: "#343434",
});

const CouponExpiry = styled(Typography)({
  fontSize: "10px",
  fontWeight: "medium",
  color: "#797979",
});

const CouponButtonContainer = styled(Box)({
  borderLeft: "1px dashed #F19A3C",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexBasis: "30%",
});

const CouponButton = styled(Button)({
  fontSize: "16px",
  fontWeight: "bold",
  borderRadius: "30px",
  color: "#F19A3C",
  backgroundColor: "#FFE8C6",
  "&:hover": {
    backgroundColor: "#F19A3C",
    color: "#FFFFFF",
  },
});

function Coupon({ id, subtext, description, expiry, sx }) {
  return (
    <CouponContainer key={id} sx={{ ...sx }}>
      <CouponTextContainer>
        <CouponText>{description}</CouponText>
        <CouponDescription>{subtext}</CouponDescription>
        <CouponExpiry>{expiry}</CouponExpiry>
      </CouponTextContainer>
      <CouponButtonContainer>
        <CouponButton aria-label="ใช้คูปอง">ใช้</CouponButton>
      </CouponButtonContainer>
    </CouponContainer>
  );
}

export default Coupon;
