import React, { useState } from "react";
import { Box, Typography, Card } from "@mui/material";
import DefaultProfile from "../../utils/defaultImage";
import ModalImage from "../../components/ModalImage";

const CheckOutItem = ({ item }) => {
  const [imageModal, setImageModal] = useState(false);
  const calculateTotalPrice = (item) => {
    const total = parseInt(item.quantity, 10) * parseInt(item.price, 10);
    return parseFloat(total.toFixed(2)); // Returns number
  };
  return (
    <>
      <Card
        key={item.menu_id}
        sx={{ mt: 2, boxShadow: 0, borderRadius: 2, overflow: "hidden" }}
      >
        <Box sx={{ p: 1 }}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box
              sx={{
                border: "1px solid #D9D9D9",
                borderRadius: "6px",
                width: "25px",
                height: "25px",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography sx={{}}>{item.quantity}</Typography>
            </Box>
            <Box
              sx={{
                flex: 1,
                ml: 2,
                display: "flex",
                flexDirection: "column",
                maxWidth: "calc(100% - 80px)",
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ color: "#343434", fontWeight: "medium" }}
              >
                {item.menu_name}
              </Typography>
              {item.option.map((option, index) => (
                <Typography
                  key={index} // Make sure to specify a unique key for each element when mapping
                  variant="body2"
                  sx={{
                    fontSize: "12px",
                    width: "80%",
                    height: "50%",
                    fontWeight: "medium",
                    color: "#797979",
                  }}
                >
                  {option}
                </Typography>
              ))}

              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ fontSize: "14px", mt: "4px" }}
              >
                Note : {item.note}
              </Typography>
            </Box>
            <Typography variant="body2" color="#343434" fontSize={"16px"}>
              {`฿ ${
                item.price *
                item.quantity
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
              }`}
            </Typography>
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default CheckOutItem;
