import { Box, Button, Dialog, Typography } from "@mui/material";
import React from "react";
import ClosedShop from "../../assets/images/Closed.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
function MerchantCloseModal({
  open = false,
  setOpenClose,
  seeMenuButton = true,
}) {
  const navigator = useNavigate();
  const { t } = useTranslation();
  return (
    <Dialog open={open}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          m: 3,
          px: [5, 10, 15],
          gap: 2,
        }}
      >
        <img
          src={ClosedShop}
          alt="close-shop"
          title="close-shop"
          loading="lazy"
          width="150px"
          height="150px"
          style={{ width: "150px", height: "150px" }}
        />
        <Typography sx={{ fontWeight: 500 }}>
          {t("merchant.closeTime")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: ["column", "row"],
            gap: 2,
          }}
        >
          <Button variant="outlined" onClick={() => navigator("/food")}>
            {t("merchant.backToHomePage")}
          </Button>
          {seeMenuButton && (
            <Button variant="outlined" onClick={() => setOpenClose(false)}>
              {t("merchant.watchMenu")}
            </Button>
          )}
        </Box>
      </Box>
    </Dialog>
  );
}

export default MerchantCloseModal;
