import React, { useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { passwordScheme } from "../../libs/authScheme";
import { yupResolver } from "@hookform/resolvers/yup";
import Padlock from "../../assets/icon/padlock.png";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PasswordInput from "../../components/PasswordInput";
import { authentication, login, loginV2 } from "../../libs/authAPI/loginAPI";
import { useAuthStore } from "../../stores/useAuthStore";
import useModalLoading from "../../stores/useModalLoading";
import { getCustomerByUUID } from "../../libs/customerAPI/getCustomer";
import { useGuestLogin } from "../../stores/useGuestLogin";
import { signInWithCustomToken } from "firebase/auth";
import signInWithCustomTokenResponse, { auth } from "../../utils/firebaseAuth";
import { Helmet } from "react-helmet-async";

function Login() {
  const { t } = useTranslation();
  const location = useLocation();
  const { login: loginStore, locationFrom } = useAuthStore();
  const { openModal, closeModal } = useModalLoading();
  const navigator = useNavigate();
  const { isGuest, guestLogout } = useGuestLogin();
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    setError,
  } = useForm({ mode: "all", resolver: yupResolver(passwordScheme) });
  const email = location.state;

  const onSubmit = async (data) => {
    const encodedPassword = btoa(data.password);
    const body = {
      email: email,
      password: encodedPassword,
    };
    openModal();
    loginV2(body)
      .then((loginRes) => {
        const token = loginRes.data.token;
        signInWithCustomToken(auth, loginRes.data.token).then(
          async (firebaseRes) => {
            const token = await firebaseRes.user.getIdToken();
            authentication(token).then(({ data }) => {
              const custom_token = data.customToken;
              document.cookie = `token=${custom_token}; path=/`;
            });
          }
        );
        getCustomerByUUID(loginRes.data.customersUUID)
          .then((res) => {
            const user = {
              email: res.data.email,
              first_name: res.data.first_name,
              last_name: res.data.last_name,
              phone: res.data.phone,
              uuid: res.data.uuid,
              favorite: res.data.favorite_bmerchants ?? [],
            };
            if (isGuest) {
              localStorage.removeItem("guest");
              localStorage.removeItem("guest_order");
              guestLogout();
            }
            localStorage.setItem("user", JSON.stringify(user));
            loginStore(user);
            closeModal();
            navigator(locationFrom ? `${locationFrom}` : "/", {
              replace: true,
            });
          })
          .catch(() => {
            const user = {
              email: loginRes.data.email,
              first_name: loginRes.data.firstName,
              last_name: loginRes.data.lastName,
              phone: loginRes.data.phone,
              uuid: loginRes.data.customersUUID,
              favorite: loginRes.data.favorite_bmerchants ?? [],
            };
            if (isGuest) {
              localStorage.removeItem("guest");
              localStorage.removeItem("guest_order");
              guestLogout();
            }
            localStorage.setItem("user", JSON.stringify(user));
            loginStore(user);
            closeModal();
            navigator(locationFrom ? `${locationFrom}` : "/", {
              replace: true,
            });
          });
      })
      .catch((error) => {
        // TODO: when user inform wrong password
        console.log(error);
        setError("password", { message: t("login.error") });
        closeModal();
      });
  };
  if (!location.state) {
    return <Navigate to="/" />;
  }
  return (
    <>
      <Helmet>
        <title>
          {t("pompkins")} - {t("title.login.page")}
        </title>
        <meta name="description" content="เข้าสู่ระบบ" />
        <link rel="cononical" href="/login/new" />
        <meta property="og:url" content="https://pompkins.com/login/new" />
        <meta
          property="og:title"
          content={`${t("pompkins")} - ${t("title.login.page")}`}
        />

        <meta name="twitter:creator" content="POMPKINS" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content="https://pompkins.com/login/new" />
        <meta
          name="twitter:title"
          content={`${t("pompkins")} - ${t("title.login.page")}`}
        />
      </Helmet>

      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          backgroundColor: "white",
          mt: 10,
          py: 6,
          px: [2, 4, 6],
          borderRadius: 3,
          boxShadow: "-1px -1px 10px 1px rgba(0, 0, 0, 0.2)",
          display: "flex",
          width: ["90%", "70%", "400px"],
          flexDirection: "column",
          gap: 1,
        }}
      >
        <img
          src={Padlock}
          alt="logo"
          title="logo"
          loading="lazy"
          width={"50px"}
          height={"50px"}
          style={{
            width: "50px",
            height: "50px",
          }}
        />
        <Typography variant="h5" className="mt-3 font-bold">
          {t("login.title")}{" "}
        </Typography>
        <Typography className="text-[#a8a8a8]">
          {t("login.instruction2")}{" "}
        </Typography>
        <Typography className="mb-5 font-bold">{email}</Typography>
        <PasswordInput
          label={t("registration.passwordLabel")}
          placeholder={t("registration.passwordLabelPlaceholder")}
          error={errors.password?.message}
          {...register("password", { required: true })}
        />
        <Box>
          <Button
            onClick={() =>
              navigator("/login/new?step=forgotPassword", { state: email })
            }
            sx={{
              m: 0,
              p: 0,
              minWidth: 0,
              color: "#FF961B",
              ":hover": {
                backgroundColor: "#FFF7EB",
              },
            }}
          >
            {t("login.forgotPassword")}
          </Button>
        </Box>

        <Button
          type="submit"
          variant="contained"
          fullWidth
          disabled={!isValid}
          sx={{
            mt: 2,
            borderRadius: 6,
          }}
        >
          {t("btn.loginWithPassword")}
        </Button>
      </Box>
    </>
  );
}

export default Login;
