import React from "react";
import { Dialog, Box, Typography, Button } from "@mui/material";

const CancelConfirmModal = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <Box
        sx={{
          position: "relative",
          width: 300,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2">
          ยืนยันการยกเลิกออเดอร์
        </Typography>
        <Typography sx={{ mt: 2 }}>
          คุณแน่ใจหรือไม่ว่าต้องการยกเลิกออเดอร์นี้?
        </Typography>
        <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
          <Button variant="contained" onClick={onClose}>
            ยกเลิก
          </Button>
          <Button variant="contained" color="error" onClick={onConfirm}>
            ยืนยัน
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CancelConfirmModal;
