import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  useMediaQuery,
  SwipeableDrawer,
  InputAdornment,
} from "@mui/material";
import { Box } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import Coupon from "../../components/Coupon";

const CouponModal = ({ open, onClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const DialogComponent = isMobile ? SwipeableDrawer : Dialog;

  const [couponCode, setCouponCode] = useState("");
  const [isCouponValid, setIsCouponValid] = useState(true);
  const [coupons, setCoupons] = useState([]);

  const handleCouponValidation = () => {
    // TODO: Update this later for coupon
    const isValid = couponCode === "V@L!DCOUPON";
    setIsCouponValid(isValid);
    if (isValid) {
      setCoupons((prev) => [
        ...prev,
        {
          id: prev.length + 1,
          subtext: "สั่งขั้นต่ำ 100 บาท",
          description: "5% Cashback",
          expiry: "ใช้ได้ถึง 5 ต.ค. 2024",
        },
      ]);
    }
    setCouponCode("");
  };

  const handleClose = () => {
    setCouponCode("");
    setIsCouponValid(true);
    onClose();
  };

  const dialogProps = isMobile
    ? {
        anchor: "bottom",
        open: open,
        onClose: onClose,
        onOpen: () => {},
        disableSwipeToOpen: false,
        PaperProps: {
          style: {
            height: "100%",
          },
        },
      }
    : {
        open: open,
        onClose: onClose,
        fullWidth: true,
        maxWidth: "sm",
        sx: {
          "& .MuiDialog-paper": {
            borderRadius: "30px",
            minHeight: "50vh",
          },
        },
      };

  return (
    <DialogComponent {...dialogProps}>
      <DialogTitle>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="relative"
        >
          <Typography variant="h6" sx={{ color: "#343434", fontSize: "22px" }}>
            คูปองของฉัน
          </Typography>
          <IconButton
            disableRipple
            onClick={handleClose}
            sx={{ position: "absolute", right: 0 }}
          >
            <Typography sx={{ fontSize: "30px", color: "#F19A3C" }}>
              ×
            </Typography>
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ flexBasis: ["35%", "20%"], color: "#58AC60" }}
            >
              ใส่รหัสคูปอง
            </Typography>
            <TextField
              fullWidth
              value={couponCode}
              onChange={(e) => setCouponCode(e.target.value)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: isCouponValid ? "#58AC60" : "#FF0000",
                  },
                  "&:hover fieldset": {
                    borderColor: isCouponValid ? "#58AC60" : "#FF0000",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: isCouponValid ? "#58AC60" : "#FF0000",
                  },
                },
              }}
              variant="outlined"
              placeholder="ใส่รหัสคูปอง"
              InputProps={{
                sx: { height: "40px" },
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      size="small"
                      onClick={handleCouponValidation}
                      sx={{
                        backgroundColor: "#58AC60",
                        color: "#FFFFFF",
                        "&:hover": {
                          backgroundColor: "#45a049",
                        },
                      }}
                    >
                      ยืนยัน
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          {!isCouponValid && (
            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
              รหัสคูปองไม่ถูกต้อง
            </Typography>
          )}
        </Box>
        {coupons.length > 0 ? (
          <Box sx={{ mt: 2 }}>
            {coupons.map((coupon) => (
              <Coupon
                key={coupon.id}
                id={coupon.id}
                subtext={coupon.subtext}
                description={coupon.description}
                expiry={coupon.expiry}
                sx={{ mb: 2 }}
              />
            ))}
          </Box>
        ) : (
          <Typography
            variant="body1"
            color="text.secondary"
            align="center"
            mt={2}
          >
            ไม่มีคูปองในขณะนี้
          </Typography>
        )}
      </DialogContent>
    </DialogComponent>
  );
};

export default CouponModal;
