import { Box, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import couponIcon from "../../assets/images/couponIcon.png";
import Coupon from "../../components/Coupon";
import SwiperCore from "swiper";
import { Navigation, Scrollbar, Grid, Mousewheel } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/scrollbar";

SwiperCore.use([Navigation, Scrollbar, Grid, Mousewheel]);

function CouponSection() {
  const sliderRef = useRef(null);
  const [swiper, setSwiper] = useState(null);

  const settings = {
    onSwiper: (swiper) => setSwiper(swiper),
    navigation: false,
    className: "home-slider",
    spaceBetween: 10,
    pagination: false,
    slidesPerView: "auto",
  };

  return (
    <Box sx={{ px: 2, backgroundColor: "white" }}>
      <Box
        sx={{ display: "flex", alignItems: "center", gap: 1, width: "100%" }}
      >
        <img
          src={couponIcon}
          alt="coupon icon"
          title="coupon icon"
          width={21}
          height={14}
          loading="lazy"
        />
        <Typography
          variant="h6"
          sx={{ fontWeight: 600, fontSize: "16px", color: "#F19A3C" }}
        >
          คูปองที่ใช้ได้
        </Typography>
      </Box>

      <div className="pt-4">
        <Swiper
          ref={sliderRef}
          {...settings}
          mousewheel={{
            forceToAxis: true,
            sensitivity: 0.5,
            thresholdDelta: 10,
            releaseOnEdges: true,
          }}
          freeMode={{ enabled: true }}
        >
          <SwiperSlide>
            <Coupon />
          </SwiperSlide>
          {/* <SwiperSlide>
          <Coupon />
        </SwiperSlide>
        <SwiperSlide>
          <Coupon />
        </SwiperSlide>
        <SwiperSlide>
          <Coupon />
        </SwiperSlide>
        <SwiperSlide>
          <Coupon />
        </SwiperSlide>
        <SwiperSlide>
          <Coupon />
        </SwiperSlide> */}
        </Swiper>
      </div>
    </Box>
  );
}

export default CouponSection;
