import {
  Box,
  Card,
  CardContent,
  Collapse,
  Divider,
  Skeleton,
} from "@mui/material";

function ActiveOrderItemSkeleton() {
  return (
    <Card sx={{ marginBottom: 2, borderRadius: "15px" }}>
      <CardContent>
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
          <Skeleton variant="rounded" width={144} height={144} />
          <Box sx={{ flex: 1 }}>
            <Skeleton variant="text" width="60%" />
            <Skeleton variant="text" width="40%" />
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="50%" />
          </Box>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Skeleton variant="text" width="30%" />
          <Skeleton variant="circular" width={24} height={24} />
        </Box>
        <Collapse in={false}>
          <Box>
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="60%" />
            <Skeleton variant="text" width="40%" />
          </Box>
        </Collapse>
      </CardContent>
    </Card>
  );
}

export default ActiveOrderItemSkeleton;
