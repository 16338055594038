import React, { useState, useEffect } from "react";
import CartItem from "../../components/cart/CartItem"; // Make sure this path is correct
import {
  Card,
  CardContent,
  Typography,
  Box,
  Button,
  Radio,
  useMediaQuery,
  Divider,
  useTheme,
  Container,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { createOrder, updateOrderStatus } from "../../libs/orderAPI/orderAPI";

import CheckOutItem from "./CheckOutItem";

import { paymentData } from "./paymentData";
import useModalLoading from "../../stores/useModalLoading";
import { useAuthStore } from "../../stores/useAuthStore";
import { useGuestLogin } from "../../stores/useGuestLogin";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import { sendConversionEvent } from "../../utils/conversionTracking";
import useFetchMerchantData from "../../stores/useFetchMerchantData";
import { triggerVibration } from "../../utils/vibrationAPI";

const CheckOutPage = () => {
  const { t } = useTranslation();
  const [orderData, setOrderData] = useState({});
  const [orderType, setOrderType] = useState("DELIVERY");
  const [orders, setOrders] = useState([]);
  const navigator = useNavigate();
  const { isAuthenticated } = useAuthStore();
  const { isGuest } = useGuestLogin();
  const { merchant_id, bmerchant_id } = useParams();
  const [showDetails, setShowDetails] = useState(false);

  const [paymentType, setPaymentType] = useState("");
  const { openModal, closeModal } = useModalLoading();
  const [searchParams] = useSearchParams();
  const utmSource = searchParams.get("utm_source");
  const utmMedium = searchParams.get("utm_medium");
  const utmCampaign = searchParams.get("utm_campaign");

  const { bmerchantData, merchantData, bmerchantService, isFetch } =
    useFetchMerchantData(bmerchant_id, merchant_id);

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isMdScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));

  const fontSize = isXsScreen ? 14 : isSmScreen ? 16 : 18;
  const titleFontSize = isXsScreen ? 20 : isSmScreen ? 24 : 28;

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/merchant/${merchant_id}/${bmerchant_id}/checkout`,
      title: "CheckOutPage",
    });
  }, []);

  useEffect(() => {
    const orderDataString = localStorage.getItem(
      `checkout_data_${bmerchant_id}`
    );
    const orderData = JSON.parse(orderDataString);
    if (orderData) {
      setOrders(orderData.order_detail);
      setOrderData(orderData);
      setOrderType(orderData.order_type);
      setPaymentType(orderData.payment_method);
    } else {
      navigator("/food");
    }
  }, []);

  useEffect(() => {
    const orderFromGoogleStr = localStorage.getItem("orderFromGoogle");
    if (orderFromGoogleStr) {
      const orderFromGoogle = JSON.parse(orderFromGoogleStr);
      const now = new Date().getTime();
      if (now > orderFromGoogle.expiry) {
        localStorage.removeItem("orderFromGoogle");
      } else {
        if (
          orderFromGoogle.value &&
          orderFromGoogle.value.includes(
            `/merchant/${merchant_id}/${bmerchant_id}`
          )
        ) {
          const currentPathname = window.location.pathname;
          const url = new URL(orderFromGoogle.value);
          const utmSource = url.searchParams.get("utm_source");
          const utmMedium = url.searchParams.get("utm_medium");
          const utmCampaign = url.searchParams.get("utm_campaign");
          const orderType = orderData.order_type
            ? orderData.order_type.toLowerCase()
            : "";
          navigator(
            currentPathname +
              `?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}&order_type=${orderType}`,
            {
              replace: true,
            }
          );
        }
      }
    }
  }, [orderData]);

  const handleToggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const handleSubmit = async () => {
    triggerVibration(500);
    openModal();
    if (!paymentType) {
      closeModal();
      return null;
    } else {
      const body = {
        ...orderData,
        payment_method: paymentType,
      };
      createOrder(body)
        .then((res) => {
          const order_uuid = res.data.order_uuid;
          if (bmerchantService.auto_accept) {
            if (
              [
                "ALIPAY",
                "WECHAT_PAY",
                "SHOPEE_PAY",
                "THAI_QR",
                "THAI_QR_INET",
              ].some((method) => paymentType.includes(method))
            ) {
              updateOrderStatus(order_uuid, "MERCHANT CONFIRMED");
            } else if (paymentType === "CASH") {
              updateOrderStatus(order_uuid, "PURCHASED");
            }
          }
          navigator(
            `/order-status/${merchant_id}/${bmerchant_id}/${order_uuid}`
          );
          localStorage.removeItem(`order_merchant_${bmerchant_id}`);
          localStorage.removeItem(`merchant_${bmerchant_id}`);
          localStorage.removeItem(`checkout_data_${bmerchant_id}`);
          localStorage.removeItem("orderFromGoogle");

          const guestOrder = localStorage.getItem("guest_order")
            ? JSON.parse(localStorage.getItem("guest_order"))
            : [];

          guestOrder.push({
            order_uuid,
            merchant_id,
            bmerchant_id,
            bmerchant_uuid: bmerchantData.uuid,
          });

          localStorage.setItem("guest_order", JSON.stringify(guestOrder));
        })
        .catch((error) => {})
        .finally(async () => {
          const currentBMerchantId = bmerchant_id;
          await sendConversionEvent(currentBMerchantId);
          closeModal();
        });
    }
  };

  const totals = orders.reduce(
    (acc, item) => acc + item.quantity * item.price,
    0
  );

  const subtotal = bmerchantService.vat ? totals / 1.07 : totals;
  const vatValues = bmerchantService.vat ? totals - subtotal : 0;
  const deliveryFee =
    orderData && orderType === "DELIVERY" ? orderData.deliver_fee ?? 0 : 0; // ตัวอย่างค่าจัดส่ง
  const total = totals + deliveryFee;
  useEffect(() => {
    if (!isAuthenticated && !isGuest) {
      navigator(-1);
    }
  }, [isAuthenticated, isGuest, navigator]);
  if (!orderData) {
    navigator("/food");
  }
  return (
    <>
      <Helmet>
        <title>
          {t("pompkins")} - {t("checkout")}
        </title>
        <meta
          name="description"
          content="สั่งเดลิเวอรีผ่าน POMPKINS Food  x Order with Google ได้แล้ววันนี้"
        />
        <link rel="canonical" href={`${window.location.href}`} />
        <meta property="og:url" content="https://pompkins.com/merchant" />
      </Helmet>
      <Container maxWidth="lg" sx={{ py: { xs: 2, sm: 3, md: 4 } }}>
        <Card sx={{ borderRadius: { xs: "10px", sm: "15px" }, boxShadow: 3 }}>
          <CardContent sx={{ p: { xs: 2, sm: 3, md: 4 } }}>
            <Typography variant="h5" fontWeight={500} fontSize={titleFontSize}>
              เช็คเอาท์
            </Typography>
            <Typography variant="h6" fontWeight={"light"} fontSize={fontSize}>
              รายละเอียด
            </Typography>

            <Box sx={{ mb: 3 }}>
              <Typography
                variant="body1"
                fontSize={fontSize}
                color="#343434"
                fontWeight={500}
                gutterBottom
              >
                ชื่อร้านค้า: {merchantData.name}
              </Typography>

              <Typography
                variant="body1"
                fontSize={fontSize}
                color="#343434"
                fontWeight={500}
                gutterBottom
              >
                ชื่อผู้รับ:{" "}
                <span style={{ fontWeight: "lighter" }}>
                  {orderData.customer_name}
                </span>
              </Typography>
              <Typography
                variant="body1"
                fontSize={fontSize}
                color="#343434"
                fontWeight={500}
                gutterBottom
              >
                เบอร์ติดต่อ:{" "}
                <span style={{ fontWeight: "lighter" }}>{orderData.phone}</span>
              </Typography>

              {orderType === "DELIVERY" && (
                <>
                  <Typography
                    variant="body1"
                    fontSize={fontSize}
                    color="#343434"
                    fontWeight={500}
                    gutterBottom
                  >
                    ที่อยู่การจัดส่ง:{" "}
                    <span style={{ fontWeight: "lighter" }}>
                      {orderData.deliver_address}
                    </span>
                  </Typography>
                  {orderData.deliver_note !== "" && (
                    <Typography
                      variant="body1"
                      fontSize={fontSize}
                      color="#343434"
                      fontWeight={500}
                      gutterBottom
                    >
                      รายละเอียดให้คนขับ:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {orderData.deliver_note}
                      </span>
                    </Typography>
                  )}
                </>
              )}
              {orderType === "PICKUP" && (
                <Typography
                  variant="body1"
                  fontSize={fontSize}
                  color="#343434"
                  fontWeight={500}
                  gutterBottom
                >
                  เวลาที่รับ:{" "}
                  <span style={{ fontWeight: "lighter" }}>
                    {orderData.time_to_get}
                  </span>
                </Typography>
              )}
              {orderType === "RESERVE" && (
                <Typography
                  variant="body1"
                  fontSize={fontSize}
                  color="#343434"
                  fontWeight={500}
                  gutterBottom
                >
                  เวลาที่จอง:{" "}
                  <span style={{ fontWeight: "lighter" }}>
                    {orderData.time_to_get}
                  </span>
                </Typography>
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontSize: fontSize + 2 }}
                fontWeight="medium"
                color="#343434"
              >
                รายการอาหารที่สั่ง
              </Typography>
              <Button
                onClick={() =>
                  navigator(
                    `/merchant/${merchant_id}/${bmerchant_id}/${bmerchantData.name}`
                  )
                }
                sx={{
                  color: "#58AC60",
                  ":hover": { backgroundColor: "#FFF7EB" },
                  fontSize: fontSize - 2,
                }}
              >
                {t("cartOrder.addMenu")}
              </Button>
            </Box>

            <Box sx={{ mb: 3 }}>
              {orders.map((item, index) => (
                <CheckOutItem key={item.menu_id} item={item} index={index} />
              ))}
            </Box>

            <Divider sx={{ my: 2 }} />

            {/* ส่วนสรุป */}
            <Box sx={{ mb: 3 }}>
              {orderType === "DELIVERY" && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    my: 1,
                  }}
                >
                  <Typography fontSize={fontSize - 2}>ค่าจัดส่ง</Typography>
                  <Typography fontSize={fontSize - 2}>{`฿ ${deliveryFee
                    .toFixed(2)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`}</Typography>
                </Box>
              )}
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
              >
                <Typography sx={{ fontSize: fontSize - 2 }}>
                  {" "}
                  {}ค่าอาหาร
                </Typography>

                <Typography fontSize={fontSize - 2}>{`฿ ${subtotal.toFixed(
                  2
                )}`}</Typography>
              </Box>
              {bmerchantService.vat && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 1,
                  }}
                >
                  <Typography fontSize={fontSize - 2}>VAT7%</Typography>
                  <Typography fontSize={fontSize - 2}>{`฿ ${vatValues
                    .toFixed(2)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`}</Typography>
                </Box>
              )}
            </Box>

            {/* {bmerchantService.vat && ( */}

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontSize: fontSize, fontWeight: "lighter" }}
              >
                เลือกชำระโดย
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <img
                  src={
                    paymentData.find((p) => p.paymentType === paymentType)
                      ?.images
                  }
                  width={isXsScreen ? "24px" : "28px"}
                  height={isXsScreen ? "24px" : "28px"}
                  alt="payment"
                  loading="lazy"
                />
                <Typography fontSize={fontSize - 2}>
                  {
                    paymentData.find((p) => p.paymentType === paymentType)
                      ?.title
                  }
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: 3,
                color: "#58AC60",
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontSize: fontSize + 4, fontWeight: "medium" }}
              >
                ทั้งหมด
              </Typography>
              <Typography sx={{ fontSize: fontSize + 4, fontWeight: "medium" }}>
                {`฿ ${total
                  .toFixed(2)
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`}
              </Typography>
            </Box>

            <Button
              variant="contained"
              color="primary"
              disabled={paymentType === ""}
              fullWidth
              sx={{
                p: { xs: "10px", sm: "12px" },
                borderRadius: "2rem",
                fontSize: { xs: "16px", sm: "18px" },
              }}
              onClick={handleSubmit}
            >
              ยืนยัน
            </Button>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default CheckOutPage;
