import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import TextHeader from "../../components/TextHeader";
import SubSectionText from "../../components/SubSectionText";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";

function TermsPage() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/terms-and-conditions",
      title: "TermsPage",
    });
  }, []);
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "white",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box sx={{ p: 2, px: [4, 4, 14] }}>
        <TextHeader text={t("term.title")} sx={{}} />
        <TextHeader
          text={t("term.revisedEdition")}
          sx={{ fontSize: ["16px", "20px"], mb: 2 }}
        />
        <Typography sx={{ textIndent: "3em", mb: 2 }}>
          {t("term.desc")}{" "}
          <a
            target="_blank"
            rel="noreferrer"
            className="text-blue-500 underline"
            href="https://pompkins.com"
            style={{ borderBottom: "none" }}
          >
            https://pompkins.com
          </a>{" "}
          {t("term.desc2")}
        </Typography>
        <Typography fontWeight={700} gutterBottom>
          {t("term.1")}
        </Typography>
        <SubSectionText text={t("term.1_1")} number={"1.1"} />
        <SubSectionText number={"1.2"} text={t("term.1_2")} />
        <SubSectionText number={"1.3"} text={t("term.1_3")} />
        <Typography fontWeight={700} mt={2} gutterBottom>
          {t("term.2")}
        </Typography>
        <Typography>{t("term.2Desc")}</Typography>

        <SubSectionText number={"2.1"} text={t("term.2_1")} />
        <SubSectionText number={"2.2"} text={t("term.2_2")} />
        <SubSectionText number={"2.3"} text={t("term.2_3")} />

        <Typography fontWeight={700} mt={2} gutterBottom>
          {t("term.3")}{" "}
        </Typography>

        <SubSectionText number={"3.1"} text={t("term.3_1")} />
        <SubSectionText number={"3.2"} text={t("term.3_2")} />
        <SubSectionText number={"3.3"} text={t("term.3_3")} />
        <SubSectionText number={"3.4"} text={t("term.3_4")} />
        <SubSectionText number={"3.5"} text={t("term.3_5")} />
        <SubSectionText number={"3.6"} text={t("term.3_6")} />
        <SubSectionText number={"3.7"} text={t("term.3_7")} />
        <SubSectionText number={"3.8"} text={t("term.3_8")} />
        <Typography fontWeight={700} mt={2} gutterBottom>
          {t("term.4")}{" "}
        </Typography>
        <Typography>{t("term.4Desc")}</Typography>
        <SubSectionText number={"1."} text={t("term.4_1")} />
        <SubSectionText number={"2."} text={t("term.4_2")} />
        <Typography fontWeight={700} mt={2} gutterBottom>
          {t("term.5")}
        </Typography>
        <SubSectionText number={"5.1"} text={t("term.5_1")} />
        <SubSectionText number={"5.2"} text={t("term.5_2")} />
        <SubSectionText number={"5.3"} text={t("term.5_3")} />
        <SubSectionText
          number={"5.3.1"}
          text={t("term.5_3_1")}
          sx={{ ml: 6 }}
        />
        <SubSectionText
          number={"5.3.2"}
          text={t("term.5_3_2")}
          sx={{ ml: 6 }}
        />
        <SubSectionText
          number={"5.3.3"}
          text={t("term.5_3_3")}
          sx={{ ml: 6 }}
        />
        <SubSectionText
          number={"5.3.4"}
          text={t("term.5_3_4")}
          sx={{ ml: 6 }}
        />
        <SubSectionText number={"5.4"} text={t("term.5_4")} />
        <SubSectionText number={"5.5"} text={t("term.5_5")} />
        <SubSectionText number={"5.6"} text={t("term.5_6")} />
        <SubSectionText
          number={"5.6.1"}
          text={t("term.5_6_1")}
          sx={{ ml: 6 }}
        />
        <SubSectionText
          number={"5.6.2"}
          text={t("term.5_6_2")}
          sx={{ ml: 6 }}
        />
        <SubSectionText
          number={"5.6.3"}
          text={t("term.5_6_3")}
          sx={{ ml: 6 }}
        />
        <SubSectionText number={"5.7"} text={t("term.5_7")} />
        <SubSectionText
          number={"5.7.1"}
          text={t("term.5_7_1")}
          sx={{ ml: 6 }}
        />
        <SubSectionText
          number={"5.7.2"}
          text={t("term.5_7_2")}
          sx={{ ml: 6 }}
        />
        <SubSectionText number={"5.8"} text={t("term.5_8")} />
        <SubSectionText
          number={"5.8.1"}
          text={t("term.5_8_1")}
          sx={{ ml: 6 }}
        />
        <SubSectionText
          number={"5.8.2"}
          text={t("term.5_8_2")}
          sx={{ ml: 6 }}
        />
        <SubSectionText
          number={"5.8.3"}
          text={t("term.5_8_3")}
          sx={{ ml: 6 }}
        />
        <SubSectionText
          number={"(1)"}
          text={t("term.5_8_3_1")}
          sx={{ ml: 12 }}
        />
        <SubSectionText
          number={"(2)"}
          text={t("term.5_8_3_2")}
          sx={{ ml: 12 }}
        />
        <SubSectionText
          number={"(3)"}
          text={t("term.5_8_3_3")}
          sx={{ ml: 12 }}
        />
        <Typography fontWeight={700} mt={2} gutterBottom>
          {t("term.6")}{" "}
        </Typography>
        <Typography sx={{ textIndent: "3em" }}>{t("term.6Desc")}</Typography>
        <Typography sx={{ textIndent: "3em", mt: 1 }}>
          {t("term.6Desc_2")}
        </Typography>
        <Typography fontWeight={700} mt={2} gutterBottom>
          {t("term.7")}{" "}
        </Typography>
        <SubSectionText number={"7.1"} text={t("term.7_1")} />
        <SubSectionText number={"7.2"} text={t("term.7_2")} />
        <SubSectionText
          number={"7.2.1"}
          text={t("term.7_2_1")}
          sx={{ ml: 6 }}
        />
        <SubSectionText
          number={"7.2.2"}
          text={t("term.7_2_2")}
          sx={{ ml: 6 }}
        />
        <SubSectionText
          number={"7.2.3"}
          text={t("term.7_2_3")}
          sx={{ ml: 6 }}
        />
        <SubSectionText
          number={"7.2.4"}
          text={t("term.7_2_4")}
          sx={{ ml: 6 }}
        />
        <SubSectionText number={"7.3"} text={t("term.7_3")} />
        <Typography fontWeight={700} mt={2} gutterBottom>
          {t("term.8")}
        </Typography>
        <SubSectionText number={"8.1"} text={t("term.8_1")} />
        <SubSectionText number={"8.2"} text={t("term.8_2")} />
        <SubSectionText
          number={"8.2.1"}
          text={t("term.8_2_1")}
          sx={{ ml: 6 }}
        />
        <SubSectionText
          number={"8.2.2"}
          text={t("term.8_2_2")}
          sx={{ ml: 6 }}
        />
        <SubSectionText
          number={"8.2.3"}
          text={t("term.8_2_3")}
          sx={{ ml: 6 }}
        />
        <SubSectionText
          number={"8.2.4"}
          text={t("term.8_2_4")}
          sx={{ ml: 6 }}
        />
        <SubSectionText
          number={"8.2.5"}
          text={t("term.8_2_5")}
          sx={{ ml: 6 }}
        />
        <SubSectionText
          number={"8.2.6"}
          text={t("term.8_2_6")}
          sx={{ ml: 6 }}
        />
        <SubSectionText
          number={"8.2.7"}
          text={t("term.8_2_7")}
          sx={{ ml: 6 }}
        />
        <SubSectionText
          number={"8.2.8"}
          text={t("term.8_2_8")}
          sx={{ ml: 6 }}
        />
        <Typography fontWeight={700} mt={2} gutterBottom>
          {t("term.9")}{" "}
        </Typography>
        <SubSectionText number={"9.1"} text={t("term.9_1")} />
        <SubSectionText number={"9.2"} text={t("term.9_2")} />
        <SubSectionText number={"9.3"} text={t("term.9_3")} />
        <SubSectionText number={"9.4"} text={t("term.9_4")} />
        <Typography fontWeight={700} mt={2} gutterBottom>
          {t("term.10")}{" "}
        </Typography>
        <Typography sx={{ textIndent: "3em", mb: 1 }}>
          {t("term.10Desc")}
        </Typography>
        <Typography sx={{ textIndent: "3em", mb: 1 }}>
          {t("term.10Desc2")}
        </Typography>
        <Typography sx={{ textIndent: "3em", mb: 1 }}>
          {t("term.10Desc3")}
        </Typography>
        <Typography sx={{ textIndent: "3em", mb: 1 }}>
          {t("term.10Desc4")}
        </Typography>
      </Box>
    </Box>
  );
}

export default TermsPage;
