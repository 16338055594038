import { useState, useEffect } from "react";
import {
  getOrders,
  getOrders_history,
  getOrdersByOrders_uuid,
  getOrderV2,
} from "../libs/customerAPI/getOrders";
import { useAuthStore } from "./useAuthStore";
import { useGuestLogin } from "./useGuestLogin";

export const useOrders = (page = 1) => {
  const { user } = useAuthStore();
  const { isGuest } = useGuestLogin();
  const [orders, setOrders] = useState([]);
  const [ordersHistory, setOrdersHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchOrders = async () => {
      setIsLoading(true); // Start loading
      try {
        if (!user) {
          setIsLoading(false);
          return;
        }
        const data = {
          customer_uuid: user?.uuid,
          active: true,
          page: 1,
          size: 100,
        };
        const response = await getOrderV2(data);
        setOrders(response.data);
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
        setIsLoading(false); // End loading
      }
    };

    const fetchGuestOrders = async () => {
      setIsLoading(true); // Start loading
      const guestOrderList = localStorage.getItem("guest_order");
      if (guestOrderList) {
        const guestOrders = JSON.parse(guestOrderList);
        try {
          const orders = await Promise.all(
            guestOrders.map(async (order) => {
              const response = await getOrdersByOrders_uuid(order.order_uuid);
              return {
                ...response.data,
                bmerchant_id: order.bmerchant_id,
                bmerchant_uuid: order.bmerchant_uuid,
                merchant_id: order.merchant_id,
              };
            })
          );
          const filteredOrders = orders.filter(
            (order) =>
              order.order_status === "SUCCESS" ||
              order.order_status === "CANCELLED"
          );
          const remainingOrders = orders.filter(
            (order) =>
              order.order_status !== "SUCCESS" &&
              order.order_status !== "CANCELLED"
          );
          if (filteredOrders.length > 0) {
            setOrdersHistory(filteredOrders);
          }

          setOrders(remainingOrders);
        } catch (error) {
          console.error("Error fetching guest orders:", error);
        } finally {
          setIsLoading(false); // End loading
        }
      } else {
        setIsLoading(false); // End loading if no guest orders
      }
    };

    if (!isGuest) {
      fetchOrders();
    } else {
      fetchGuestOrders();
    }
  }, [isGuest, user, user?.uuid]);

  useEffect(() => {
    const fetchOrdersHistory = async () => {
      setIsLoading(true); // Start loading
      try {
        if (!user) {
          setIsLoading(false);
          return;
        }
        if (isGuest) {
          setIsLoading(false);
          return;
        }
        const data = {
          customer_uuid: user?.uuid,
          active: false,
          page: page,
          size: 5,
        };
        const response = await getOrderV2(data);
        setOrdersHistory(response.data);
      } catch (error) {
        console.error("Error fetching orders history:", error);
      } finally {
        setIsLoading(false); // End loading
      }
    };
    fetchOrdersHistory();
  }, [user?.uuid, page, user, isGuest]);

  return { orders, ordersHistory, isLoading };
};
