import { Box, Paper } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import DeliveryStatusCard from "./DeliveryStatusCard";
import PickupStatusCard from "./PickupStatusCard";
import { useNavigate, useParams } from "react-router-dom";
import {
  getOrdersByOrders_uuid,
  updateOrdersStatus,
} from "../../libs/customerAPI/getOrders";
import LoadingScreen from "../../components/LoadingScreen";
import OrderCancelled from "./OrderCancelled";
import OrderSuccess from "./OrderSuccess";
import ReserveStatusCard from "./ReserveStatusCard";
import {
  getBMerchantById,
  getMerchantById,
} from "../../libs/merchantAPI/merchantAPI";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { getLineUUID, isFriendship } from "../../utils/line/checkLine";
import { sendLine } from "../../libs/lineAPI";
import { sendLineMessage } from "../../utils/line/sendLineMessage";
import dayjs from "dayjs";
import ModalAddFriend from "../../components/ModalAddFriend";
import liff from "@line/liff";
import useModalLoading from "../../stores/useModalLoading";

function OrderStatusPage() {
  const { t } = useTranslation();
  const { order_uuid, merchant_id, bmerchant_id, transaction_uuid } =
    useParams();
  const [orderData, setOrderData] = useState(null);
  const [orderStatus, setOrderStatus] = useState("");
  const [cancel, setCancel] = useState(false);
  const [bmerchant, setBmerchant] = useState({});
  const [merchant, setMerchant] = useState({});
  const navigator = useNavigate();
  const prevOrderStatusRef = useRef();
  const prevCookingStatusRef = useRef();
  const [showModal, setShowModal] = useState(false);
  const [isLiffInitialized, setIsLiffInitialized] = useState(false);
  const { openModal, closeModal } = useModalLoading();

  const fetchOrderData = useCallback(() => {
    getOrdersByOrders_uuid(order_uuid)
      .then((res) => {
        setOrderData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [order_uuid]);

  // useEffect(() => {
  //   const initializeLiff = async () => {
  //     try {
  //       console.log("Initializing LIFF", process.env.REACT_APP_LIFF_ID);
  //       await liff.init({ liffId: process.env.REACT_APP_LIFF_ID });
  //       setIsLiffInitialized(true);
  //     } catch (error) {
  //       console.error("LIFF initialization failed", error);
  //     }
  //   };
  //   initializeLiff();
  // }, []);

  // useEffect(() => {
  //   const checkFriendshipStatus = async () => {
  //     const lineUUID = getLineUUID();
  //     if (!lineUUID || !isLiffInitialized) return;
  //     const { friendFlag } = await liff.getFriendship();
  //     if (!friendFlag) {
  //       setShowModal(true);
  //     }
  //   };
  //   checkFriendshipStatus();
  // }, [isLiffInitialized]);

  useEffect(() => {
    const checkFriendshipStatus = async () => {
      const lineUUID = getLineUUID();
      if (!lineUUID) return;
      const accessToken = localStorage.getItem("accessToken");
      // if (!isLiffInitialized) return;
      // const { friendFlag } = await liff.getFriendship();
      const friendFlag = await isFriendship(accessToken);
      if (!friendFlag) {
        setShowModal(true);
      }
    };

    checkFriendshipStatus();
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const handleOrderStatusChange = async () => {
      const lineUUID = getLineUUID();
      if (!orderData || !lineUUID) return;

      const prevOrderStatus = prevOrderStatusRef.current;
      const prevCookingStatus = prevCookingStatusRef.current;
      if (
        orderData.order_status === prevOrderStatus &&
        orderData.cooking_status === prevCookingStatus
      )
        return;

      try {
        const accessToken = localStorage.getItem("accessToken");
        const friendFlag = await isFriendship(accessToken);
        // const { friendFlag } = await liff.getFriendship();
        const webURL = process.env.REACT_APP_BASE_WEB_URL;
        const orderStatusKey = `orderStatus_${order_uuid}`;
        const storedStatus = localStorage.getItem(orderStatusKey);
        const currentStatus = `${orderData.order_status}_${orderData.cooking_status}`;

        if (storedStatus === currentStatus) return;

        if (friendFlag && orderData) {
          const sendMessage = (statusMessage) => {
            sendLineMessage(
              lineUUID,
              orderData,
              merchant,
              statusMessage,
              "ติดตามสถานะสั่งซื้อ",
              `${webURL}/order-status/${merchant_id}/${bmerchant_id}/${order_uuid}`
            );
            localStorage.setItem(orderStatusKey, currentStatus);
          };
          if (
            orderData.cooking_status === "SUCCESS" &&
            orderData.order_status === "SUCCESS"
          ) {
            localStorage.removeItem(orderStatusKey);
            return;
          }
          if (
            orderData.order_status === "PURCHASED" &&
            orderData.payment_method === "CASH" &&
            orderData.cooking_status === "PENDING"
          ) {
            sendMessage(t("orderStatus.inKitchen"));
          } else if (orderData.order_status === "CANCELLED") {
            sendMessage(t("orderStatus.orderCancel"));
            localStorage.removeItem(orderStatusKey);
          } else if (orderData.cooking_status === "SUCCESS") {
            sendMessage(t("orderStatus.readyForPickup"));
          } else {
            sendMessage(t("orderStatus.orderPlaced"));
          }
        }
      } catch (error) {
        console.error("Error handling order status change:", error);
      }
      prevOrderStatusRef.current = orderData.order_status;
      prevCookingStatusRef.current = orderData.cooking_status;
    };

    handleOrderStatusChange();
  }, [
    orderData,
    merchant_id,
    bmerchant_id,
    order_uuid,
    isLiffInitialized,
    merchant,
    t,
  ]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/order-status/${merchant_id}/${bmerchant_id}/${order_uuid}`,
      title: "OrderStatusPage",
    });
  }, []);
  useEffect(() => {
    getMerchantById(merchant_id).then((res) => {
      setMerchant(res.data);
    });
    getBMerchantById(bmerchant_id).then((res) => {
      setBmerchant(res.data);
    });
  }, []);
  useEffect(() => {
    fetchOrderData();

    const fetchInterval = setInterval(() => {
      fetchOrderData();
    }, 10 * 500);

    return () => clearInterval(fetchInterval);
  }, [fetchOrderData]);

  // useEffect(() => {
  //   updateOrderPaymentStatus(order_uuid, "PAID");
  // }, []);
  useEffect(() => {
    if (orderData) {
      setOrderStatus(orderData.order_status);
    }
  }, [orderData]);
  const handleCancel = () => {
    const status = {
      status: "CANCELLED",
      transaction_uuid: transaction_uuid,
    };
    updateOrdersStatus(order_uuid, status);
    setCancel(true);
    closeModal();
  };

  const renderCard = () => {
    if (!orderData) {
      return <LoadingScreen />;
    }

    const orderType = orderData.order_type.toLowerCase();
    return orderStatus === "MERCHANT CONFIRMED" && orderType === "reserve" ? (
      <OrderSuccess orderType={orderType} />
    ) : orderStatus === "CANCELLED" || cancel ? (
      <OrderCancelled
        orderType={orderType}
        contract={bmerchant.contract_number}
      />
    ) : orderType === "pickup" ? (
      <PickupStatusCard
        orderData={orderData}
        handleCancel={handleCancel}
        merchantName={merchant.name}
        contract={bmerchant.contract_number}
      />
    ) : orderType === "delivery" ? (
      <DeliveryStatusCard
        orderData={orderData}
        handleCancel={handleCancel}
        merchantName={merchant.name}
        contract={bmerchant.contract_number}
      />
    ) : orderType === "reserve" ? (
      <ReserveStatusCard
        orderData={orderData}
        handleCancel={handleCancel}
        merchantName={merchant.name}
        contract={bmerchant.contract_number}
      />
    ) : orderStatus === "SUCCESS" ? (
      // navigator(`/review/${orderData.merchant_uuid}/${orderData.order_uuid}`
      navigator(`/order/bill/${merchant_id}/${orderData.order_uuid}`)
    ) : (
      <Box>No status found.</Box>
    );
  };

  // console.log(useTimer(order_uuid));
  return (
    <>
      <Helmet>
        <title>
          {t("pompkins")} - {t("order.status")}
        </title>
        <meta
          name="description"
          content="สั่งเดลิเวอรีผ่าน POMPKINS Food  x Order with Google ได้แล้ววันนี้"
        />
        <link rel="canonical" href={`${window.location.href}`} />
        <meta property="og:url" content="https://pompkins.com/order-status" />
      </Helmet>
      {/* <ModalAddFriend open={showModal} handleClose={handleCloseModal} /> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "95vh",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            padding: 2,
            margin: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            maxWidth: "600px",
          }}
        >
          {renderCard()}
        </Paper>
      </Box>
    </>
  );
}

export default OrderStatusPage;
