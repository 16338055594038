import React, { useCallback, useEffect, useState, createRef } from "react";
import { Box, IconButton, Toolbar, AppBar, Badge, Fab } from "@mui/material";
import notFoundImg from "../../assets/images/emo_pomkins_dizzy320.png";
import ShoppingBag from "../../assets/images/ShoppingBag.png";

import {
  getBMerchantById,
  getMenuByBMerchantId,
  getMerchantById,
  getCategoryByBMerchantId,
  getRatingByMerchantUUID,
  getServiceBMerchant,
} from "../../libs/merchantAPI/merchantAPI";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useMobile } from "../../stores/useMobile";
import MenuShopCategoryTab from "../../components/MenuShopCategoryTab";
import { useAuthStore } from "../../stores/useAuthStore";
import ReviewModal from "./ReviewModal";
import { isMerchantOpen } from "../../libs/isMerchantOpen";
import MoreInfoModal from "./MoreInfoModal";
import { useLocationStore } from "../../stores/useLocationStore";
import { DistanceCalculator } from "../../utils/calDistance";
import { useGuestLogin } from "../../stores/useGuestLogin";
import useSnackBar from "../../stores/useSnackBar";
import { putFavorite } from "../../libs/customerAPI/favoriteAPI";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import { persistConversionData } from "../../utils/conversionTracking";
import MenuSection from "../../components/MenuSection";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MerchantHeader from "./MerchantHeader";
import MerchantMetaTags from "./MerchantMetaTags";
import MerchantModals from "./MerchantModals";
import SearchMenuResults from "../../components/SearchMenuResult";
import CouponSection from "./CouponSection";
import { filterMenu } from "../../utils/filterMenu";
import GoogleOneTap from "../../components/GoogleOneTap";

function Merchant() {
  const version = new Date().toISOString().split("T")[0];
  const { isMobile } = useMobile();
  const {
    currentLocation,
    isLoadScript,
    fetchCurrentLocation,
    openModal,
    currentAddress,
  } = useLocationStore();
  const { isGuest, openGuestModal } = useGuestLogin();
  const location = useLocation();
  const [isAddMenuOpen, setIsAddMenuOpen] = useState(false);
  const { user, isAuthenticated, setLocationFrom, updateUser } = useAuthStore();
  const [merchantData, setMerchantData] = useState();
  const [bMerchantData, setBMerchantData] = useState();
  const [bMerchantMenu, setBMerchantMenu] = useState([]);
  const [bMerchantCategory, setBMerchantCategory] = useState([]);
  const [displayMenu, setDisplayMenu] = useState([]);
  const [isFetch, setIsFetch] = useState(false);
  const [cartList, setCartList] = React.useState([]);
  const [isMerchantOpenState, setIsMerchantOpenState] = useState(false);
  const [openCloseModal, setOpenCloseModal] = useState(true);
  const [isVerified, setIsVerified] = useState(false);
  const [bMerchantService, setBMerchantService] = useState({});
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isMoreInfoOpen, setIsMoreInfoOpen] = useState(false);
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [uuidProps, setUuidProps] = useState("");
  const { merchant_id, bmerchant_id, merchant_name } = useParams();
  const [distance, setDistance] = useState();
  const [rating, setRating] = useState(0);
  const [isFavorite, setIsFavorite] = useState(false);
  const { openSnack } = useSnackBar();
  const { t } = useTranslation();
  const [openPriceError, setOpenPriceError] = useState(false);
  const [openNoPayment, setOpenNoPayment] = useState(true);
  const [openNoService, setOpenNoService] = useState(true);
  const navigator = useNavigate();
  const [searchParams] = useSearchParams();
  const utmSource = searchParams.get("utm_source");
  const utmMedium = searchParams.get("utm_medium");
  const utmCampaign = searchParams.get("utm_campaign");
  const orderType = searchParams.get("order_type");
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const [filteredMenu, setFilteredMenu] = useState(undefined);
  const [resultCount, setResultCount] = useState(0);

  const handleFilterMenu = useCallback(() => {
    const newFilteredMenu = filterMenu(searchValue, displayMenu);
    setFilteredMenu(newFilteredMenu);
    setResultCount(
      newFilteredMenu.reduce((acc, item) => acc + item.menu.length, 0)
    );
  }, [searchValue, displayMenu]);

  useEffect(() => {
    handleFilterMenu();
  }, [searchValue, displayMenu, handleFilterMenu]);

  useEffect(() => {
    const orderFromGoogleStr = localStorage.getItem("orderFromGoogle");
    if (orderFromGoogleStr) {
      const orderFromGoogle = JSON.parse(orderFromGoogleStr);
      const now = new Date().getTime();
      if (now > orderFromGoogle.expiry) {
        localStorage.removeItem("orderFromGoogle");
      } else {
        if (
          orderFromGoogle.value &&
          orderFromGoogle.value.includes(
            `/merchant/${merchant_id}/${bmerchant_id}`
          )
        ) {
          const url = new URL(orderFromGoogle.value);
          navigator(url.pathname + url.search, { replace: true });
        }
      }
    }
    if (merchant_name && merchant_name.includes(" ")) {
      const merchantNameFormat = merchant_name.replace(/ /g, "-");
      let url;
      if (utmSource && utmMedium && utmCampaign && orderType) {
        url = `/merchant/${merchant_id}/${bmerchant_id}/${merchantNameFormat}?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}&order_type=${orderType}`;
      } else {
        url = `/merchant/${merchant_id}/${bmerchant_id}/${merchantNameFormat}`;
      }
      navigator(url, { replace: true });
    }
  }, [
    merchant_name,
    bmerchant_id,
    merchant_id,
    navigator,
    utmSource,
    utmMedium,
    utmCampaign,
    orderType,
  ]);

  useEffect(() => {
    const orderFromGoogleStr = localStorage.getItem("orderFromGoogle");
    if (orderFromGoogleStr) {
      const orderFromGoogle = JSON.parse(orderFromGoogleStr);
      const url = new URL(orderFromGoogle.value);
      const orderFromGooglePathname = url.pathname;
      const currentPathname = window.location.pathname;
      if (
        orderFromGooglePathname &&
        orderFromGooglePathname !== currentPathname
      ) {
        if (utmSource && utmMedium && utmCampaign) {
          const currentUrl = window.location.href;
          const expirationTime = new Date().getTime() + 1000 * 60 * 60 * 24; // 24 hours
          const data = {
            value: currentUrl,
            expiry: expirationTime,
          };
          localStorage.setItem("orderFromGoogle", JSON.stringify(data));
        }
      }
    } else {
      if (utmSource && utmMedium && utmCampaign) {
        const currentUrl = window.location.href;
        const expirationTime = new Date().getTime() + 1000 * 60 * 60 * 24; // 24 hours
        const data = {
          value: currentUrl,
          expiry: expirationTime,
        };
        localStorage.setItem("orderFromGoogle", JSON.stringify(data));
      }
    }
  }, [utmSource, utmMedium, utmCampaign]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const rwg_token = params.get("rwg_token");
    if (rwg_token && bmerchant_id) {
      persistConversionData(rwg_token, bmerchant_id);
    }
  }, [bmerchant_id]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/merchant/${merchant_id}/${bmerchant_id}/${merchant_name}`,
      title: "Merchant",
    });
  }, []);

  useEffect(() => {
    if (isAuthenticated && user.favorite) {
      if (user.favorite.includes(parseInt(bmerchant_id))) {
        setIsFavorite(true);
      } else {
        setIsFavorite(false);
      }
    }
  }, [isAuthenticated, bmerchant_id, user]);

  useEffect(() => {
    const cartDataString = localStorage.getItem(`merchant_${bmerchant_id}`);
    let cartData = cartDataString ? JSON.parse(cartDataString) : {};
    if (cartData.cartList) {
      setTotalQuantity(cartData.totalQuantity);
      setTotalPrice(cartData.totalPrice);
      setCartList(cartData.cartList);
    }
  }, [bmerchant_id]);

  useEffect(() => {
    const data = {
      uuidProps: uuidProps,
      totalPrice: totalPrice,
      totalQuantity: totalQuantity,
      cartList: cartList,
      distance: distance,
    };

    if (cartList.length > 0) {
      localStorage.setItem(`merchant_${bmerchant_id}`, JSON.stringify(data));
    }
    if (cartList.length === 0) {
      localStorage.removeItem(`merchant_${bmerchant_id}`);
    }
  }, [bmerchant_id, cartList, distance, totalPrice, totalQuantity, uuidProps]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      if (scrollTop > 300) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleFavClick = () => {
    if (isAuthenticated) {
      let updateFavorite = [];

      if (isFavorite) {
        updateFavorite = user.favorite.filter(
          (favId) => favId !== parseInt(bmerchant_id)
        );

        openSnack(t("snack.unFav"));
      } else {
        updateFavorite = [...user.favorite, parseInt(bmerchant_id)];
        openSnack(t("snack.fav"));
      }
      updateUser({ ...user, favorite: updateFavorite });
      putFavorite(updateFavorite, user.uuid);
      setIsFavorite(!isFavorite);
    } else {
      setLocationFrom(location.pathname);
      navigator("/login/new");
    }
  };

  const handleQuantityChange = (id, delta) => {
    const updatedCartList = [...cartList];
    const itemToUpdateIndex = updatedCartList.findIndex(
      (itm) => itm.menu.id === id
    );

    if (itemToUpdateIndex !== -1) {
      const itemToUpdate = updatedCartList[itemToUpdateIndex];
      const newQuantity = (itemToUpdate.quantity || 0) + delta;

      if (newQuantity === 0) {
        updatedCartList.splice(itemToUpdateIndex, 1);
      } else if (newQuantity > 0) {
        updatedCartList[itemToUpdateIndex].quantity = newQuantity;
      }

      setCartList(updatedCartList);
    }
  };

  const calculateTotals = useCallback(() => {
    let totalQuantity = 0;
    let totalPrice = 0;

    cartList.forEach((item) => {
      const quantity = parseInt(item.quantity, 10) || 0;
      const price = parseFloat(item.price, 10) || 0;

      totalQuantity += quantity;
      totalPrice += price;
    });
    setTotalQuantity(totalQuantity);
    setTotalPrice(totalPrice);
  }, [cartList]);

  useEffect(() => {
    calculateTotals();
  }, [calculateTotals]);

  useEffect(() => {
    if (!currentLocation) {
      fetchCurrentLocation();
    }
  }, [currentLocation, fetchCurrentLocation]);

  const [merchantLocation, setMerchantLocation] = useState({});
  const fetchMerchant = useCallback(() => {
    setIsLoading(true);
    getMerchantById(merchant_id)
      .then((res1) => {
        setMerchantData(res1.data);
        getBMerchantById(bmerchant_id)
          .then((res2) => {
            setIsVerified(res2.data.verified);
            getServiceBMerchant(res2.data.uuid).then(({ data }) => {
              setBMerchantService(data);
            });
            setBMerchantData(res2.data);
            let timeTemp = isMerchantOpen(
              res2.data.open_time,
              res2.data.close_time
            );
            setIsMerchantOpenState(timeTemp);
            const location = {
              lat: parseFloat(res2.data.latitude),
              lng: parseFloat(res2.data.longitude),
            };
            getRatingByMerchantUUID(res2.data.uuid).then((res) => {
              setRating(res.data.average_rating);
            });
            setMerchantLocation(location);
            getMenuByBMerchantId(res2.data.uuid)
              .then((res3) => {
                setBMerchantMenu(res3.data);
                getCategoryByBMerchantId(res2.data.uuid)
                  .then((res4) => {
                    setBMerchantCategory(res4.data);
                    orderCategory(res3.data, res4.data);
                    setIsLoading(false);
                  })
                  .catch((error) => {
                    console.log(error);
                  })
                  .finally(() => {
                    setIsFetch(true);
                  });
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const orderCategory = (menus, categories) => {
    let menuTemp = [];
    categories.forEach((category) => {
      let filteredMenu = menus.filter((menu) => {
        return menu.categories_id.includes(category.id);
      });
      menuTemp.push({ category: category, menu: filteredMenu });
    });
    setDisplayMenu(menuTemp);
  };

  useEffect(() => {
    if (!isFetch) {
      fetchMerchant();
    }
  }, [fetchMerchant, isFetch]);

  const [isLoading, setIsLoading] = React.useState(false);

  const scrollCategoryRefs = displayMenu.map(() => createRef());

  const scrollToCategory = (index) => {
    if (scrollCategoryRefs[index] && scrollCategoryRefs[index].current) {
      const yOffset = isMobile ? -130 : -120;
      const element = scrollCategoryRefs[index].current;
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (cartList !== null && cartList.length > 0) {
      setIsAddMenuOpen(true);
    }
  }, [cartList]);

  useEffect(() => {
    if (bMerchantData) {
      setUuidProps(bMerchantData.uuid);
    }
  }, [bMerchantData]);

  const handleGoToCartPage = () => {
    const checkPrice = cartList.reduce(
      (acc, { price, quantity }) => acc + price * quantity,
      0
    );
    if (checkPrice < 1) {
      setOpenPriceError(true);
      return;
    }
    if (
      !currentAddress ||
      currentAddress === null ||
      currentAddress === "" ||
      distance === null ||
      distance === undefined
    ) {
      openModal();
      return;
    }

    if (isAuthenticated || isGuest) {
      let sendProps = {
        order_detail: cartList,
        merchant_name: bMerchantData.name,
        merchant_uuid: bMerchantData.uuid,
        customer_uuid: user?.uuid ?? "",
        distance: distance[0],
      };
      localStorage.setItem(
        `order_merchant_${bmerchant_id}`,
        JSON.stringify(sendProps)
      );
      if (utmSource && utmMedium && utmCampaign && orderType) {
        navigator(
          `/merchant/${merchant_id}/${bmerchant_id}/cartorder?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}&order_type=${orderType}`
        );
      } else {
        navigator(`/merchant/${merchant_id}/${bmerchant_id}/cartorder`);
      }
    } else {
      if (!currentAddress) {
        openModal();
        return;
      }
      openGuestModal();
    }
  };

  useEffect(() => {
    if (currentLocation && isLoadScript) {
      const calculateDistance = async () => {
        try {
          const { distance, time } = await DistanceCalculator(
            currentLocation,
            merchantLocation
          );
          setDistance(distance);
        } catch (error) {
          console.error("Error calculating distance:", error);
        }
      };
      calculateDistance();
    }
  }, [currentLocation, isLoadScript, merchantLocation]);
  if (!merchant_id || merchant_id === "undefined") {
    return <Navigate to="/food" />;
  }

  // if (!isAuthenticated) {
  //   navigator("/login/new");
  //   setLocationFrom(location.pathname);
  // }

  const helmetKeyword = `POMPKINS Food, Order with Google, ${merchant_name}, online food delivery, ${merchant_name} menu, ${merchant_name} reviews, best food in ${
    merchantData?.company_location ?? merchant_name
  }, ${merchant_name} delivery, พ้อมป์กินส์ ฟู้ด, สั่งอาหารออนไลน์, เมนู ${merchant_name}, รีวิว ${merchant_name}, อาหารที่ดีที่สุดใน ${
    merchantData?.company_location ?? merchant_name
  }, จัดส่ง ${merchant_name}`;

  return (
    <>
      <GoogleOneTap />
      <MerchantMetaTags
        merchant_name={merchant_name}
        merchantData={merchantData}
        version={version}
      />
      <MerchantModals
        isFetch={isFetch}
        bMerchantMenu={bMerchantMenu}
        isMerchantOpenState={isMerchantOpenState}
        openCloseModal={openCloseModal}
        setOpenCloseModal={setOpenCloseModal}
        openPriceError={openPriceError}
        setOpenPriceError={setOpenPriceError}
        isMoreInfoOpen={isMoreInfoOpen}
        setIsMoreInfoOpen={setIsMoreInfoOpen}
        merchantData={merchantData}
        bMerchantData={bMerchantData}
        openReviewModal={openReviewModal}
        setOpenReviewModal={setOpenReviewModal}
        isMobile={isMobile}
        rating={rating}
        t={t}
      />
      {isFetch && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mb: 10,
          }}
          width="100%"
        >
          <MoreInfoModal
            data={{
              name: merchantData.name,
              openTime: bMerchantData.open_time,
              closeTime: bMerchantData.close_time,
              location: merchantLocation,
              isMerchantOpen: isMerchantOpenState,
            }}
            open={isMoreInfoOpen}
            setOpen={setIsMoreInfoOpen}
          />
          <Box sx={{ width: "100%" }}>
            <ReviewModal
              uuid={bMerchantData.uuid}
              open={openReviewModal}
              handleClose={() => setOpenReviewModal(false)}
              isMobile={isMobile}
              rating={rating.toFixed(1)}
            />

            <MerchantHeader
              merchantData={merchantData}
              bMerchantData={bMerchantData}
              isMerchantOpenState={isMerchantOpenState}
              distance={distance}
              rating={rating}
              isFavorite={isFavorite}
              setOpenReviewModal={setOpenReviewModal}
              setIsMoreInfoOpen={setIsMoreInfoOpen}
              handleFavClick={handleFavClick}
              openNoPayment={openNoPayment}
              openNoService={openNoService}
              t={t}
            />
            {false && <CouponSection />}

            {bMerchantCategory && (
              <MenuShopCategoryTab
                scrollToCategory={scrollToCategory}
                displayMenu={displayMenu}
                cartList={cartList}
                setCartList={setCartList}
                handleQuantityChange={handleQuantityChange}
                openNoPayment={openNoPayment}
                openNoService={openNoService}
                openCloseModal={openCloseModal}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
            )}
            {searchValue && (
              <SearchMenuResults
                searchValue={searchValue}
                resultCount={resultCount}
                filteredMenu={filteredMenu}
                isQr={false}
                cartList={cartList}
                setCartList={setCartList}
                handleQuantityChange={handleQuantityChange}
                openNoPayment={openNoPayment}
                openNoService={openNoService}
                openCloseModal={openCloseModal}
                notFoundImg={notFoundImg}
                setSearchValue={setSearchValue}
              />
            )}
            {!searchValue &&
              bMerchantCategory &&
              displayMenu
                .filter((item) => item.menu.length !== 0)
                .sort((a, b) => a.category.priority - b.category.priority)
                .map((object, index) => (
                  <MenuSection
                    key={index}
                    object={object}
                    index={index}
                    scrollCategoryRefs={scrollCategoryRefs}
                    cartList={cartList}
                    setCartList={setCartList}
                    handleQuantityChange={handleQuantityChange}
                    openNoPayment={openNoPayment}
                    openNoService={openNoService}
                    openCloseModal={openCloseModal}
                  />
                ))}
          </Box>
        </Box>
      )}
      {totalQuantity !== 0 && (
        <AppBar
          sx={{
            backgroundColor: "transparent",
            boxShadow: 0,
            top: "auto",
            bottom: 0,
            zIndex: 1000,
            animation: "slideUp 0.5s ease-in-out",
            "@keyframes slideUp": {
              from: { transform: "translateY(100%)" },
              to: { transform: "translateY(0)" },
            },
          }}
        >
          <Toolbar
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
              mb: 2,
            }}
          >
            <IconButton
              onClick={() => {
                setIsAddMenuOpen(!isAddMenuOpen);
                handleGoToCartPage();
              }}
              sx={{
                backgroundColor: "#FF961B",
                width: "55px",
                height: "55px",
                color: "white",
                animation: "fadeIn 0.5s ease-in-out",
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                ":hover": {
                  backgroundColor: "#FF961B",
                  color: "white",
                  transform: "scale(1.1)",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                },
                "@keyframes fadeIn": {
                  from: { opacity: 0 },
                  to: { opacity: 1 },
                },
              }}
            >
              <Badge
                sx={{
                  "& .MuiBadge-badge": {
                    minWidth: "15px",
                    width: "15px",
                    fontSize: "10px",
                    height: "15px",
                    color: "#F19A3C",
                    backgroundColor: "white",
                  },
                }}
                badgeContent={totalQuantity}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <img
                  src={ShoppingBag}
                  width={25}
                  height={25}
                  alt="Shopping Bag"
                />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
      )}
      {showScrollTop && (
        <Fab
          size="medium"
          variant="extended"
          onClick={scrollToTop}
          sx={{
            position: "fixed",
            bottom: 50,
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1000,
          }}
        >
          กลับไปด้านบน
          <KeyboardArrowUpIcon />
        </Fab>
      )}
    </>
  );
}

export default Merchant;
