import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import PrompkinsIcon from "../assets/images/logo_large_size.png";
import PompkinsGoogleIcon from "../assets/images/pompkins_google.png";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Box, Link } from "@mui/material";
import LayoutMobile from "./LayoutMobile";
import { useMobile } from "../stores/useMobile";
import LanguageMenu from "./layoutComponents/LanguageMenu";
import Footer from "./Footer";
import LayoutFoodMobile from "./LayoutFoodMobile";
import foodFaviconIco from "../assets/images/favicon_package_pompkins_food/favicon-32x32.png";
import largeFoodPngIcon from "../assets/images/favicon_package_pompkins_food/PKFCircle-01.png";
import appleTouchFoodIcon from "../assets/images/favicon_package_pompkins_food/apple-touch-icon.png";
import imagePompkinOG from "../assets/images/coverFoodWeb-07.png";
import { Helmet } from "react-helmet-async";
import PompkinsFoodIcon from "../assets/images/pompkins-food-nav.png";
import LogoNav from "./layoutComponents/LogoNav";

function LayoutAuth() {
  const version = new Date().toISOString().split("T")[0];
  const { isMobile } = useMobile();
  const location = useLocation();
  return (
    <>
      <Helmet>
        <meta property="og:image" content={`${imagePompkinOG}?v=${version}`} />
        <meta name="twitter:image" content={`${imagePompkinOG}?v=${version}`} />
        <link rel="image/png" href={`${foodFaviconIco}`} sizes="any" />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href={`${largeFoodPngIcon}`}
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${appleTouchFoodIcon}`}
        />
      </Helmet>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="sticky" className="bg-white ">
          <Toolbar>
            {/* {isMobile ? (
              <LayoutFoodMobile />
            ) : ( */}
            <>
              {location.pathname.includes("login") ? (
                <Link href="/food">
                  <LogoNav logoSrc={PompkinsFoodIcon} width="150px" />
                </Link>
              ) : (
                <LogoNav logoSrc={PompkinsFoodIcon} width="150px" />
              )}

              <Box sx={{ flexGrow: 1 }} />
              <Box>
                <LanguageMenu />
              </Box>
            </>
            {/* )} */}
          </Toolbar>
        </AppBar>
        <main className="flex-grow min-h-screen">
          <Outlet />
        </main>
        {!location.pathname.includes("queue") &&
          !location.pathname.includes("merchant-qr") &&
          !location.pathname.includes("menu") && <Footer />}
      </Box>
    </>
  );
}

export default LayoutAuth;
