import { Box } from "@mui/material";
import React, { useEffect } from "react";
import BgImage from "../assets/image/bg-graphic.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoginPage from "./Login/LoginPage";
import EmailStep from "./Register/EmailStep";
import Login from "./Login/Login";
import ForgotPassword from "./Login/ForgotPassword";
import ResetPassword from "./Login/ResetPassword";
import ResetPasswordSuccess from "./Login/ResetPasswordSuccess";
import SendVerifyStep from "./Register/SendVerifyStep";
import Registration from "./Register/Registration";
import PhoneStep from "./Register/MobileNumberStep";
import OtpStep from "./Register/OtpStep";
import SuccessStep from "./Register/SuccessStep";
import VerifyEmailSuccess from "./Register/VerifyEmailSuccess";
import GuestLogin from "./Login/GuestLogin";
import { useAuthStore } from "../stores/useAuthStore";
import ReactGA from "react-ga4";
import signInWithCustomTokenResponse from "../utils/firebaseAuth";

function AuthenPage() {
  const [searchParams] = useSearchParams();
  const navigator = useNavigate();
  const { isAuthenticated } = useAuthStore();
  // if (isAuthenticated) {
  //   navigator("/", { replace: true });
  // }
  const getStepFromParams = searchParams.get("step");
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/login/new",
      title: "AuthenPage",
    });
  }, []);

  const renderAuthPage = () => {
    switch (getStepFromParams) {
      case "email":
        return <EmailStep />;
      case "login":
        return <Login />;
      case "forgotPassword":
        return <ForgotPassword />;
      case "resetPassword":
        return <ResetPassword />;
      case "resetPasswordSuccess":
        return <ResetPasswordSuccess />;
      case "emailVerification":
        return <SendVerifyStep />;
      case "registration":
        return <Registration />;
      case "phone":
        return <PhoneStep />;
      case "otp":
        return <OtpStep />;
      case "success":
        return <SuccessStep />;
      case "verifyEmailComplete":
        return <VerifyEmailSuccess />;
      case "guestLogin":
        return <GuestLogin />;
      default:
        return <LoginPage />;
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        backgroundImage: `url(${BgImage})`,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "95vh",
      }}
    >
      {renderAuthPage()}
    </Box>
  );
}

export default AuthenPage;
