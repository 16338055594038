import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Rating,
  TextField,
  Paper,
} from "@mui/material";
import { getOrdersByOrders_uuid } from "../../libs/customerAPI/getOrders";
import { useParams } from "react-router-dom";
import LoadingScreen from "../../components/LoadingScreen";
import OrderItem from "../Order/OrderItem";
import { useAuthStore } from "../../stores/useAuthStore";
import { createReview } from "../../libs/merchantAPI/merchantAPI";
import ReviewSuccess from "./ReviewSuccess";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

function ReviewPage() {
  const { t } = useTranslation();
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [orders, setOrders] = useState();
  const { order_uuid, bmerchant_uuid } = useParams();
  const { user } = useAuthStore();
  const [isReview, setIsReview] = useState(false);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/review/${bmerchant_uuid}/${order_uuid}`,
      title: "ReviewPage",
    });
  }, []);

  useEffect(() => {
    getOrdersByOrders_uuid(order_uuid)
      .then((res) => {
        setOrders(res.data);
      })
      .catch((err) => {
        console.log("Get Order Error", err);
      });
  }, [order_uuid]);

  const handleRatingChange = (event, newRating) => {
    setRating(newRating);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleConfirm = () => {
    const data = {
      bmerchant_uuid: bmerchant_uuid,
      customer_uuid: user.uuid,
      point: rating,
      customer_comment: comment,
    };
    createReview(data).then(() => {
      setIsReview(true);
    });
  };

  if (isReview) {
    return <ReviewSuccess />;
  }

  if (!orders) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Helmet>
        <title>
          {t("pompkins")} - {t("review")}
        </title>
        <meta
          name="description"
          content="สั่งเดลิเวอรีผ่าน POMPKINS Food  x Order with Google ได้แล้ววันนี้"
        />
        <link rel="canonical" href={`${window.location.href}`} />
        <meta property="og:url" content="https://pompkins.com/review" />
      </Helmet>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "95vh",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            padding: 2,
            margin: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            maxWidth: "600px",
            borderRadius: "16px",
          }}
        >
          {/* Display a heading */}
          <Typography variant="h5">ออเดอร์ของคุณเป็นอย่างไร</Typography>

          {/* Display an image */}

          <Box width={"90%"}>
            {orders &&
              orders.order_detail.map((menuItem, menuIndex) => (
                <OrderItem key={menuIndex} item={menuItem} />
              ))}
          </Box>
          <Typography variant="subtitle1">ให้คะแนนร้านค้า:</Typography>
          <Rating
            name="rating"
            value={rating}
            onChange={handleRatingChange}
            size="large"
          />

          {/* กล่องความคิดเห็น */}
          <Typography variant="subtitle1">เพิ่มความคิดเห็น:</Typography>
          <TextField
            fullWidth
            id="outlined-multiline-static"
            placeholder="เขียนความคิดเห็นของคุณที่นี่..."
            multiline
            rows={4}
            value={comment}
            onChange={handleCommentChange}
            margin="normal"
          />
          {/* Action button */}
          <Button
            disabled={!rating}
            onClick={handleConfirm}
            variant="contained"
            color="primary"
          >
            ยืนยัน
          </Button>
        </Paper>
      </Box>
    </>
  );
}

export default ReviewPage;
