import { useEffect, useState } from "react";

const useDeliveryFee = (orderType, bmerchantService, distance) => {
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [error, setError] = useState({ errorDistance: null });

  useEffect(() => {
    if (orderType !== "delivery") {
      setDeliveryFee(0);
      return;
    }

    if (distance < parseFloat(bmerchantService.delivery_distance1)) {
      setDeliveryFee(parseInt(bmerchantService.delivery_fee1));
      setError({
        ...error,
        errorDistance: null,
      });
    } else if (
      bmerchantService.delivery_distance2 &&
      distance < parseFloat(bmerchantService.delivery_distance2)
    ) {
      setDeliveryFee(parseInt(bmerchantService.delivery_fee2));
      setError({
        ...error,
        errorDistance: null,
      });
    } else if (
      bmerchantService.delivery_distance3 &&
      distance < parseFloat(bmerchantService.delivery_distance3)
    ) {
      setDeliveryFee(parseInt(bmerchantService.delivery_fee3));
      setError({
        ...error,
        errorDistance: null,
      });
    } else {
      setError({
        ...error,
        errorDistance: "ไม่อยู่ในระยะทางที่กําหนดของร้านค้า",
      });
    }
  }, [
    bmerchantService.delivery_distance1,
    bmerchantService.delivery_distance2,
    bmerchantService.delivery_distance3,
    bmerchantService.delivery_fee1,
    bmerchantService.delivery_fee2,
    bmerchantService.delivery_fee3,
    distance,
    orderType,
  ]);

  return { deliveryFee, error };
};

export default useDeliveryFee;
