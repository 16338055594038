import React, { useEffect, useState } from "react";
import {
  Typography,
  Stack,
  Box,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  IconButton,
  stepConnectorClasses,
} from "@mui/material";
import {
  AccessTime,
  Receipt,
  RestaurantMenu,
  Done,
  ArrowBackIosNew,
} from "@mui/icons-material";
import { keyframes, styled } from "@mui/material/styles";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { startTimer } from "../../utils/startTimer";
import WaitForPayment from "../../assets/images/ld3_payment320.gif";
import Cooking from "../../assets/images/ld2_chef320.gif";
import Delivery from "../../assets/images/ld4_deliveryman320.gif";
import Pickup from "../../assets/images/ld5_arrived320.gif";

const pulse = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`;

// Custom connector to change the color
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,115,80) 50%,rgb(233,115,50) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,115,80) 50%,rgb(233,115,50) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 4,
    border: 0,
    marginTop: "2px",
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.grey[800]
        : "rgba(252, 158, 79, 1)",
    borderRadius: 1,
    transition: theme.transitions.create("background-color", {
      duration: theme.transitions.duration.shortest,
    }),
    animation: `${pulse} 2s ease-in-out infinite`,
  },
}));

// Styles for the step icon
const ColorlibStepIconRoot = styled("div")(({ theme, active, completed }) => ({
  backgroundColor: "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(active && {
    backgroundImage:
      "linear-gradient(136deg, rgba(252, 158, 79, 1) 0%, rgba(247, 107, 28, 1) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(completed && {
    backgroundImage:
      "linear-gradient(136deg, rgba(252, 158, 79, 1) 0%, rgba(247, 107, 28, 1) 100%)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, icon } = props;

  const icons = {
    1: <Receipt sx={{ width: 35, height: 35 }} />,
    2: <RestaurantMenu sx={{ width: 35, height: 35 }} />,
    3: <DeliveryDiningIcon sx={{ width: 35, height: 35 }} />,
    4: <Done sx={{ width: 35, height: 35 }} />,
  };

  return (
    <ColorlibStepIconRoot active={active} completed={completed}>
      {icons[String(icon)]}
    </ColorlibStepIconRoot>
  );
}

const DeliveryStatusCard = ({
  orderData,
  handleCancel,
  contract,
  merchantName,
}) => {
  const { t } = useTranslation();
  const { order_uuid, merchant_id, bmerchant_id } = useParams();
  const [timeLeft, setTimeLeft] = useState(
    startTimer("waitForConfirm", order_uuid)
  );
  // Update the remaining time every second
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((prevRemainingTime) =>
        prevRemainingTime > 0 ? prevRemainingTime - 1 : 0
      );
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const [activeStep, setActiveStep] = useState(0);
  // const [isCancel, setIsCancel] = useState(false);
  const cookingStatus = orderData.cooking_status;
  const payment_status = orderData.payment_status;
  const order_status = orderData.order_status;
  const deliver_status = orderData.deliver_status;
  const transaction_uuid = orderData.transaction_uuid;
  const navigator = useNavigate();
  useEffect(() => {
    if (order_status === "MERCHANT CONFIRMED" && payment_status === "PENDING") {
      localStorage.removeItem(`waitForConfirmTimer_${order_uuid}`);
      navigator(
        `/order-status/${merchant_id}/${bmerchant_id}/payment/${orderData.merchant_uuid}/${orderData.order_uuid}/${transaction_uuid}`
      );
    }
  }, [
    bmerchant_id,
    merchant_id,
    navigator,
    orderData.merchant_uuid,
    orderData.order_uuid,
    order_status,
    payment_status,
  ]);

  useEffect(() => {
    if (activeStep === 0) {
      if (payment_status === "PAID") {
        setActiveStep(1);
      }
    }
    if (activeStep === 1) {
      if (cookingStatus === "SUCCESS") {
        setActiveStep(2);
      }
    }
    if (activeStep === 2) {
      if (deliver_status === "SUCCESS") {
        setActiveStep(3);
      }
    }
    if (activeStep === 3) {
      if (order_status === "SUCCESS") {
        navigator(
          `/order/bill/${merchant_id}/${bmerchant_id}/${transaction_uuid}`
        );
      }
    }
  }, [order_status, activeStep, cookingStatus, deliver_status, payment_status]);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  if (timeLeft === 0) {
    if (order_status === "PENDING") {
      handleCancel();
    }
  }

  if (!orderData) {
    return null;
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          width: "100%",
          height: "40px",
        }}
      >
        {activeStep === 3 && (
          <IconButton
            onClick={() => navigator(-1)}
            sx={{
              top: 0,
              left: 0,
            }}
          >
            <ArrowBackIosNew className="text-primary" />
          </IconButton>
        )}
      </Box>
      <Typography variant="h6" gutterBottom>
        {merchantName}
      </Typography>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        {activeStep === 0 && (
          <img
            src={WaitForPayment}
            style={{ width: "200px" }}
            alt="status"
            title="waitForPayment"
            loading="lazy"
            width={"200px"}
            height={"auto"}
          />
        )}
        {activeStep === 1 && (
          <img
            src={Cooking}
            style={{ width: "200px" }}
            alt="status"
            title="cooking"
            loading="lazy"
            width={"200px"}
            height={"auto"}
          />
        )}
        {activeStep === 2 && (
          <img
            src={Delivery}
            style={{ width: "200px" }}
            alt="status"
            title="delivery"
            loading="lazy"
            width={"200px"}
            height={"auto"}
          />
        )}
        {activeStep === 3 && (
          <img
            src={Pickup}
            style={{ width: "200px" }}
            alt="status"
            title="pickup"
            loading="lazy"
            width={"200px"}
            height={"auto"}
          />
        )}
      </Box>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
        sx={{ my: 2 }}
      >
        {[
          t("orderStatus.orderPlaced"),
          t("orderStatus.inKitchen"),
          t("orderStatus.delivery"),
          t("orderStatus.deliverySuccess"),
        ].map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Typography variant="body1">
        {t("orderStatus.orderNo")}{" "}
        <span>{orderData.order_no.slice(0, 5).toUpperCase()}</span>
      </Typography>

      <Box sx={{ my: 2 }}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          justifyContent={"center"}
          textAlign={"center"}
        >
          {activeStep === 0 && (
            <>
              <AccessTime />
              <Typography variant="body2">
                {t("orderStatus.pleaseWaitDescription")}
              </Typography>{" "}
            </>
          )}
          {activeStep === 1 && (
            <Box sx={{ width: ["80%", "80%", "80%"] }}>
              <Typography variant="body2">
                {t("orderStatus.inKitchenDescription")}
              </Typography>
            </Box>
          )}
          {activeStep === 2 && (
            <Typography variant="body2">
              {t("orderStatus.deliveryDescription")}
            </Typography>
          )}
          {activeStep === 3 && (
            <Box sx={{ width: ["80%", "80%", "80%"] }}>
              <Typography variant="body2">
                {t("orderStatus.deliverySuccessDescription")}
              </Typography>
            </Box>
          )}
        </Stack>
        {activeStep === 0 && (
          <>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mt: 1, textAlign: "center" }}
            >
              {`${minutes} min : ${seconds.toString().padStart(2, "0")} sec`}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {t("orderStatus.notice")}
            </Typography>
          </>
        )}
      </Box>

      <Typography variant="body2" sx={{ mb: 2 }}>
        ติดต่อร้านค้าโทร. {contract}
      </Typography>
      {/* {!isCancel ? (
        <Button
          variant="contained"
          disabled={activeStep !== 0}
          onClick={() => setIsCancel(true)}
          sx={{ borderRadius: 5, width: ["100%", "50%", "50%"] }}
        >
          {t("orderStatus.cancelOrder")}
        </Button>
      ) : (
        <Box sx={{ width: "70%", bgcolor: "background.paper" }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              ทำไมคุณถึงยกเลิก
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selection}
              label="ทำไมคุณถึงยกเลิก"
              onChange={handleSelectionChange}
            >
              <MenuItem value={10}>ร้านกำลังจะปิดแล้ว</MenuItem>
              <MenuItem value={20}>สั่งอาหารผิด</MenuItem>
              <MenuItem value={30}>ใส่ที่อยู่ผิด</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            id="outlined-multiline-static"
            label={t("orderStatus.pleaseEnterReason")}
            multiline
            rows={4}
            value={reason}
            onChange={handleReasonChange}
            margin="normal"
          />
          <Stack
            direction="row-reverse"
            alignItems="center"
            justifyContent="center"
            spacing={2}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleCancelOrder}
              sx={{ borderRadius: 5 }}
            >
              {t("orderStatus.confirmCancel")}
            </Button>
            <Button
              variant="outlined"
              onClick={handleKeepOrder}
              sx={{ borderRadius: 5 }}
            >
              {t("orderStatus.keepOrder")}
            </Button>
          </Stack>
        </Box>
      )} */}
    </>
  );
};

export default DeliveryStatusCard;
