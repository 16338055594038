import React, { useEffect } from "react";
import { Box, Typography, Grid, Button } from "@mui/material";
import QrMenuShopCard from "../pages/QrPage/QrMenuShopCard";
import MenuShopCard from "./MenuShopCard";

const SearchMenuResults = ({
  searchValue,
  resultCount,
  filteredMenu,
  isQr,
  cartList,
  setCartList,
  handleQuantityChange,
  openNoPayment,
  openNoService,
  openCloseModal,
  notFoundImg,
  setSearchValue,
}) => {
  return (
    <>
      {searchValue && (
        <Typography variant="subtitle1" sx={{ px: 2, mt: 2 }}>
          We found {resultCount} results for "{searchValue}"
        </Typography>
      )}
      {filteredMenu &&
        filteredMenu.map((object, index) => (
          <Box key={index} sx={{ px: 2 }}>
            {object.menu.length > 0 && (
              <Typography
                variant="h6"
                sx={{
                  mt: 2,
                  mb: 2,
                  fontWeight: "bold",
                }}
              >
                {object.category.name}
              </Typography>
            )}

            {isQr ? (
              <Box>
                {object.menu.map((menus) => (
                  <Box key={menus.id}>
                    <QrMenuShopCard
                      items={menus}
                      cartList={cartList}
                      setCartList={setCartList}
                      onIncrement={() => handleQuantityChange(menus.id, 1)}
                      onDecrement={() => handleQuantityChange(menus.id, -1)}
                    />
                  </Box>
                ))}
              </Box>
            ) : (
              <Grid container justifyContent="space-between" spacing={2}>
                {object.menu.map((menus) => (
                  <Grid key={menus.id} item xs={12} sm={5.85}>
                    <MenuShopCard
                      items={menus}
                      cartList={cartList}
                      setCartList={setCartList}
                      onIncrement={() => handleQuantityChange(menus.id, 1)}
                      onDecrement={() => handleQuantityChange(menus.id, -1)}
                      openNoPayment={openNoPayment}
                      openNoService={openNoService}
                      openCloseModal={openCloseModal}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
        ))}
      {resultCount === 0 && searchValue !== "" && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "50%",
          }}
        >
          <img
            src={notFoundImg}
            alt="not found img"
            title="not found img"
            loading="lazy"
            width={"250px"}
            height={"250px"}
          />
          <Button
            variant="contained"
            onClick={() => setSearchValue("")}
            sx={{ m: 2 }}
            size="large"
          >
            Clear search
          </Button>
        </Box>
      )}
    </>
  );
};

export default SearchMenuResults;
