import React from "react";
import { Button, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const NavigationMenu = ({ setCanAccess, handleFindRestaurants }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navItems = [
    { label: t("nav.whatIsPompkins"), path: "/" },
    { label: t("nav.priceAndPackage"), path: "/pompkins/package" },
    { label: t("nav.findRestaurants"), action: handleFindRestaurants },
    { label: t("nav.FAQ"), path: "/pompkins/FAQ" },
  ];

  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      {navItems.map((item, index) => (
        <Button
          key={index}
          onClick={() => {
            setCanAccess(true);
            item.action ? item.action() : navigate(item.path);
          }}
          sx={{
            color: "text.primary",
            fontWeight: "medium",
            "&:hover": {
              backgroundColor: "action.hover",
            },
          }}
        >
          {item.label}
        </Button>
      ))}
    </Box>
  );
};

export default NavigationMenu;
