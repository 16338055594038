import React from "react";
import {
  Box,
  Typography,
  Button,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  AccessTime,
  PedalBike,
  Star as StarIcon,
  InfoOutlined,
  Favorite,
  FavoriteBorder,
} from "@mui/icons-material";
import DefaultBackground from "../../utils/defaultBackground";
import DefaultProfile from "../../assets/images/pompkins_food_logo.png";
import MerchantBreadcrumbs from "./MerchantBreadcrumbs";
import { Link } from "react-router-dom";
import useReview from "../../stores/useReview";

const MerchantHeader = ({
  merchantData,
  bMerchantData,
  isMerchantOpenState,
  distance,
  rating,
  isFavorite,
  setOpenReviewModal,
  setIsMoreInfoOpen,
  handleFavClick,
  openNoPayment,
  openNoService,
  t,
}) => {
  const { displayReviews } = useReview(bMerchantData.uuid, true);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isVerySmallScreen = useMediaQuery("(max-width:385px)");

  const breadcrumbs = [
    <Link
      key="1"
      color="inherit"
      to={"/food"}
      className="text-primary underline"
      style={{ fontWeight: 500, fontSize: isVerySmallScreen ? "12px" : "14px" }}
    >
      รายการร้านอาหาร
    </Link>,
    <Typography
      key="2"
      sx={{
        color: "#333333",
        fontWeight: 500,
        fontSize: isVerySmallScreen ? "12px" : "14px",
      }}
    >
      {merchantData.name}
    </Typography>,
  ];

  return (
    <Box sx={{ position: "relative", backgroundColor: "white" }}>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          backgroundImage: `url(${
            merchantData.background ?? DefaultBackground
          })`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: isSmallScreen ? "20dvh" : "22.7dvh",
          paddingX: isSmallScreen ? 2 : 4,
        }}
      />
      <MerchantBreadcrumbs breadcrumbs={breadcrumbs} />
      <Divider sx={{ width: "100%" }} />
      <Box
        sx={{
          px: isSmallScreen ? 1 : 2,
          py: isSmallScreen ? 1 : 2,
          bottom: 0,
          display: "flex",
          flexDirection: ["column", "column", "row"],
          alignItems: ["start", "start", "center"],
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            alt="logo"
            title="logo"
            loading="lazy"
            component="img"
            src={merchantData.logo ?? DefaultProfile}
            sx={{
              minWidth: isSmallScreen ? "90px" : "110px",
              width: isSmallScreen ? "90px" : ["110px", "110px", "150px"],
              minHeight: isSmallScreen ? "90px" : "110px",
              height: isSmallScreen ? "90px" : ["110px", "110px", "150px"],
              borderRadius: "20px",
              objectFit: merchantData.logo ? "cover" : "contain",
            }}
          />
          <Box
            sx={{
              ml: 2,
              mt: 1,
              py: [0, 0, 2],
              display: "flex",
              flexDirection: "column",
              gap: 0.7,
              backgroundColor: "white",
              flex: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  fontSize: isVerySmallScreen
                    ? "10px"
                    : ["12px", "14px", "16px"],
                }}
              >
                {merchantData.shop_type ?? "ร้านอาหารทั่วไป"}{" "}
              </Typography>
              <Button
                onClick={() => setIsMoreInfoOpen(true)}
                variant="text"
                sx={{
                  p: 0,
                  minWidth: 0,
                  color: "#FF961B",
                  ":hover": {
                    backgroundColor: "#FFF7EB",
                  },
                }}
              >
                <InfoOutlined
                  sx={{
                    marginTop: "-2px",
                    fontSize: isVerySmallScreen
                      ? "10px"
                      : ["12px", "14px", "16px"],
                    mr: "4px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: isVerySmallScreen
                      ? "10px"
                      : ["12px", "14px", "16px"],
                  }}
                >
                  {t("merchant.moreInfo")}
                </Typography>
              </Button>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  fontSize: isVerySmallScreen
                    ? "16px"
                    : ["18px", "20px", "24px"],
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                  color: "#343434",
                  fontWeight: "medium",
                  maxWidth: "calc(100% - 40px)",
                }}
                variant="h1"
              >
                {merchantData.name}
              </Typography>
              <Box
                onClick={() => handleFavClick()}
                sx={{
                  width: isVerySmallScreen ? "16px" : ["18px", "20px", "24px"],
                  height: isVerySmallScreen ? "16px" : ["18px", "20px", "24px"],
                  borderRadius: "50%",
                  border: "1px solid #FF961B",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor:
                    !openNoPayment || !openNoService
                      ? "not-allowed"
                      : "pointer",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                  flexShrink: 0,
                }}
                disabled={!openNoPayment || !openNoService}
              >
                {isFavorite ? (
                  <Favorite
                    sx={{
                      fontSize: isVerySmallScreen
                        ? "10px"
                        : ["12px", "14px", "16px"],
                      color: "#FF961B",
                    }}
                  />
                ) : (
                  <FavoriteBorder
                    sx={{
                      fontSize: isVerySmallScreen
                        ? "10px"
                        : ["12px", "14px", "16px"],
                      color: "#FF961B",
                    }}
                  />
                )}
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: isSmallScreen ? "column" : "row",
                gap: 0.7,
                flexWrap: isSmallScreen ? "wrap" : "nowrap",
              }}
            >
              <Box sx={{ display: "flex", gap: 1 }}>
                <AccessTime
                  sx={{
                    fontSize: isVerySmallScreen
                      ? "14px"
                      : ["16px", "18px", "20px"],
                    marginTop: "-2px",
                    color: isMerchantOpenState ? "green" : "red",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: isVerySmallScreen
                      ? "10px"
                      : ["12px", "12px", "14px"],
                    color: isMerchantOpenState ? "green" : "red",
                  }}
                  variant="body2"
                >
                  {isMerchantOpenState
                    ? t("merchant.merchantOpen")
                    : t("merchant.merchantClose")}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: isVerySmallScreen
                      ? "10px"
                      : ["12px", "12px", "14px"],
                  }}
                >
                  {bMerchantData.open_time} - {bMerchantData.close_time}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: 1 }}>
                <PedalBike
                  sx={{
                    fontSize: isVerySmallScreen
                      ? "14px"
                      : ["16px", "18px", "20px"],
                    marginTop: "-2px",
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: isVerySmallScreen
                      ? "10px"
                      : ["12px", "12px", "14px"],
                  }}
                >
                  {distance ?? "2"} Km
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "center",
                gap: 0.7,
              }}
            >
              <StarIcon
                sx={{
                  color: "rgb(250, 175, 0)",
                  fontSize: isVerySmallScreen ? "16px" : "20px",
                  marginTop: "-2px",
                }}
              />
              <Typography
                fontSize={isVerySmallScreen ? "10px" : "12px"}
                alignSelf={"center"}
              >
                {rating.toFixed(1)}
              </Typography>
              <Button
                onClick={() => setOpenReviewModal(true)}
                variant="text"
                sx={{
                  mr: 1,
                  p: 0,
                  minWidth: 0,
                  color: "#FF961B",
                  ":hover": {
                    backgroundColor: "#FFF7EB",
                  },
                  fontWeight: "400",
                  fontSize: isVerySmallScreen ? "10px" : "12px",
                }}
              >
                ({displayReviews.length}
                {t("merchant.seeReivews")})
              </Button>
            </Box>
            <Typography
              variant="body2"
              sx={{
                color: "#FF961B",
                fontSize: isVerySmallScreen ? "10px" : "12px",
              }}
            >
              <span className="text-red-500">*</span> {t("actualDriving")}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MerchantHeader;
