import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";
import { Avatar, Divider, IconButton, InputAdornment } from "@mui/material";
import ReactLoading from "react-loading";
import { GpsFixed, Search } from "@mui/icons-material";
import { useLocationStore } from "../stores/useLocationStore";
import { useTranslation } from "react-i18next";
import locationWithBg from "../assets/images/locationwithbg.png";
import { getAddressFromCoordinates } from "../utils/convertAddress";

const GOOGLE_MAPS_API_KEY = "AIzaSyCf0iF-Wk0h6A32W7DgVDS1BjYABfanT0Q";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

export default function GoogleMaps({ onClickSearch, propsValue }) {
  const [value, setValue] = React.useState(propsValue);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);
  const { t } = useTranslation();
  const {
    currentAddress,
    setCurrentAddress,
    setCurrentLocation,
    fetchCurrentLocation,
    isLoading,
    setIsLoading,
    currentLocation,
  } = useLocationStore();
  const [locationPermission, setLocationPermission] = React.useState(null);

  const handleLocationClick = async () => {
    setIsLoading(true);
    setValue(null);
    await fetchCurrentLocation();
  };
  React.useEffect(() => {
    const fetchAddress = async () => {
      if (currentLocation) {
        const address = await getAddressFromCoordinates(currentLocation);
        setValue(address);
      }
    };
    fetchAddress();
  }, [currentLocation, value]);

  React.useEffect(() => {
    if (propsValue) {
      setValue(propsValue);
    }
  }, [propsValue]);

  React.useEffect(() => {
    navigator.permissions
      .query({ name: "geolocation" })
      .then(function (result) {
        setLocationPermission(result.state);
        result.onchange = () => {
          setLocationPermission(result.state);
        };
      });
  }, []);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places,geometry,drawing`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });
    return () => {
      active = false;
    };
  }, [inputValue, fetch, value]);

  return (
    <Autocomplete
      id="google-map-demo"
      sx={{ width: "100%", backgroundColor: "white", borderRadius: 4 }}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      freeSolo
      autoComplete
      disableClearable
      includeInputInList
      filterSelectedOptions
      value={value ?? currentAddress}
      noOptionsText="No locations"
      onChange={(event, newValue) => {
        if (newValue) {
          const geocoder = new window.google.maps.Geocoder();
          geocoder.geocode(
            { placeId: newValue.place_id },
            (results, status) => {
              if (status === "OK" && results[0]) {
                const { lat, lng } = results[0].geometry.location;
                setCurrentLocation({ lat: lat(), lng: lng() });
              }
            }
          );
        }
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        // setCurrentAddress(newValue.description);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          // label={t("welcome.findLabel")}
          placeholder={t("welcome.findLabel")}
          fullWidth
          variant="standard"
          size="small"
          sx={{
            p: 1,
          }}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            type: "search",

            startAdornment: (
              <InputAdornment position="end" sx={{ ml: 1 }}>
                <Box
                  sx={{
                    borderRight: "1px solid #ABACAE",
                    borderStyle: "dashed",
                    pr: 1,
                    mr: 1,
                    width: "40px",
                  }}
                >
                  {isLoading ? (
                    <ReactLoading
                      type="bubbles"
                      color="#FF961B"
                      height={40}
                      width={40}
                    />
                  ) : (
                    <Box
                      onClick={handleLocationClick}
                      component={"img"}
                      sx={{ width: "30px", cursor: "pointer" }}
                      src={locationWithBg}
                      alt="location"
                      title="location"
                      loading="lazy"
                    />
                  )}
                </Box>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="start">
                <Search
                  onClick={
                    locationPermission === "granted" && !isLoading
                      ? onClickSearch
                      : null
                  }
                  sx={{
                    width: "38px",
                    height: "38px",
                    color:
                      locationPermission === "granted" && !isLoading
                        ? "#F19A3C"
                        : "#ccc",
                    cursor:
                      locationPermission === "granted" && !isLoading
                        ? "pointer"
                        : "not-allowed",
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
      )}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting?.main_text_matched_substrings || [];
        const parts = matches.map((match) =>
          option.structured_formatting?.main_text.slice(
            match.offset,
            match.offset + match.length
          )
        );
        if (matches.length === 0) {
          return null;
        } else {
          return (
            <li {...props}>
              <Grid container alignItems="center" sx={{ width: "100%" }}>
                <Grid item sx={{ display: "flex", width: 44 }}>
                  <LocationOnIcon sx={{ color: "text.secondary" }} />
                </Grid>
                <Grid
                  item
                  sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
                >
                  {parts.map((part, index) => (
                    <Box
                      key={index}
                      component="span"
                      sx={{
                        fontWeight: parts[index]?.length ? "bold" : "regular",
                      }}
                    >
                      {part}
                    </Box>
                  ))}

                  <Typography variant="body2" color="text.secondary">
                    {option.structured_formatting?.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }
      }}
    />
  );
}
