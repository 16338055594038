import changeImage1 from "../../assets/images/instructions/Change image 1.png";
import changeImage2 from "../../assets/images/instructions/Change image 2.png";
import merchantSetting from "../../assets/images/instructions/Merchant Detail 1.png";
import merchantDetail2 from "../../assets/images/instructions/Merchant Detail 2.png";
import merchantDetail3 from "../../assets/images/instructions/Merchant Detail 3.png";
import openCloseTime1 from "../../assets/images/instructions/Open Close 1.png";
import openCloseTime2 from "../../assets/images/instructions/Open close 2.png";
import openCloseTime3 from "../../assets/images/instructions/Open close 3.png";
import openCloseTime4 from "../../assets/images/instructions/Open close 4.png";
import serviceMerchant1 from "../../assets/images/instructions/Service merchant 1.png";
import serviceMerchant2 from "../../assets/images/instructions/Service merchant 2.png";
import serviceMerchant3 from "../../assets/images/instructions/Service merchant 3.png";
import onlineService1 from "../../assets/images/instructions/Online service 1.png";
import onlineService2 from "../../assets/images/instructions/Online service 2.png";
import onlineService3 from "../../assets/images/instructions/Online service 3.png";
import billDesign1 from "../../assets/images/instructions/Bill_design_1.png";
import billDesign2 from "../../assets/images/instructions/Bill_design_2.png";
import billDesign3 from "../../assets/images/instructions/Bill_design_3.png";
import billDesign4 from "../../assets/images/instructions/Bill_design_4.png";
import deliverFee1 from "../../assets/images/instructions/Delivery setting 1.png";
import deliverFee2 from "../../assets/images/instructions/Delivery setting 2.png";
import deliverFee3 from "../../assets/images/instructions/Delivery setting 3.png";
import addStaff from "../../assets/images/instructions/Add staff 1.png";
import addStaff2 from "../../assets/images/instructions/Add staff 2.png";
import addStaff3 from "../../assets/images/instructions/Add staff 3.png";
import addStaff4 from "../../assets/images/instructions/Add staff 4.png";
import deleteStaff1 from "../../assets/images/instructions/Delete staff 1.png";
import deleteStaff2 from "../../assets/images/instructions/Delete staff 2.png";
import deleteStaff3 from "../../assets/images/instructions/Delete staff 3.png";
import editStaff1 from "../../assets/images/instructions/Edit staff 1.png";
import editStaff2 from "../../assets/images/instructions/Edit staff 2.png";
import loginStaff1 from "../../assets/images/instructions/Login staff 1.png";
import loginStaff2 from "../../assets/images/instructions/Login staff 2.png";
import loginStaff3 from "../../assets/images/instructions/Login staff 3.png";
import addMenu1 from "../../assets/images/instructions/Add menu 1.png";
import addMenu2 from "../../assets/images/instructions/Add menu 2.png";
import addMenu3 from "../../assets/images/instructions/Add menu 3.png";
import addCategory1 from "../../assets/images/instructions/Add category 1.png";
import addCategory2 from "../../assets/images/instructions/Add category 2.png";
import addCategory3 from "../../assets/images/instructions/Add category 3.png";
import addOption1 from "../../assets/images/instructions/Add option 1.png";
import addOption2 from "../../assets/images/instructions/Add option 2.png";
import addOption3 from "../../assets/images/instructions/Add option 3.png";
import addTable1 from "../../assets/images/instructions/Add remove table 1.png";
import addTable2 from "../../assets/images/instructions/Add remove table 2.png";
import addTable3 from "../../assets/images/instructions/Add remove table 3.png";
import moveTable1 from "../../assets/images/instructions/Move table 1.png";
import moveTable2 from "../../assets/images/instructions/Move table 2.png";
import moveTable3 from "../../assets/images/instructions/Move table 3.png";
import moveTable4 from "../../assets/images/instructions/Move table 4.png";
import moveTable5 from "../../assets/images/instructions/Move table 5.png";
import checkBill1 from "../../assets/images/instructions/Checkbill 1.png";
import checkBill2 from "../../assets/images/instructions/Checkbill 2.png";
import checkBill3 from "../../assets/images/instructions/Checkbill 3.png";
import checkBill4 from "../../assets/images/instructions/Checkbill 4.png";
import checkBill5 from "../../assets/images/instructions/Checkbill 5.png";
import addQueue1 from "../../assets/images/instructions/Add queue 1.png";
import addQueue2 from "../../assets/images/instructions/Add queue 2.png";
import addQueue3 from "../../assets/images/instructions/Add queue 3.png";
import deleteQueue1 from "../../assets/images/instructions/Remove queue 1.png";
import deleteQueue2 from "../../assets/images/instructions/Remove queue 2.png";
import receiveQueue1 from "../../assets/images/instructions/Recieve queue 1.png";
import receiveQueue2 from "../../assets/images/instructions/Recieve queue 2.png";
import checkQueue1 from "../../assets/images/instructions/Queue status 1.png";
import checkQueue2 from "../../assets/images/instructions/Queue status 2.png";
import checkQueue3 from "../../assets/images/instructions/Queue status 3.png";
import resetQueue1 from "../../assets/images/instructions/Reset queue 1.png";
import resetQueue2 from "../../assets/images/instructions/Reset queue 2.png";
import settingPayment1 from "../../assets/images/instructions/Payment setting 1.png";
import settingPayment2 from "../../assets/images/instructions/Payment setting 2.png";
import settingPayment3 from "../../assets/images/instructions/Payment setting 3.png";
import cashPayment1 from "../../assets/images/instructions/Payment cash 1.png";
import cashPayment2 from "../../assets/images/instructions/Payment cash 2.png";
import cashPayment3 from "../../assets/images/instructions/Payment cash 3.png";
import promptpayPayment1 from "../../assets/images/instructions/Payment PP 1.png";
import promptpayPayment2 from "../../assets/images/instructions/Payment PP 2.png";
import promptpayPayment3 from "../../assets/images/instructions/Payment PP 3.png";
import thaiQrPayment1 from "../../assets/images/instructions/Payment ThaiQr 1.png";
import thaiQrPayment2 from "../../assets/images/instructions/Payment ThaiQr 2.png";
import thaiQrPayment3 from "../../assets/images/instructions/Payment ThaiQr 3.png";
import weChatPayment1 from "../../assets/images/instructions/Payment wechat 1.png";
import weChatPayment2 from "../../assets/images/instructions/Payment wechat 2.png";
import weChatPayment3 from "../../assets/images/instructions/Payment wechat 3.png";
import alipayPayment1 from "../../assets/images/instructions/Payment alipay 1.png";
import alipayPayment2 from "../../assets/images/instructions/Payment alipay 2.png";
import alipayPayment3 from "../../assets/images/instructions/Payment alipay 3.png";
import shopeePayment1 from "../../assets/images/instructions/Payment shopeepay 1.png";
import shopeePayment2 from "../../assets/images/instructions/Payment shopeepay 2.png";
import shopeePayment3 from "../../assets/images/instructions/Payment shopeepay 3.png";
import printerSetting1 from "../../assets/images/instructions/Printer setting 1.png";
import printerSetting2 from "../../assets/images/instructions/Printer setting 2.png";
import printerSetting3 from "../../assets/images/instructions/Printer setting 3.png";
import printerSetting4 from "../../assets/images/instructions/Printer setting 4.png";
import printerSetting5 from "../../assets/images/instructions/Printer setting 5.png";
import registerPayment1 from "../../assets/images/instructions/Document register 1.png";
import registerPayment2 from "../../assets/images/instructions/Document register 2.png";
import registerPayment3 from "../../assets/images/instructions/Document register 3.png";
import registerPayment4 from "../../assets/images/instructions/Document register 4.png";
import onlineOrder1 from "../../assets/images/instructions/Online order 1.png";
import onlineOrder2 from "../../assets/images/instructions/Online order 2.png";
import onlineOrder3 from "../../assets/images/instructions/Online order 3.png";
import onlineOrder4 from "../../assets/images/instructions/Online order 4.png";
import orderSitin1 from "../../assets/images/instructions/Sit-in order 1.png";
import orderSitin2 from "../../assets/images/instructions/Sit-in order 2.png";
import orderSitin3 from "../../assets/images/instructions/Sit-in order 3.png";
import orderSitin4 from "../../assets/images/instructions/Sit-in order 4.png";
import orderSitin5 from "../../assets/images/instructions/Sit-in order 5.png";
import orderSitin6 from "../../assets/images/instructions/Sit-in order 6.png";
import orderSitin7 from "../../assets/images/instructions/Sit-in order 7.png";
import orderTakehome1 from "../../assets/images/instructions/Takehome order 1.png";
import orderTakehome2 from "../../assets/images/instructions/Takehome order 2.png";
import orderTakehome3 from "../../assets/images/instructions/Takehome order 3.png";
import orderTakehome4 from "../../assets/images/instructions/Takehome order 4.png";
import orderTakehome5 from "../../assets/images/instructions/Takehome order 5.png";
import orderTakehome6 from "../../assets/images/instructions/Takehome order 6.png";
import orderTakehome7 from "../../assets/images/instructions/Takehome order 7.png";
import confirmOrderKitchen1 from "../../assets/images/instructions/Kitchen manage 1.png";
import confirmOrderKitchen2 from "../../assets/images/instructions/Kitchen manage 2.png";
import confirmOrderKitchen3 from "../../assets/images/instructions/Kitchen manage 3.png";
import package1 from "../../assets/images/instructions/Buy package 1.png";
import package2 from "../../assets/images/instructions/Buy package 2.png";
import package3 from "../../assets/images/instructions/Buy package 3.png";
import changePin1 from "../../assets/images/instructions/Change pin 1.png";
import changePin2 from "../../assets/images/instructions/Change pin 2.png";
import changePin3 from "../../assets/images/instructions/Change pin 3.png";
import changePin4 from "../../assets/images/instructions/Change pin 4.png";
import changePin5 from "../../assets/images/instructions/Change pin 5.png";
import logout1 from "../../assets/images/instructions/Logout 1.png";
import logout2 from "../../assets/images/instructions/Logout 2.png";
import logout3 from "../../assets/images/instructions/Logout 3.png";
import logout4 from "../../assets/images/instructions/Logout 4.png";
import language1 from "../../assets/images/instructions/Language 1.png";
import language2 from "../../assets/images/instructions/Language 2.png";
import language3 from "../../assets/images/instructions/Language 3.png";
import language4 from "../../assets/images/instructions/Language 4.png";

export const instructionData = [
  {
    title: "เปลี่ยนโปรไฟล์และรูปพื้นหลังร้านค้า",
    navigate: "merchantProfile",
    data: [
      {
        img: changeImage1,
        description: "1.คลิกที่ไอคอนดินสอที่โปรไฟล์เพื่อแก้ไข",
      },
      {
        img: changeImage2,
        description: "2.คลิกที่ โปรไฟล์ หรือ “ภาพพื้นหลัง” เพื่อเปลี่ยนรูป",
      },
    ],
  },
  {
    title: "เปลี่ยนรายละเอียดข้อมูลร้านค้า",
    navigate: "merchantDetail",
    data: [
      {
        img: merchantSetting,
        description: "1.ไปที่ตั้งค่า",
      },
      {
        img: merchantDetail2,
        description: "2.เลือก “ข้อมูลร้านค้า”",
      },
      {
        img: merchantDetail3,
        description: "3.สามารถเปลี่ยนข้อมูลได้ตามต้องการจากนั้น กด “บันทึก”",
      },
    ],
  },
  {
    title: "ตั้งค่าเวลา เปิด-ปิด ร้าน",
    navigate: "openCloseTime",
    data: [
      {
        img: openCloseTime1,
        description: "1.ไปที่ “ตั้งค่าร้านค้า” ",
      },
      {
        img: openCloseTime2,
        description: "2.เลือกเวลาเปิด-ปิด",
      },
      {
        img: [openCloseTime3, openCloseTime4],
        description:
          "3. สามารถตั้งค่าเวลาเปิด-ปิด ได้ตามความต้องการจากนั้น กด “ยืนยัน”",
      },
    ],
  },
  {
    title: "ตั้งค่าการให้บริการร้านค้า",
    navigate: "serviceMerchant",
    data: [
      {
        img: serviceMerchant1,
        description: "1.ไปที่ “ตั้งค่าร้านค้า” ",
      },
      {
        img: serviceMerchant2,
        description: "2.เลือก “การให้บริการร้าน”",
      },
      {
        img: serviceMerchant3,
        description:
          "3.ร้านค้าสามารถเลือก เปิด-ปิด การให้บริการของร้านค้าเองได",
      },
    ],
  },
  {
    title: "ตั้งค่าการให้บริการออนไลน์",
    navigate: "onlineService",
    data: [
      {
        img: onlineService1,
        description: "1.ไปที่ “ตั้งค่าร้านค้า” ",
      },
      {
        img: onlineService2,
        description: "2.เลือก “การให้บริการออนไลน์”",
      },
      {
        img: onlineService3,
        description:
          "3.ร้านค้าสามารถเลือก เปิด-ปิด การบริการรับออเดอร์ออนไลน์ได",
      },
    ],
    optionalText: [
      {
        id: 1,
        text: [
          "หากร้านค้ายังไม่สมัครบริการรับชำระเงินกับผู้ให้บริการ INET หรือ Ksher ร้านค้าจะสามารถเปิดใช้บริการ “",
          <strong style={{ color: "#F19A3C" }} key="self-pickup">
            รับเอง
          </strong>,
          "” และใช้ “",
          <strong style={{ color: "#F19A3C" }} key="cash-payment">
            ระบบรับชำระเงินสด
          </strong>,
          "” ได้เพียงอย่างเดียวเท่านั้น",
        ],
      },
      {
        id: 2,
        text: [
          "เพื่อการ",
          <strong style={{ color: "#F19A3C" }} key="online-payment">
            เพิ่มระบบรับชำระเงินออนไลน์
          </strong>,
          "และเปิดใช้บริการ “",
          <strong style={{ color: "#F19A3C" }} key="delivery">
            จัดส่ง
          </strong>,
          "” ได้ ร้านค้าจำเป็นต้องสมัครบริการรับชำระเงินจาก INET หรือ Ksher",
        ],
      },
    ],
  },
  {
    title: "การออกแบบใบเสร็จรับเงิน",
    navigate: "billDesign",
    data: [
      {
        img: billDesign1,
        description: "1.ไปที่ “ตั้งค่าร้านค้า” ",
      },
      {
        img: billDesign2,
        description: "2.เลือก “ออกแบบใบเสร็จรับเงิน”",
      },
      {
        img: billDesign3,
        description: "3.สามารถเปลี่ยนข้อความ ใส่รูปในใบเสร็จได้ตามความต้องการ ",
      },
      {
        img: billDesign4,
        description: [
          "4.เมื่อเสร็จสิ้น เลือก “บันทึก”",
          "*เลือก “ดูตัวอย่าง” เพื่อดูตัวอย่างใบเสร็จที่ออกแบบ",
        ],
      },
    ],
  },
  {
    title: "ตั้งค่าการจัดส่ง",
    navigate: "deliverFee",
    data: [
      {
        img: deliverFee1,
        description: "1.ไปที่ “ตั้งค่าร้านค้า” ",
      },
      {
        img: deliverFee2,
        description: "2.เลือก “ค่าจัดส่ง”",
      },
      {
        img: deliverFee3,
        description:
          "3.สามารถตั้งค่าค่าจัดส่งตามระยะทางที่กำหนด จากนั้น กด “เพิ่ม” และ กด “อัปเดต”",
      },
    ],
  },
  {
    title: "เพิ่มพนักงาน",
    navigate: "addStaff",
    data: [
      {
        img: addStaff,
        description: "1.ไปที่ “ตั้งค่า” ",
      },
      {
        img: addStaff2,
        description: "2.เลือก “จัดการพนักงาน”",
      },
      {
        img: addStaff3,
        description: "3.เลือก “เพิ่มพนักงาน”",
      },
      {
        img: addStaff4,
        description: "4.เพิ่ม “ชื่อ” และตำแหน่ง เปิดใช้งาน จากนั้น กด “สร้าง”",
      },
    ],
  },
  {
    title: "ลบพนักงาน",
    navigate: "deleteStaff",
    data: [
      {
        img: deleteStaff1,
        description: "1.กด “แก้ไข”",
      },
      {
        img: deleteStaff2,
        description: "2.เลือก ไอคอนถังขยะ เพื่อลบพนักงาน",
      },
      {
        img: deleteStaff3,
        description: "3.ยืนยันเพื่อลบพนักงาน",
      },
    ],
  },
  {
    title: "แก้ไขข้อมูลพนักงาน",
    navigate: "editStaff",
    data: [
      {
        img: editStaff1,
        description: "1.กด “แก้ไข”",
      },
      {
        img: editStaff2,
        description: "2.แก้ไข ชื่อ และตำแหน่งได้ตามความต้องการ",
      },
    ],
  },
  {
    title: "เข้าสู่ระบบด้วยพนักงาน",
    navigate: "loginWithStaff",
    data: [
      {
        img: loginStaff1,
        description: "1.เลือก QR codeของพนักงาน",
      },
      {
        img: loginStaff2,
        description: "2.เปิดหน้าสแกนให้พนักงาน",
      },
      {
        img: loginStaff3,
        description: "3.ให้พนักงาน “สแกน QR Code เพื่อเข้าสู่ระบบ”",
      },
    ],
  },
  {
    title: "สร้างเมนู",
    navigate: "addMenu",
    data: [
      {
        img: addMenu1,
        description: "1.ไปที่ “เมนู”",
      },
      {
        img: addMenu2,
        description: "2.กด “เพิ่มเมนู”",
      },
      {
        img: addMenu3,
        description: "3.กรอกข้อมูลให้ครบถ้วน จากนั้นกด “สร้าง”",
      },
    ],
  },
  // {
  //   title: "ลบเมนู",
  //   navigate: "deleteMenu",
  //   data: [
  //     {
  //       img: deleteMenu1,
  //       description: "1.กดไอคอนดินสอ",
  //     },
  //     {
  //       img: deleteMenu2,
  //       description: "2.กดลบ ",
  //     },
  //   ],
  // },
  {
    title: "เพิ่มประเภท",
    navigate: "addCategory",
    data: [
      {
        img: addCategory1,
        description: "1.ไปที่ “เมนู”",
      },
      {
        img: addCategory2,
        description: "2.ไปที่ “ประเภท” และกด “เพิ่มประเภท”",
      },
      {
        img: addCategory3,
        description: "3.กรอกชื่อประเภท และกด ”ยืนยัน”",
      },
    ],
  },
  // {
  //   title: "ลบประเภท",
  //   navigate: "deleteCategory",
  //   data: [
  //     {
  //       img: deleteCategory1,
  //       description: "1.กดที่ไอคอนถังขยะเพื่อลบประเภท",
  //     },
  //   ],
  // },
  {
    title: "เพิ่มตัวเลือก",
    navigate: "addOption",
    data: [
      {
        img: addOption1,
        description: "1.ไปที่ “เมนู”",
      },
      {
        img: addOption2,
        description: "2.ไปที่ “ตัวเลือก” และกด “เพิ่มตัวเลือก”",
      },
      {
        img: addOption3,
        description:
          "3.กรอกรายละเอียด จากนั้นกด “เพิ่มตัวเลือกย่อย” และกด “สร้าง”",
      },
    ],
  },
  // {
  //   title: "ลบตัวเลือก",
  //   navigate: "deleteOption",
  //   data: [
  //     {
  //       img: deleteOption1,
  //       description: "1.กดที่ไอคอนถังขยะเพื่อลบตัวเลือก",
  //     },
  //   ],
  // },
  {
    title: "วิธีการเพิ่มและลบโต๊ะ",
    navigate: "addTable",
    data: [
      {
        img: addTable1,
        description: "1.กดที่ โต๊ะ/เช็คบิล",
      },
      {
        img: addTable2,
        description: "2.กด “จัดโต๊ะ”",
      },
      {
        img: addTable3,
        description:
          "3.กดเครื่องหมายบวกเพื่อเพิ่มโต๊ะ หรือเครื่องหมายลบเพื่อลบโต๊ะ",
      },
    ],
  },
  // {
  //   title: "ลบโต๊ะ",
  //   navigate: "deleteTable",
  //   data: [
  //     {
  //       img: deleteTable1,
  //       description: "1.กดลบที่เครื่องหมาย - เพื่อลบโต๊ะ",
  //     },
  //   ],
  // },
  {
    title: "วิธีการย้ายโต๊ะ",
    navigate: "moveTable",
    data: [
      {
        img: moveTable1,
        description: "1.กดที่ โต๊ะ/เช็คบิล",
      },
      {
        img: moveTable2,
        description: "2.เลือกโต๊ะ",
      },
      {
        img: moveTable3,
        description: "3.กด “เปลี่ยนโต๊ะ”",
      },
      {
        img: moveTable4,
        description: "4.เลือกโต๊ะที่ต้องการย้าย",
      },
      {
        img: moveTable5,
        description: "5.ย้ายโต๊ะสำเร็จ",
      },
    ],
  },
  {
    title: "วิธีการเช็คบิล",
    navigate: "checkBill",
    data: [
      {
        img: checkBill1,
        description: "1.กดที่ โต๊ะ/เช็คบิล",
      },
      {
        img: checkBill2,
        description: "2.กด “เช็คบิล”",
      },
      {
        img: checkBill3,
        description: "3.ตรวจสอบรายการและ กด “เช็คบิล”",
      },
      {
        img: checkBill4,
        description:
          "4.ตรวจสอบรายการ สามารถลบ-เพิ่มเมนูได้ ในกรณีที่ออเดอร์มีการเปลี่ยนแปลง",
      },
      {
        img: checkBill5,
        description: "5.เลือกช่องทางการชำระเงิน",
      },
    ],
  },
  {
    title: "วิธีการเพิ่มคิว",
    navigate: "addQueue",
    data: [
      {
        img: addQueue1,
        description: "1.กดที่ “จองคิว”",
      },
      {
        img: addQueue2,
        description: "2.กดที่ “เพิ่มคิว”",
      },
      {
        img: addQueue3,
        description: "3.กรอกรายละเอียด เพิ่มจำนวนลูกค้า และกด “สร้างคิว”",
      },
    ],
  },
  {
    title: "วิธีการรับคิว",
    navigate: "receiveQueue",
    data: [
      {
        img: receiveQueue1,
        description: "1.เลือกคิว",
      },
      {
        img: receiveQueue2,
        description: "2.กด “ยืนยันรับคิว”",
      },
    ],
  },
  {
    title: "วิธีการลบคิว",
    navigate: "deleteQueue",
    data: [
      {
        img: deleteQueue1,
        description: "1.เลือกคิว",
      },
      {
        img: deleteQueue2,
        description: "2.กดที่ ไอคอนถังขยะ เพื่อลบคิว",
      },
    ],
  },
  {
    title: "ดูสถานะคิว",
    navigate: "statusQueue",
    data: [
      {
        img: checkQueue1,
        description: "1.เลือกคิว",
      },
      {
        img: checkQueue2,
        description: "2.ให้ลูกค้าสแกน QR Code เพื่อดูสถานะคิว",
      },
      {
        img: checkQueue3,
        description: "3.เมื่อลูกค้าสแกน สถานะคิวจะปรากฎขึ้น",
      },
    ],
  },
  {
    title: "วิธีการ Reset คิว",
    navigate: "resetQueue",
    data: [
      {
        img: resetQueue1,
        description: "1.เลือกไอคอน Reset",
      },
      {
        img: resetQueue2,
        description: "2.กด “ยืนยัน”",
      },
    ],
  },
  {
    title: "การยืนยันออเดอร์เมื่อทำเสร็จ",
    navigate: "confirmOrderKitchen",
    data: [
      {
        img: confirmOrderKitchen1,
        description: "1.ไปที่ “ครัว” ",
      },
      {
        img: confirmOrderKitchen2,
        description: "2.กดเลือกออเดอร์เพื่อแสดงรายละเอียด",
      },
      {
        img: confirmOrderKitchen3,
        description: "3.เมื่อทำเสร็จกดที่เมนู จากนั้นกด “เสร็จ”",
      },
    ],
  },
  {
    title: "การตั้งค่าระบบชำระเงิน",
    navigate: "settingPayment",
    data: [
      {
        img: settingPayment1,
        description: "1.ไปที่ “ตั้งค่า” ",
      },
      {
        img: settingPayment2,
        description: "2.เลือก “ตั้งค่าระบบชำระเงิน”",
      },
      {
        img: settingPayment3,
        description: "3.สามารถเลือกเปิด-ปิดระบบการรับชำระเงินได้ตามความต้องการ",
      },
    ],
  },
  {
    title: "ระบบชำระด้วยเงินสด",
    navigate: "paymentByCash",
    data: [
      {
        img: cashPayment1,
        description: "1.กด “เช็คบิล”",
      },
      {
        img: cashPayment2,
        description: "2.เลือก “เงินสด” และกรอกจำนวนเงินที่ได้รับ",
      },
      {
        img: cashPayment3,
        description: "3.กด “ยืนยัน”",
      },
    ],
  },
  {
    title: "ระบบชำระด้วย PromptPay",
    navigate: "paymentByPromptPay",
    data: [
      {
        img: promptpayPayment1,
        description: "1.กด “เช็คบิล”",
      },
      {
        img: promptpayPayment2,
        description: "2.เลือก “Promptpay”",
      },
      {
        img: promptpayPayment3,
        description: "3.ลูกค้าสแกน QR Code ชำระเงิน",
      },
    ],
  },
  {
    title: "ระบบชำระด้วย Thai QR",
    navigate: "paymentByThaiQR",
    data: [
      {
        img: thaiQrPayment1,
        description: "1.กด “เช็คบิล”",
      },
      {
        img: thaiQrPayment2,
        description: "2.กด “Thai QR”",
      },
      {
        img: thaiQrPayment3,
        description: "3.ลูกค้าสแกน QR Code ชำระเงิน",
      },
    ],
  },
  {
    title: "ระบบชำระด้วย WeChat Pay",
    navigate: "paymentByWeChatPay",
    data: [
      {
        img: weChatPayment1,
        description: "1.กด “เช็คบิล”",
      },
      {
        img: weChatPayment2,
        description: "2.กด “WeChat Pay”",
      },
      {
        img: weChatPayment3,
        description: "3.ลูกค้าสแกน QR Code ชำระเงิน",
      },
    ],
  },
  {
    title: "ระบบชำระด้วย Alipay",
    navigate: "paymentByAlipay",
    data: [
      {
        img: alipayPayment1,
        description: "1.กด “เช็คบิล”",
      },
      {
        img: alipayPayment2,
        description: "2.กด “Alipay”",
      },
      {
        img: alipayPayment3,
        description: "3.ลูกค้าสแกน QR Code ชำระเงิน",
      },
    ],
  },
  {
    title: "ระบบชำระด้วย ShopeePay",
    navigate: "paymentByShopeePay",
    data: [
      {
        img: shopeePayment1,
        description: "1.กด “เช็คบิล”",
      },
      {
        img: shopeePayment2,
        description: "2.กด “ShopeePay”",
      },
      {
        img: shopeePayment3,
        description: "3.ลูกค้าสแกน QR Code ชำระเงิน",
      },
    ],
  },
  {
    title: "การตั้งค่าเครื่องพิมพ์",
    navigate: "settingPrint",
    data: [
      {
        img: printerSetting1,
        description: "1.ไปที่ “ตั้งค่า” ",
      },
      {
        img: printerSetting2,
        description: "2.เลือก “เครื่องพิมพ์”",
      },
      {
        img: printerSetting3,
        description:
          "3.เลือก “ค้นหา” จากนั้นเครื่องพิมพ์ ที่เปิดอยู่จะปรากฎขึ้นมา ",
      },
      {
        img: printerSetting4,
        description: "4.เลือกเครื่องพิมพ์ที่ต้องการเชื่อมต่อและกด “บันทึก”",
      },
      {
        img: printerSetting5,
        description: "5.เชื่อมต่อสำเร็จ",
      },
    ],
  },
  {
    tilte: "วิธีการสมัครระบบรับชำระเงิน",
    navigate: "registerPayment",
    data: [
      {
        img: registerPayment1,
        description: "1.เลือก “สมัครบริการระบบชำระเงิน”",
      },
      {
        img: registerPayment2,
        description:
          "2.เลือกผู้ให้บริการระบบรับชำระเงินสามารถเลือกสมัครได้หลายบริการ ",
      },
      {
        img: registerPayment3,
        description: "3.กรอกข้อมูลให้ครบถ้วน",
      },
      {
        img: registerPayment4,
        description: [
          "4.กด “ส่งเอกสาร”",
          " *กด “บันทึก” ระบบจะทำการบันทึกข้อมูล ของท่านไว้ ",
        ],
      },
    ],
  },
  {
    title: "ออเดอร์จากออนไลน์",
    navigate: "orderOnline",
    data: [
      {
        img: onlineOrder1,
        description: "1.ไปที่ “รายการวันนี้”",
      },
      {
        img: onlineOrder2,
        description: "2.เลือก ออเดอร์",
      },
      {
        img: onlineOrder3,
        description: "3.กด ยืนยันออเดอร์",
      },
      {
        img: onlineOrder4,
        description: "4.เลือกระยะเวลาทำอาหาร",
      },
    ],
  },
  {
    title: "ออเดอร์ทานที่ร้าน",
    navigate: "orderSit-in",
    data: [
      {
        img: orderSitin1,
        description: "1.ไปที่ “POS”",
      },
      {
        img: orderSitin2,
        description: "2.เลือก “ทานที่ร้าน”",
      },
      {
        img: orderSitin3,
        description: "3.เลือกเมนู",
      },
      {
        img: orderSitin4,
        description: "4.เลือกตัวเลือก จำนวน และกด “เพิ่ม”",
      },
      {
        img: orderSitin5,
        description: "5.กดที่ตระกร้า",
      },
      {
        img: orderSitin6,
        description: "6.เลือกโต๊ะ จากนั้นกด “สั่งออเดอร์”",
      },
      {
        img: orderSitin7,
        description: "7.ยืนยันออเดอร์",
      },
    ],
  },
  {
    title: "ออเดอร์สั่งกลับบ้าน",
    navigate: "orderTakehome",
    data: [
      {
        img: orderTakehome1,
        description: "1.ไปที่ “POS”",
      },
      {
        img: orderTakehome2,
        description: "2.เลือก สั่งกลับบ้าน",
      },
      {
        img: orderTakehome3,
        description: "3.เลือกเมนู",
      },
      {
        img: orderTakehome4,
        description: "4.เลือกตัวเลือก จำนวน และกด “เพิ่ม”",
      },
      {
        img: orderTakehome5,
        description: "5.กดที่ตระกร้า",
      },
      {
        img: orderTakehome6,
        description: "6.เลือกช่องทางการชำระเงิน",
      },
      {
        img: orderTakehome7,
        description: "7.ยืนยันออเดอร์",
      },
    ],
  },
  {
    title: "วิธีซื้อและเปลี่ยนแพ็กเกจ",
    navigate: "package",
    data: [
      {
        img: package1,
        description: "1.กดที่ “แพ็กเกจ” ด้านขวาบน",
      },
      {
        img: package2,
        description: "2.เลือก แพ็กเกจ",
      },
      {
        img: package3,
        description: "3.ชำระเงิน",
      },
    ],
  },
  {
    title: "วิธีเปลี่ยนรหัส PIN",
    navigate: "changePIN",
    data: [
      {
        img: changePin1,
        description: "1.ไปที่ “ตั้งค่า”",
      },
      {
        img: changePin2,
        description: "2.เลือก “ตั้งค่าบัญชี”",
      },
      {
        img: changePin3,
        description: "3.เลือก “เปลี่ยนรหัส PIN”",
      },
      {
        img: changePin4,
        description: "4.ใส่รหัส PIN เดิม",
      },
      {
        img: changePin5,
        description: "5.ใส่รหัส PIN ใหม่",
      },
    ],
  },
  {
    title: "วิธีลบบัญชี",
    navigate: "deleteAccount",
    data: [
      {
        img: null,
        description: [
          "กรุณาติดต่อ ",
          " Line Official : @pompkins",
          "Email : support@pompkins.com",
        ],
      },
    ],
  },
  {
    title: "วิธีออกจากระบบ",
    navigate: "logout",
    data: [
      {
        img: logout1,
        description: "1.ไปที่ “ตั้งค่า”",
      },
      {
        img: logout2,
        description: "2.เลือก “ตั้งค่าบัญชี”",
      },
      {
        img: logout3,
        description: "3.เลือก “ออกจากระบบ”",
      },
      {
        img: logout4,
        description: "4.ยืนยัน",
      },
    ],
  },
  {
    title: "วิธีเปลี่ยนภาษา",
    navigate: "language",
    data: [
      {
        img: language1,
        description: "1.ไปที่ “ตั้งค่า”",
      },
      {
        img: language2,
        description: "2.เลือก “ตั้งค่าบัญชี”",
      },
      {
        img: language3,
        description: "3.เลือก “เปลี่ยนภาษา”",
      },
      {
        img: language4,
        description: "4.เลือกภาษาที่ต้องการ",
      },
    ],
  },
];
