import React from "react";
import { Box, Typography, Button } from "@mui/material";
import InstructionItem from "./InstructionItem";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import { instructionData } from "./InstructionData";
import LoadingScreen from "../../components/LoadingScreen";

function Instruction({ state }) {
  const navigator = useNavigate();
  const [data, setData] = React.useState();
  const [isFetch, setIsFetch] = React.useState(false);
  React.useEffect(() => {
    setIsFetch(false);
    instructionData.map((item) => {
      if (item.navigate === state) {
        setData(item);
        setIsFetch(true);
      }
    });
    // switch (state) {
    //   case "merchantProfile":
    //     setData(instructionData[0]);
    //     setIsFetch(true);
    //     break;
    //   case "merchantDetail":
    //     setData(instructionData[1]);
    //     setIsFetch(true);
    //     break;
    //   case "openingClosingTime":
    //     setData(instructionData[2]);
    //     setIsFetch(true);
    //     break;
    //   default:
    //     return <Instruction />;
    // }
  }, [state]);

  const handleChange = () => {
    navigator(`?page=${data.navigate}`);
  };

  const renderOptionalText = (data) => {
    if (Array.isArray(data) && data.length > 0) {
      return data.map((item) => (
        <Box
          key={item.id} // Ensure unique key for each Box
          sx={{
            borderRadius: "14px",
            boxShadow: "4px 4px 14.6px rgba(0, 0, 0, 0.09)",
            p: 2,
            mb: 3,
          }}
        >
          <Typography>
            {item.text.map((textPart, index) => (
              <span key={index} style={{ color: "#F19A3C" }}>
                {textPart}
              </span> // Render each text part with a unique key
            ))}
          </Typography>
        </Box>
      ));
    } else {
      return null;
    }
  };

  if (!isFetch) {
    return <LoadingScreen />;
  }
  return (
    <>
      <Box
        sx={{
          position: "sticky",
          width: "100%",
          display: "flex",

          justifyContent: "space-between",
          bgcolor: "#f9931f",
          zIndex: 10000,
          alignItems: "center",
        }}
      >
        <Button
          onClick={() => navigator(-1)}
          sx={{ color: "white", backgroundColor: "#ef8a17" }}
        >
          <ArrowBackIosNewIcon />
        </Button>
        <Box
          sx={{
            color: "white",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {/* <Typography>หัวข้อ :</Typography>
          <Autocomplete
            id="grouped-demo"
            options={data.title}
            getOptionLabel={(option) => option.title}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                sx={{ color: "white" }}
                InputLabelProps={{
                  sx: { fontSize: "16px", top: "-12px", color: "white" },
                }}
                InputProps={{
                  sx: { height: "36px", mt: "0 !important" },
                  disableUnderline: true,
                }}
              />
            )}
          /> */}
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          py: 4,
          px: 1,
          mt: 5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: 1,
            backgroundColor: "#FFDEBA",
            border: "1.5px solid #D9D9D9",
            borderRadius: "14px",
            width: "fit-content",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: "18px",
            }}
          >
            {data.title}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {data.data.map((itm) => (
            <InstructionItem data={itm} />
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: ["70%", "60%", "30%"],
          }}
        >
          {renderOptionalText(data.optionalText)}
        </Box>
      </Box>
    </>
  );
}

export default Instruction;
