import { AccountCircleOutlined, Menu } from "@mui/icons-material";
import { Box, IconButton, Link } from "@mui/material";
import React, { useState } from "react";
import PompkinsGoogleIcon from "../assets/images/pompkins_google.png";

import ModalDialogMenu from "./layoutComponents/ModalDialogMenu";
import { useAuthStore } from "../stores/useAuthStore";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLocationStore } from "../stores/useLocationStore";
import { useGuestLogin } from "../stores/useGuestLogin";
import GusetModalMenuMobile from "./layoutComponents/GusetModalMenuMobile";
import LanguageMenu from "./layoutComponents/LanguageMenu";
import ModalHomeDialogMenu from "./layoutComponents/ModalHomeDialogMenu";
import LocationNav from "./layoutComponents/LocationNav";
import defaultFavicon from "../../public/favicon.ico";
import { Helmet } from "react-helmet-async";

function LayoutMobile() {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const { isAuthenticated, setLocationFrom } = useAuthStore();
  const { currentAddress, openModal } = useLocationStore();
  const { isGuest } = useGuestLogin();
  const navigator = useNavigate();

  const handleMenuClick = () => {
    if (isAuthenticated || isGuest) {
      setOpen(true);
    } else {
      setLocationFrom(location.pathname);
      navigator("/login/new");
    }
  };

  const { t } = useTranslation();

  const noAccountIconPaths = [
    "merchant-qr",
    "menu/",
    "/",
    "/contactus",
    "/pompkins/package",
  ];

  const menuIconPaths = ["/", "/contactus", "/pompkins/package"];

  return (
    <>
      <Helmet>
        <link rel="image/png" href={defaultFavicon} sizes="any" />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href={defaultFavicon}
        />
        <link rel="apple-touch-icon" sizes="180x180" href={defaultFavicon} />
      </Helmet>
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              flex: "0 0 auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            {!noAccountIconPaths.some((path) =>
              location.pathname.includes(path)
            ) && (
              <IconButton
                color="inherit"
                aria-label="menu"
                component="span"
                onClick={handleMenuClick}
              >
                <AccountCircleOutlined className="text-primary" />
              </IconButton>
            )}
            {menuIconPaths.includes(location.pathname) && (
              <IconButton
                color="inherit"
                aria-label="user"
                component="span"
                onClick={() => setOpen(true)}
              >
                <Menu className="text-black" />
              </IconButton>
            )}
          </Box>
          <Link
            href="/"
            sx={{
              flex: "1 1 auto",
              display: "flex",
              justifyContent: "center",
              maxWidth: "60%",
            }}
          >
            <img
              src={PompkinsGoogleIcon}
              alt="logo"
              title="logo"
              loading="lazy"
              width={"350px"}
              height={"70px"}
              style={{
                objectFit: "cover",
              }}
            />
          </Link>
          <Box
            sx={{
              display: "flex",
              flex: "0 0 auto",
              justifyContent: "flex-end",
            }}
          >
            <LanguageMenu />
          </Box>
        </Box>
        {location.pathname.includes("merchant") &&
          !location.pathname.includes("queue") && (
            <Box sx={{ width: "100%", mt: 1, px: 1 }}>
              <LocationNav
                currentAddress={currentAddress}
                openModal={openModal}
                t={t}
              />
            </Box>
          )}
        {menuIconPaths.includes(location.pathname) && (
          <ModalHomeDialogMenu open={open} setOpen={setOpen} />
        )}
        {location.pathname !== "/" && isAuthenticated && (
          <ModalDialogMenu open={open} setOpen={setOpen} />
        )}
        {location.pathname !== "/" && isGuest && (
          <GusetModalMenuMobile open={open} setOpen={setOpen} />
        )}
      </Box>
    </>
  );
}

export default LayoutMobile;
