import {
  Box,
  Chip,
  IconButton,
  SwipeableDrawer,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Circle, GoogleMap, Marker, OverlayView } from "@react-google-maps/api";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import {
  getBMerchant,
  getMerchantById,
  getRatingByMerchantUUID,
} from "../../libs/merchantAPI/merchantAPI";
import { DistanceCalculator } from "../../utils/calDistance";
import { isMerchantOpen } from "../../libs/isMerchantOpen";
import DefaultProfile from "../../utils/defaultImage";
import humanIcon from "../../assets/images/run_fast_icon_136256.png";
import restaurantIcon from "../../assets/images/pin.png";
import MerchantList from "./MerchantList";
import { Search } from "@mui/icons-material";
import FoodTiles from "../../components/FoodTiles";
import { useLocationStore } from "../../stores/useLocationStore";
function ExplorePage() {
  const { t } = useTranslation();
  const [nearMerchant, setNearMerchant] = useState([]);
  const isMobile = useMediaQuery("(max-width:680px)");
  const [drawerHeight, setDrawerHeight] = useState("10%");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredMerchants, setFilteredMerchants] = useState([]);
  const { currentLocation } = useLocationStore();
  const [selectedMerchant, setSelectedMerchant] = useState();
  const mapRef = useRef();
  const [clickedChips, setClickedChips] = useState({});
  const { openModal } = useLocationStore();
  const displayedItems = isMobile
    ? nearMerchant.slice(0, 2)
    : nearMerchant.slice(0, 4);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/explore",
      title: "explorePage",
    });
  }, []);

  useEffect(() => {
    if (!currentLocation) {
      openModal();
      return;
    }
  }, []);

  useEffect(() => {
    if (currentLocation.latitude && currentLocation.longitude) {
      currentLocation.lat = Number(currentLocation.latitude);
      currentLocation.lng = Number(currentLocation.longitude);
      delete currentLocation.latitude;
      delete currentLocation.longitude;
      // window.location.reload();
    }
  }, [currentLocation]);

  const getTopPosition = (center, radius) => {
    if (!center) return;
    const earthRadius = 6371000;
    const dLat = radius / earthRadius;
    const lat = center.lat + (dLat * 180) / Math.PI;
    return { lat, lng: center.lng };
  };

  const circle500 = {
    radius: 500,
    options: {
      strokeColor: "#80746b",
      strokeOpacity: 1,
      strokeWeight: 1,
      fillColor: "#80746b",
      fillOpacity: 0,
      strokeDashArray: [10, 10],
    },
  };

  const topPositionCircle500 = getTopPosition(
    currentLocation,
    circle500.radius
  );

  const circle1000 = {
    radius: 1000,
    options: {
      strokeColor: "#80746b",
      strokeOpacity: 1,
      strokeWeight: 1,
      fillColor: "#80746b",
      fillOpacity: 0,
      strokeDashArray: [10, 10],
    },
  };

  const topPositionCircle1000 = getTopPosition(
    currentLocation,
    circle1000.radius
  );

  useEffect(() => {
    fetchMerchant();
  }, []);

  const fetchMerchant = useCallback(() => {
    getBMerchant()
      .then((data) => {
        mapData(data.data).then((mappedData) => {
          setNearMerchant(mappedData);
        });
      })
      .catch((err) => console.log(err));
  }, []);

  const mapData = async (data) => {
    const merchants = await Promise.all(
      data.map(async (item) => {
        if (!item.latitude) return null;
        if (!currentLocation) return null;
        // if (!item.verified || item.verify_check !== "verified") return null;
        const location = { lat: item.latitude, lng: item.longitude };
        const { distance, time } = DistanceCalculator(
          currentLocation,
          location
        );
        item.bmerchantId = item.id;
        item.distance = distance;
        item.isOpen = isMerchantOpen(item.open_time, item.close_time);
        item.time = time;
        const res = await getMerchantById(item.merchant_id);
        item.logo = res.data.logo ?? DefaultProfile;
        item.name = res.data.name;
        item.shop_type = res.data.shop_type;
        item.business_type = res.data.business_type;

        return item;
      })
    );

    const validMerchants = merchants.filter((item) => item !== null);

    const merchantsWithinOneKm = validMerchants.filter(
      (item) => parseFloat(item.distance) <= 1
    );

    const finalMerchants =
      merchantsWithinOneKm.length < 2 ? validMerchants : merchantsWithinOneKm;

    const mappedData = finalMerchants.map((item) => ({
      id: item.merchant_id,
      bmerchantId: item.bmerchantId,
      location: item.location,
      title: item.name,
      lat: item.latitude,
      lng: item.longitude,
      img: item.logo,
      isOpen: item.isOpen,
      category: item.business_type,
      rating: item.rating ?? 0,
      distance: `${item.distance} Km`,
      shopType:
        item.shop_type === ""
          ? "ร้านอาหารทั่วไป"
          : item.shop_type || "ร้านอาหารทั่วไป",
      features: [],
    }));

    const sortByDistance = (a, b) => {
      const distanceA = parseFloat(a.distance.replace(" Km", ""));
      const distanceB = parseFloat(b.distance.replace(" Km", ""));
      return distanceA - distanceB;
    };

    const sortedDistanceData = mappedData.sort(sortByDistance);
    return sortedDistanceData;
  };

  // useEffect(() => {
  //   console.log(nearMerchant);
  // }, [nearMerchant]);

  const handleSwipeUp = () => {
    if (drawerHeight === "10%") {
      setDrawerHeight("60%");
    } else {
      setDrawerHeight("10%");
    }
  };

  const handleSwipeDown = () => {
    setDrawerHeight("10%");
  };

  const handleMarkerSelect = (merchant) => {
    setClickedChips((prev) => ({
      [merchant.id]: !prev[merchant.id],
    }));
    setSelectedMerchant(merchant);
    animatePanTo(
      mapRef.current,
      {
        lat: parseFloat(merchant.lat),
        lng: parseFloat(merchant.lng),
      },
      1000
    );
    mapRef.current.setZoom(18);
  };

  useEffect(() => {
    filterMerchants(searchQuery);
  }, [searchQuery, nearMerchant]);

  const filterMerchants = (query) => {
    if (!query) {
      setFilteredMerchants(nearMerchant);
    } else {
      const filtered = nearMerchant.filter((merchant) =>
        merchant.title.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredMerchants(filtered);
    }
  };

  const animatePanTo = (map, targetLatLng, duration = 1000) => {
    const start = map.getCenter();
    const startLat = start.lat();
    const startLng = start.lng();
    const targetLat = targetLatLng.lat;
    const targetLng = targetLatLng.lng;

    const startTime = performance.now();

    const easeInOutCubic = (t) => {
      return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
    };

    const panStep = () => {
      const currentTime = performance.now();
      const elapsedTime = currentTime - startTime;
      const t = Math.min(elapsedTime / duration, 1);

      const easedT = easeInOutCubic(t);

      const lat = startLat + (targetLat - startLat) * easedT;
      const lng = startLng + (targetLng - startLng) * easedT;
      map.panTo({ lat, lng });

      if (t < 1) {
        requestAnimationFrame(panStep);
      }
    };

    panStep();
  };
  return (
    <>
      <Helmet>
        <title>
          {t("pompkins")} - {t("explorePage")}
        </title>
        <link rel="canonical" href={`${window.location.href}`} />
      </Helmet>
      <Box>
        <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
          <Box
            sx={{
              width: "100%",
              height: "95vh",
              position: "relative",
            }}
          >
            <GoogleMap
              mapContainerStyle={{ width: "100%", height: "100%" }}
              center={currentLocation}
              zoom={16}
              options={{
                zoomControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
                clickableIcons: false,
                styles: [
                  {
                    featureType: "poi",
                    elementType: "labels",
                    stylers: [{ visibility: "off" }],
                  },
                  {
                    featureType: "transit",
                    elementType: "labels",
                    stylers: [{ visibility: "off" }],
                  },
                ],
              }}
              onLoad={(map) => (mapRef.current = map)}
            >
              {currentLocation && <Marker position={currentLocation} />}
              {currentLocation && (
                <Circle
                  center={currentLocation}
                  radius={circle500.radius}
                  options={circle500.options}
                />
              )}
              {currentLocation && (
                <Circle
                  center={currentLocation}
                  radius={circle1000.radius}
                  options={circle1000.options}
                />
              )}
              {/* nearMerchant map */}
              {filteredMerchants &&
                filteredMerchants.length > 0 &&
                filteredMerchants.map((item, index) => (
                  <Marker
                    key={index}
                    position={{
                      lat: parseFloat(item.lat),
                      lng: parseFloat(item.lng),
                    }}
                    icon={{
                      url: restaurantIcon,
                      scaledSize: new window.google.maps.Size(32, 42),
                    }}
                    onClick={() => handleMarkerSelect(item)}
                  />
                ))}
              <Marker
                position={topPositionCircle500}
                icon={{
                  url: humanIcon,
                  scaledSize: new window.google.maps.Size(32, 32),
                }}
              />
              <OverlayView
                position={topPositionCircle500}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              >
                <Typography
                  variant="h5"
                  sx={{
                    position: "absolute",
                    transform: "translate(-50%, 0)",
                    padding: "2px 5px",
                    whiteSpace: "nowrap",
                  }}
                  fontSize={"18px"}
                  fontWeight={600}
                >
                  0.5 Km
                </Typography>
              </OverlayView>
              <Marker
                position={topPositionCircle1000}
                icon={{
                  url: humanIcon,
                  scaledSize: new window.google.maps.Size(32, 32),
                }}
              />
              <OverlayView
                position={topPositionCircle1000}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              >
                <Typography
                  sx={{
                    position: "absolute",
                    transform: "translate(-50%, 0)",
                    padding: "2px 5px",
                    whiteSpace: "nowrap",
                  }}
                  variant="h5"
                  fontSize={"18px"}
                  fontWeight={600}
                >
                  1.0 Km
                </Typography>
              </OverlayView>
              {selectedMerchant && (
                <OverlayView
                  position={{
                    lat: parseFloat(selectedMerchant.lat),
                    lng: parseFloat(selectedMerchant.lng),
                  }}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      transform: "translate(-35%,25%)",
                      zIndex: 11,
                    }}
                  >
                    <FoodTiles item={selectedMerchant} sx={{ width: "100%" }} />
                  </Box>
                </OverlayView>
              )}
            </GoogleMap>
            <Box
              sx={{
                position: "absolute",
                top: 16,
                left: "50%",
                transform: "translate(-50%)",
                zIndex: 10,
                padding: 2,
                marginTop: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: ["90%", "80%", "60%"],
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                placeholder={t("home.searchBox")}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <IconButton
                      aria-label="search"
                      sx={{ width: "40px", height: "40px" }}
                    >
                      <Search
                        className="text-primary"
                        sx={{ width: "32px", height: "32px" }}
                      />
                    </IconButton>
                  ),
                  sx: {
                    pl: 2,
                    borderRadius: 4,
                    borderColor: "transparent",
                    backgroundColor: "#ffffff",
                    boxShadow: "7px 6px 18.4px rgba(0, 0, 0, 0.1)",
                  },
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 1,
                  flexWrap: "wrap",
                }}
              >
                {displayedItems &&
                  displayedItems.length > 0 &&
                  displayedItems.map((merchant) => (
                    <Chip
                      label={merchant.title}
                      clickable
                      onClick={() => handleMarkerSelect(merchant)}
                      sx={{
                        fontSize: { xs: "14px", md: "18px" },
                        backgroundColor: clickedChips[merchant.id]
                          ? "#f9931f"
                          : "#ffffff",
                        color: clickedChips[merchant.id]
                          ? "#ffffff"
                          : "#f9931f",
                        padding: { xs: "8px", md: "10px" },
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                        "&:hover": {
                          backgroundColor: "#ffffff",
                          color: "#f9931f",
                        },
                        mx: 1,
                        mb: 1,
                      }}
                    />
                  ))}
              </Box>
            </Box>
          </Box>
        </Box>
        <SwipeableDrawer
          anchor="bottom"
          open={drawerHeight !== "10%"}
          onClose={handleSwipeDown}
          onOpen={handleSwipeUp}
          swipeAreaWidth={drawerHeight === "10%" ? 80 : 0}
          disableSwipeToOpen={drawerHeight !== "10%"}
          sx={{ zIndex: 10 }}
          PaperProps={{
            style: {
              height: drawerHeight,
              transition: "height 0.3s ease-in-out",
            },
          }}
        >
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                width: 40,
                height: 6,
                bgcolor: "gray",
                borderRadius: 3,
                alignSelf: "center",
                mt: 1,
                cursor: "pointer",
              }}
              onSwipeDown={handleSwipeDown}
              onClick={handleSwipeDown}
            />
            <MerchantList
              merchants={filteredMerchants.flatMap((item) =>
                Array(1).fill(item)
              )}
            />
          </Box>
        </SwipeableDrawer>
        <Box
          sx={{
            position: "fixed",
            bottom: 16,
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1200,
          }}
        >
          <Chip
            sx={{
              fontSize: "18px",
              backgroundColor: "#f9931f",
              color: "#ffffff",
              padding: "10px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              "&:hover": {
                backgroundColor: "#ffffff",
                color: "#f9931f",
              },
            }}
            label={`${t("home.nearby")}`}
            clickable
            onClick={handleSwipeUp}
          />
        </Box>
      </Box>
    </>
  );
}

export default ExplorePage;
