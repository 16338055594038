import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Button, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import ActiveOrderItem from "../../components/order/ActiveOrderItem";
import PastOrderTiles from "./PastOrderTiles";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import { useOrders } from "../../stores/useOrders";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ActiveOrderItemSkeleton from "../../components/order/ActiveOrderItemSkeleton";
import PastOrderTilesSkeleton from "../../components/order/PastOrderItemSkeleton";
import { useAuthStore } from "../../stores/useAuthStore";

function OrderPage() {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const { user } = useAuthStore();
  const { orders, ordersHistory, isLoading: isOrdersLoading } = useOrders(page);
  // const { bMerchantData, isLoading: isBMerchantLoading } = useBMerchantData();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/order",
      title: "OrderPage",
    });
  }, []);

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handlePageClick = (pageNumber) => {
    setPage(pageNumber);
  };

  const renderPagination = () => {
    const totalPages = ordersHistory.total_pages;
    const paginationItems = [];

    const renderPageButton = (pageNum) => (
      <Button
        key={pageNum}
        onClick={() => handlePageClick(pageNum)}
        variant={page === pageNum ? "contained" : "outlined"}
        sx={{
          minWidth: "40px",
          height: "40px",
          padding: "6px",
          margin: "0 4px",
          borderRadius: "50%",
        }}
      >
        {pageNum}
      </Button>
    );

    const renderEllipsis = (key) => (
      <Typography key={key} component="span" sx={{ margin: "0 8px" }}>
        ...
      </Typography>
    );

    // Always show first page
    paginationItems.push(renderPageButton(1));

    if (totalPages <= 7) {
      // If 7 or fewer pages, show all
      for (let i = 2; i <= totalPages; i++) {
        paginationItems.push(renderPageButton(i));
      }
    } else {
      // More than 7 pages
      if (page > 3) {
        paginationItems.push(renderEllipsis("start"));
      }

      // Show current page and 1 or 2 pages before and after
      for (
        let i = Math.max(2, page - 1);
        i <= Math.min(totalPages - 1, page + 1);
        i++
      ) {
        paginationItems.push(renderPageButton(i));
      }

      if (page < totalPages - 2) {
        paginationItems.push(renderEllipsis("end"));
      }

      // Always show last page
      paginationItems.push(renderPageButton(totalPages));
    }

    return paginationItems;
  };

  return (
    <>
      <Helmet>
        <title>
          {t("pompkins")} - {t("order.page")}
        </title>
        <meta
          name="description"
          content="สั่งเดลิเวอรีผ่าน POMPKINS Food  x Order with Google ได้แล้ววันนี้"
        />
        <link rel="canonical" href={`${window.location.href}`} />
        <meta property="og:url" content="https://pompkins.com/order" />
      </Helmet>
      <Box
        sx={{
          maxWidth: 1440,
          margin: "auto",
          padding: { xs: "0% 5%", sm: "0% 10%", md: "2% 24%" },
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" pt={2} pb={1}>
              {t("order.activeOrder")}
            </Typography>
            {isOrdersLoading && user.uuid ? (
              <ActiveOrderItemSkeleton />
            ) : orders.length ? (
              orders.map((order, index) => (
                <ActiveOrderItem key={index} order={order} />
              ))
            ) : user.uuid && orders.orders?.length ? (
              orders.orders.map((order, index) => (
                <ActiveOrderItem key={index} order={order} />
              ))
            ) : (
              <Typography>{t("order.noActive")}</Typography>
            )}
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" pt={1} pb={1}>
              {t("order.pastOrder")}
            </Typography>
            {isOrdersLoading && user.uuid ? (
              <PastOrderTilesSkeleton />
            ) : ordersHistory.length ? (
              ordersHistory.map((order, index) => (
                <PastOrderTiles key={index} order={order} />
              ))
            ) : user.uuid && ordersHistory.orders?.length ? (
              ordersHistory.orders
                .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                .map((order, index) => (
                  <PastOrderTiles key={index} order={order} />
                ))
            ) : (
              <Typography>{t("order.nopastOrder")}</Typography>
            )}
          </Grid>
        </Grid>

        {user.uuid && ordersHistory.orders?.length ? (
          <Box display="flex" justifyContent="center" my={2} gap={1}>
            <IconButton onClick={handlePrevPage} disabled={page === 1}>
              <ArrowBackIcon />
            </IconButton>

            {renderPagination()}
            <IconButton
              onClick={handleNextPage}
              disabled={page === ordersHistory.total_pages}
            >
              <ArrowForwardIcon />
            </IconButton>
          </Box>
        ) : null}
      </Box>
    </>
  );
}

export default OrderPage;
