import React, { useState, useEffect } from "react";
import logoPK from "../assets/images/logo_512.png";
import logoPKFood from "../assets/images/pompkins_food_logo.png";

const Base64ToImage = ({ base64String }) => {
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    if (base64String) {
      const imageDataUrl = `data:image/jpeg;base64,${base64String}`;
      setImageUrl(imageDataUrl);
    }
  }, [base64String]);

  return (
    <div className="relative">
      {imageUrl && (
        <img
          src={imageUrl}
          alt="Converted from base64"
          className="w-full h-auto"
        />
      )}
      <img
        src={logoPKFood}
        alt="PK Logo"
        title="PK Logo"
        loading="lazy"
        width={"100px"}
        height={"80px"}
        style={{ width: "100px", height: "80px", objectFit: "fill" }}
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-24 h-24"
      />
    </div>
  );
};

export default Base64ToImage;
